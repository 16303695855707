import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex gap-1 flex-wrap justify-content-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mb-0 text-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mb-0 text-center" }

import useFormActionsStore from '@/store/useFormActionsStore';
import { ref } from 'vue';
import { MazDialog, MazBtn } from 'maz-ui/components';
import ChangeCountry from '../ChangeCountry.vue';
import SetLanguage from '../SetLanguage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AskSettingsModal',
  setup(__props) {

const formActionsStore = useFormActionsStore();
const questionsModal = ref<boolean>(true);

return (_ctx: any,_cache: any) => {
  return (_unref(formActionsStore).askCountry || _unref(formActionsStore).askLanguage)
    ? (_openBlock(), _createBlock(_unref(MazDialog), {
        key: 0,
        modelValue: questionsModal.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((questionsModal).value = $event))
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('client_page.profile_card.edit')), 1)
        ]),
        footer: _withCtx(({ close }) => [
          _createVNode(_unref(MazBtn), { onClick: close }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('client_page.profile_edit.save')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_unref(formActionsStore).askCountry)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('price_form.country')), 1),
                  _createVNode(ChangeCountry, {
                    native: "",
                    "hide-current-value": ""
                  })
                ]))
              : _createCommentVNode("", true),
            (_unref(formActionsStore).askLanguage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('client_page.profile_edit.language')), 1),
                  _createVNode(SetLanguage)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})