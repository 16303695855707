const enum EStudentCandidateConfirmations {
  hasCountryPermission = 'has_country_permission',
  hasSanBook = 'has_san_book',
  hasPassport = 'has_passport',
  canShowDocuments = 'can_show_documents',
  customerAgreement = 'customer_will_complete_agreement',
  knowBuyMaterial = 'know_buy_material',
}

const enum ECouchPriceType {
  day = 'day',
  week = 'week',
  month = 'month',
}

const enum EDaysOfWeek {
  monday = 'MON',
  tuesday = 'TUE',
  wednesday = 'WED',
  thursday = 'THU',
  friday = 'FRI',
  saturday = 'SAT',
  sunday = 'SUN',
}

const enum ECustomerCheckInStep {
  agreement = 'agreement',
  healing = 'healing',
  waiting = 'waiting',
  start = 'start',
  products = 'products',
  rate = 'rate',
  tips = 'tips',
  total = 'total',
  success = 'success',
}

const enum EFormActionType {
  transition = 'transition',
  record = 'record',
}

const enum EParlorWalletType {
  liqpay = 'liqpay',
  stripe = 'stripe',
  paypal = 'paypal',
  wayforpay = 'wayforpay',
}

const enum EPublicationPromoPublication {
  main_site_promotions_section = 'main_site_promotions_section',
  main_site_main_banner = 'main_site_main_banner',
  boarding_studios = 'boarding_studios',
  personal_account = 'personal_account',
  mobile_application = 'mobile_application',
  email_send = 'email_send',
  notification_users_push_wallet = 'notification_users_push_wallet',
}

const enum ECreditPeriod {
  three = 'three',
  four = 'four',
  five = 'five',
  six = 'six',
}

export {
  ECreditPeriod,
  EPublicationPromoPublication,
  EStudentCandidateConfirmations,
  ECouchPriceType,
  EDaysOfWeek,
  ECustomerCheckInStep,
  EParlorWalletType,
  EFormActionType,
};
