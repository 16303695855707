<script setup lang="ts">
import useFormActionsStore from '@/store/useFormActionsStore';
import { ref } from 'vue';
import { MazDialog, MazBtn } from 'maz-ui/components';
import ChangeCountry from '../ChangeCountry.vue';
import SetLanguage from '../SetLanguage.vue';

const formActionsStore = useFormActionsStore();
const questionsModal = ref<boolean>(true);
</script>

<template>
  <MazDialog
    v-if="formActionsStore.askCountry || formActionsStore.askLanguage"
    v-model="questionsModal"
  >
    <template #title>{{ $t('client_page.profile_card.edit') }}</template>
    <div class="d-flex gap-1 flex-wrap justify-content-center">
      <div v-if="formActionsStore.askCountry">
        <p class="mb-0 text-center">{{ $t('price_form.country') }}</p>
        <ChangeCountry native hide-current-value/>
      </div>
      <div v-if="formActionsStore.askLanguage">
        <p class="mb-0 text-center">{{ $t('client_page.profile_edit.language') }}</p>
        <SetLanguage />
      </div>
    </div>
    <template #footer="{ close }">
      <MazBtn @click="close"> {{ $t('client_page.profile_edit.save') }}</MazBtn>
    </template>
  </MazDialog>
</template>
