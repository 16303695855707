<template>
  <div class="d-flex align-items-center justify-content-center">
    <MazBtn
      class="d-none d-sm-inline-flex"
      @click="isSetLanguageModalOpen = !isSetLanguageModalOpen"
      :color="transparent ? 'black' : 'white'"
      size="sm"
      outline
      rounded
    >
      <template #left-icon>
        <i class="bi bi-globe"></i>
      </template>
      {{ locale ? languageNames[locale] : "Language" }}
    </MazBtn>

    <i
      class="d-sm-none bi bi-globe h3 mb-0"
      @click="isSetLanguageModalOpen = !isSetLanguageModalOpen"
      :style="{color: transparent ? 'black' : 'white'}"
    ></i>

    <MazDialog v-model="isSetLanguageModalOpen">
      <MazRadioButtons
        orientation="col"
        :options="options"
        v-model="locale"
      >
        <template #default="{ option }">
          {{ languageFlags[option.value] }}
          {{ ' ' }}
          {{ option.label }}
        </template>
      </MazRadioButtons>
    </MazDialog>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { computed, ref, watch } from 'vue';
import axios from "@/plugins/axios";
import { languageNames, languageFlags } from '@/locales';
import { MazDialog, MazBtn, MazRadioButtons } from 'maz-ui/components'

const { availableLocales, locale } = useI18n();
const isSetLanguageModalOpen = ref(false)

defineProps({
  transparent: {
    type: Boolean,
    default: false,
  },
});

const options = computed(() => {
  return availableLocales.map((locale) => ({
    value: locale,
    label: languageNames[locale] || locale,
  }));
});

watch(locale, (newLocale) => {
  localStorage.setItem("customer_lang", newLocale);
  axios.defaults.headers.common["Accept-Language"] = newLocale;
  window.location.reload();
});
</script>
