import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

import { MazSelect } from 'maz-ui/components';
import { TUrlString } from '@/types/types';
import { ref, watch } from 'vue';
import { Size } from 'maz-ui/components/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeCountry',
  props: {
    url: { default: window.location.pathname },
    blank: { type: Boolean, default: false },
    native: { type: Boolean },
    hideCurrentValue: { type: Boolean },
    size: {}
  },
  setup(__props: any) {

const props = __props;

const countries: {
  label: string;
  value: TUrlString;
}[] = [
  {
    label: 'Bulgaria',
    value: 'https://customer.vean-tattoo.bg',
  },
  {
    label: 'Canada',
    value: 'https://customer.vean-tattoo.ca',
  },
  {
    label: 'Czech Republic',
    value: 'https://customer.vean-tattoo.cz',
  },
  {
    label: 'Estonia',
    value: 'https://customer.vean-tattoo.ee',
  },
  {
    label: 'Germany',
    value: 'https://customer.vean-tattoo.de',
  },
  {
    label: 'Hungary',
    value: 'https://customer.vean-tattoo.hu',
  },
  {
    label: 'Latvia',
    value: 'https://customer.vean-tattoo.lv',
  },
  {
    label: 'Lithuania',
    value: 'https://customer.vean-tattoo.lt',
  },
  {
    label: 'Netherlands',
    value: 'https://customer.vean-tattoo.nl',
  },
  {
    label: 'Poland',
    value: 'https://customer.vean-tattoo.pl',
  },
  {
    label: 'Romania',
    value: 'https://customer.vean-tattoo.ro',
  },
  {
    label: 'Slovakia',
    value: 'https://customer.vean-tattoo.sk',
  },
  {
    label: 'Spain',
    value: 'https://customer.vean-tattoo.es',
  },
  {
    label: 'Ukraine',
    value: 'https://customer.vean-tattoo.com',
  },
  {
    label: 'United Kingdom',
    value: 'https://customer.vean-tattoo.uk',
  },
];

const selectedValue = ref<TUrlString | undefined>(
  props.hideCurrentValue ? undefined : window.location.origin,
);

watch(selectedValue, (value) => {
  const queryString = window.location.search;

  const updatedQueryString = queryString
    .split('&') // Разбиваем строку на параметры
    .filter((param) => !param.startsWith('ask_country=')) // Фильтруем, убирая параметр ask_country
    .join('&'); // Снова объединяем параметры
  window.open(value + props.url + updatedQueryString, props.blank ? '_blank' : '_self');
});

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_ctx.native)
    ? (_openBlock(), _createBlock(_component_FormKit, {
        key: 0,
        modelValue: selectedValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedValue).value = $event)),
        type: "select",
        options: countries,
        placeholder: "Select country"
      }, null, 8, ["modelValue"]))
    : (_openBlock(), _createBlock(_unref(MazSelect), {
        key: 1,
        label: "Select country",
        size: _ctx.size ? _ctx.size : 'md',
        options: countries,
        modelValue: selectedValue.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedValue).value = $event))
      }, null, 8, ["size", "modelValue"]))
}
}

})