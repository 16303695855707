export default {
  veancoins: {
    summ: 'Totaal bonussen',
    customer_bonus: 'Klantbonus',
    vip_gift: 'VIP cadeau',
    vip_price: 'Minimaal VIP bedrag',
    cash_back_sells: 'Cashback voor verkoop',
    cash_back_certificate: 'Cashback voor certificaat',
    cash_back_student: 'Cashback voor opleiding',
    cash_back_service: 'Cashback voor service',
    cash_back_money: 'Cashback voor saldo-opwaardering',
  },
  support: {
    problem_situation_link: 'Ik heb een probleem na de sessie',
    other_problem_link: 'Ik heb een ander probleem',
    problem_situation: {
      header: 'Probleem na de sessie',
      form: {
        session: 'Sessie',
        created_by_comment: 'Beschrijf het probleem',
      },
    },
    complain: {
      header: 'Klachten en suggesties',
      form: {
        name: 'Naam',
        phone_number: 'Telefoonnummer',
        email: 'E-Mail',
        parlor: 'Salon',
        description: 'Beschrijf het probleem',
        feedback: {
          overall_quality: 'Hoe beoordeelt u de algemene kwaliteit van de service in onze salon?',
          professional_skills: 'Was u tevreden met de professionele vaardigheden van de meester?',
          staff_politeness: 'Hoe beleefd en attent was het personeel van de salon?',
          waiting_time: 'Was u tevreden met de wachttijd voor uw dienst?',
          staff_professionalism: 'Hoe beoordeelt u de professionaliteit van ons personeel?',
          salon_cleanliness: 'Hoe beoordeelt u de netheid en het comfort in onze salon?',
          service_value:
            'Vindt u dat de kosten van de diensten overeenkomen met de kwaliteit ervan?',
          recommend: 'Zou u onze salon aan uw vrienden en kennissen aanbevelen?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'Waar is de lening voor',
    type_education: 'Type onderwijs',
    type_service: 'Type dienst',
    credit_type: 'Type krediet',
    how_to_contact: 'Hoe contact met u op te nemen',
    note: 'Opmerking',
  },
  items_view: {
    header: 'Producten',
    filter_form: {
      name_placeholder: 'Naam',
      category_placeholder: 'Categorie',
      parlor_placeholder: 'Salon',
      all_categories: 'Alle categorieën',
      all_parlors: 'Alle salons',
    },
    load_more_button: 'Meer laden',
    not_found_message: 'Niets gevonden',
  },
  item_view: {
    error_message: 'Fout bij het laden van gegevens',
    available_in_parlor: 'Beschikbaar in uw salon',
    not_available_in_parlor: 'Niet beschikbaar in uw salon',
    similar_items_label: 'Vergelijkbare producten',
    log_in_to_buy: 'Inloggen om te kopen',
  },
  item_simple_card: {
    studio_price_label: 'Studio prijs',
    shop_price_label: 'Winkel prijs',
  },
  student_candidate: {
    form: {
      who_studing: 'Student of plaatsverhuur',
      phone_number: 'Telefoonnummer',
      first_name: 'Voornaam',
      last_name: 'Achternaam',
      social_link: 'Link naar een sociaal netwerk',
      parlors: 'Studio',
      couch: 'Tattoo stoel',
      find_out: 'Hoe heb je over ons gehoord?',
      start_date: 'Gewenste startdatum',
      types: 'Sessietypes',
      work_permit_document: 'Werkvergunning document',
      sanitary_book: 'Sanitairboek',
      identity_proof: 'Identiteitsbewijs',
      work_photos: "Werkfoto's",
      end_date: 'Gewenste einddatum',
      confirmations: 'Ik bevestig dat',
      know_buy_material: 'Ik erken dat ik materialen kan kopen in de studio',
      customer_will_complete_agreement: 'Elke klant van mij zal een klantovereenkomst ondertekenen',
      can_show_documents: 'Ik kan indien nodig originele documenten in de studio overleggen',
      has_country_permission: 'Ik heb toestemming om in het land te verblijven',
      has_san_book: 'Ik heb een sanitairboek',
      has_passport: 'Ik heb een identiteitsbewijs (ID)',
      conditions: 'Ik heb de huurvoorwaarden gelezen',
    },
  },
  user: {
    referral_links: {
      booking: 'Sessie boeken',
      student_candidate: 'Verhuur van ruimte',
      store: 'Klantwinkel',
      candidates_form: 'Nodig een medewerker uit',
    },
  },
  products: {
    want_add: 'Ik wil de geselecteerde verzorgingsproducten toevoegen aan de sessiekosten',
    no_warranty: '*zonder deze optie kunnen we je geen 100% succesvolle genezing garanderen',
    button_add: 'Toevoegen',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Gebruikte verbruiksartikelen',
      name: 'Naam',
      count: 'Aantal',
    },
  },
  booking: {
    more_interest: 'Nog meer interessante dingen',
    active_promo: 'Actuele promoties',
    cashback: 'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          voor de <span class="text-success">online</span> boeking',
    fast_booking: 'Snelle boeking',
    develop_sketch: 'Ontwikkeling van schets',
    fast_booking_warning:
      'De boeking is niet definitief, onze manager zal contact met je opnemen om de tijd en datum van de sessie te bevestigen',
    phone_annotate:
      'Om ons verkoopteam te helpen sneller te reageren, geeft u alstublieft uw lokale contactnummer. Dank u!',
    increased_risk: 'Waarschuwing! Hoogrisicosessie',
    increased_risk_extended:
      'Het geselecteerde sessietype en de locatie op het lichaam hebben een verhoogd risico',
    approximate_price: 'Geschatte prijs',
    price_warning: 'Extra artikelen zijn niet inbegrepen',
    type: {
      info: 'Handig formulier voor het boeken van een sessie met alle nodige informatie',
      booking_button: 'SESSIE BOEKEN',
      booking_button_promo: 'MET 15% CASHBACK IN VEAN COINS',
      unsure: 'Nog steeds niet zeker van je keuze? Wij helpen je!',
      consultant_help: 'Onze consultants helpen u graag',
      consultation_button: 'CONSULTATIE',
      simplified:
        'Boek uw sessie snel en gemakkelijk! Kies de datum, tijd en dienst in een paar klikken',
    },
    color: {
      black: 'Zwart',
      colored: 'Gekleurd',
    },
    controls: {
      next: 'VOLGENDE',
      back: 'Terug',
      no_select: 'Niet geselecteerd',
    },
    place_service: {
      select_studio: 'Selecteer studio',
      service: 'Service',
      size: 'Grootte',
      location: 'Locatie',
    },
    customer_info: {
      login_prompt: 'Log in om door te gaan',
      login_button: 'Inloggen',
      black_white_colored: 'Zwart-wit of gekleurd?',
      select_style: 'Stijl selecteren',
      tell_more: 'Vertel ons meer over uw tattoo voorkeuren en deel voorbeelden (referenties)',
      login_promo: 'Krijg 10% korting bij inloggen',
      wish: 'Wens',
    },
    date_time: {
      choose_time: 'Kies een bezoektijd',
    },
    master_card: {
      master_unavailable: 'De meester is op dit moment niet beschikbaar',
      master_guest_warning: 'LET OP! De meester is te gast',
      rank: 'Rang:',
      works_count: 'Aantal werken:',
      book_now: 'Nu boeken',
      select_another_time: 'Kies een andere tijd',
    },
    success: {
      session: 'SESSIE',
      become_mystery_shopper: 'Word een mystery shopper',
      add_to_calendar: 'Voeg gebeurtenis toe aan kalender',
      call_on_day: '*een beheerder van de salon zal u op de dag van de sessie bellen',
      return_to_menu: 'Terug naar menu',
      recommended_products: 'Aanbevolen producten voor genezing',
    },
    black_friday: '50% korting bij verplichte aanbetaling',
  },
  tips: {
    placeholder: 'Kies hoe de fooien worden verdeeld',
    divide_by_all: 'Gelijkmatig',
    administrator: 'Aan beheerder',
    parlor: 'Aan salon',
    master: 'Aan meester',
  },
  new_landing: {
    count_block: {
      countries: 'Aantal landen',
      studios: "Studio's",
      awards: 'Prijzen',
      masters: 'Meesters',
    },
    header_banner: {
      label_1: 'We maken geen olifant van een mug',
      label_2: 'we maken tatoeages!',
      book_now: 'Boek nu',
      consultation: 'Consultatie',
      prices: 'Prijzen',
      top_works: 'Top werken',
      advantages: 'Voordelen',
      change_parlor: 'Verander salon',
    },
    course_card: {
      theory_part: 'Theoretisch deel:',
      practice_part: 'Praktisch deel:',
      advance: 'Extra voordelen:',
    },
    helpful_links: {
      for_masters: 'Voor meesters',
      tattoo_news: 'Tattoo nieuws',
      tattoo_news_description: 'Blijf op de hoogte van het belangrijkste uit de wereld van tattoo',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Onze meesters zijn klaar om kennis, geheimen, technieken te delen',
      rent_space: 'Ruimte huren',
      rent_space_description:
        "Ontdek de mogelijkheden voor samenwerking door een werkplek te huren in VEAN studio's",
      tattoo_promotions: 'Promoties en promoties',
      tattoo_certificates: 'Cadeaubonnen',
      consumables: 'Verbruiksartikelen',
      equipment: 'Uitrusting',
    },
    price_block: {
      title: 'Prijzen',
      loyalty_program: {
        title: 'Loyaliteitsprogramma',
      },
      actions: {
        book_now: 'Boek nu',
        calculate_cost: 'Bereken kosten',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Topwerken',
      from_the_world: 'van over de hele wereld',
    },
    why_vean: {
      title: 'Waarom VEAN',
      individual_approach: 'Individuele benadering',
      trust: 'Vertrouwen',
      safety_guarantee: 'Veiligheidsgarantie',
      convenient_location: 'Gunstige locatie',
      visited_studio: 'Mensen hebben dit atelier bezocht',
    },
  },
  mystery_shopper: {
    title: 'Mystery Shopper',
    description:
      'Een mystery shopper is iemand die de werking van een salon controleert onder het mom van een gewone klant. Hij beoordeelt het serviceniveau in de salon.',
    offer:
      'Als je besluit een mystery shopper te worden voor deze sessie, geven we je munten cadeau.',
    instructionTitle: 'Instructies',
    instructionDetails:
      'Tijdens de sessie moet je de salon en het personeel beoordelen op verschillende criteria. Ook ontvang je na de sessie een SMS met een link naar een vragenlijst die je eerlijk moet invullen.',
    question: 'Wil je een mystery shopper worden?',
    yesButton: 'Ja',
    noButton: 'Nee, bedankt',
    successMessage: 'Je moet de studio beoordelen op de volgende criteria:',
    criteria1: 'Telefonische en sociale media consultatie',
    criteria2: 'Consultatie in de studio bij aankomst',
    criteria3: 'Prijsbeleid van de studio',
    criteria4: 'Exterieur: gevel van de studio, uithangborden, locatie, enz.',
    criteria5: 'Interieur van de studio, inrichting',
    criteria6: 'Assortiment van producten, souvenirs en genezing (etalage)',
    criteria7: 'Sfeer: TV, muziek, personeel',
    criteria8:
      'Beschikbaarheid van traktaties, entertainment, tijdschriften en catalogi met schetsen',
    criteria9: 'Communicatie met de vakman',
    criteria10: 'Communicatie met de beheerder',
  },
  cookies: {
    text: `We gebruiken cookies om uw browse-ervaring te verbeteren, 
      gepersonaliseerde advertenties of inhoud te tonen 
      en ons verkeer te analyseren. Door op "Accepteren" te klikken, 
      gaat u akkoord met het gebruik van cookies.`,
    confirm: 'Accepteren',
    more: 'Meer informatie',
    manage: 'Beheren',
    config: {
      necessary: 'Noodzakelijk',
      preferences: 'Voorkeuren',
      statistics: 'Statistieken',
      marketing: 'Marketing',
    },
  },
  header_menu: {
    logout: 'Afsluiten',
    main: 'Home',
    about_us: 'Over ons',
    discount_page: 'Kortingen',
    loyalty_page: 'Loyaliteitsprogramma',
    ai_page_link: 'Schets genereren',
    fonts_page_link: 'Tattoo-lettertypen',
    quality_certificate: 'Kwaliteitscertificaten',
    complaints: 'Klachten en suggesties',
    confidence: 'Privacybeleid',
    public_offer_link: 'Openbaar aanbod',
    work: 'Ik wil werken bij VEAN',
    dockuments_page_link: 'Documenten',
    login: 'Inloggen',
    account: 'Persoonlijk account',
    studying: 'Ik wil studeren!',
    buy_certificate: 'Koop een certificaat',
    feedback: 'Feedback',
    loan: 'Ik wil een lening!',
    services: 'Diensten',
    parlor_social_links: 'Salon sociale links en contactinformatie',
    global_social_links: 'Globale sociale links en contactinformatie',
  },
  post: {
    welcome: 'Hallo. Je was bij ons voor een sessie',
    no_answer_questions: 'Je hebt de volgende vragen niet beantwoord:',
    point: 'Beoordeel de servicekwaliteit en ontvang VeanCoins!',
    send: 'Verzenden',
    error: 'Er is een fout opgetreden!',
    not_found: 'Sessie niet gevonden!',
    not_call: 'Ik hoef niet teruggebeld te worden',
    thank: 'Heel erg bedankt voor je beoordeling!',
    bot: 'Over 21 dagen zal onze bot je bellen vanaf een onbekend nummer om informatie over de service en genezing te bevestigen.',
    certificate: 'Er is een promotiecertificaat voor je gegenereerd van %{gift_price} VeanCoins.',
    social_networks: 'Laat alsjeblieft een recensie over ons achter op sociale netwerken:',
    month: 'Gebruik het binnen een maand en vergeet het niet aan de administrator te herinneren!',
    dissatisfied:
      'Ben je niet tevreden met de geleverde service? We verwerken je verzoek zo snel mogelijk.',
    help_us_improve:
      'Help ons verbeteren door vragen te beantwoorden en ontvang <span class="text-warning">{bonusAmount}</span> VEANCOINS!',
    scores: {
      variants: {
        great: 'Uitstekend',
        good: 'Goed',
        satisfactorily: 'Voldoende',
        bad: 'Slecht',
        fast: 'Snel',
        orally: 'Mondeling',
        in_writing: 'Schriftelijk',
        orally_in_writing: 'Mondeling en schriftelijk',
        yes: 'Ja',
        no: 'Nee',
        didnt_listen: 'Ik heb het niet gehoord',
      },
      administrator:
        'Hoe beoordeel je het advies van de studio-administrator over diensten, meesters en zorg?',
      recommendations: 'Heeft u duidelijke verzorgingsadviezen gekregen?',
      master_contact: 'Heeft de meester zijn contactgegevens met u gedeeld?',
      holl: 'Hoe beoordeel je het comfort bij de receptie (sfeer, netheid, gemak)?',
      price: 'Hoe beoordeel je de prijs van de diensten in de studio?',
      callback: 'Hoe snel kreeg je reactie op de afspraak?',
      master: 'Hoe beoordeel je de vakman in de studio?',
      care: 'Werd je professionele verzorgingsproducten of sieraden aangeboden?',
      buy_other: 'Heb je sieraden of verzorgingsproducten gekocht?',
      need_contact: 'Wil je dat we contact met je opnemen?',
      loyalty_program: 'Neem je al deel aan het loyaliteitsprogramma?',
      sketch: 'Ze boden me aan om een individueel ontwerp/project te bestellen:',
      souvenir: 'Ze boden me aan om souvenirs of merchandising te kopen:',
      repeat_record: 'Wil je een nieuwe afspraak maken?',
    },
    scores_mystery_shopper: {
      instagram: 'Beoordeling van onze INSTAGRAM',
      site: 'Beoordeling van de website: vean-tattoo.%{locale}',
      phone_consultation: 'Telefonisch consult en op sociale netwerken',
      consultation: 'Consult in de studio bij aankomst',
      price: 'Jouw mening over het prijsbeleid van de studio',
      exterior: 'Buitenkant: gevel van de studio, borden, locatie, enz.',
      interior: 'Interieur van de studio, inrichting',
      assortment: 'Assortiment van producten (souvenirs, genezingsproducten)',
      atmosphere: 'Sfeer: TV, muziek, team',
      leisure: 'Snacks, vrije tijd, tijdschriften en ontwerpcatalogi',
      master: 'Communicatie met de vakman',
      admin: 'Communicatie met de administrator',
      master_one_place: 'Werkt de vakman alleen in deze studio? (ja/nee)',
      master_contact: 'Heeft de vakman op verzoek persoonlijke contactgegevens verstrekt? (ja/nee)',
      care: 'Boden ze je verzorgingsproducten en souvenirs aan? Ontving je een klein geschenk (magneten, stickers of een 10%-kortingsbon)? (ja/nee)',
      conclusion: 'Eindbeoordeling van de controle',
    },
    inputs: {
      cost: 'Geef het bedrag aan dat je hebt betaald voor de service',
      cost_other: 'Hoeveel heb je uitgegeven aan sieraden of verzorgingsproducten?',
      review: 'Heb je suggesties voor het verbeteren van de studio?',
      photo: 'Foto van de salon',
    },
    inputs_mystery_shopper: {
      recommendation: 'Jouw aanbevelingen voor ons',
      why_vean: 'Waarom heb je VEAN gekozen?',
    },
  },
  header: {
    online_appointment: 'Online aanmelden',
    post_session: 'Kwaliteitsbeoordeling',
    get_price: 'Consultatie',
    consultation: '(Consultatie)',
    order_consultation: 'Bestel een consult',
  },
  index: {
    parlor: 'Studio',
    master: 'Meester',
    session_type: 'Type dienst',
    session_location: 'Plaats van dienst',
    datetime: 'Datum en tijd',
    create_appointment: 'Aanmelden',
    bonus: '* %{customer_bonus} VeanCoins BONUS VOOR ZELF AANMELDEN',
    sketch: 'Schets ( * Optioneel )',
  },
  session_types: {
    tattoo: 'Tattoo',
    tatouage: 'Tatoeage',
    piercing: 'Piercing',
    stroke: 'Verwijdering',
    training: 'Opleiding',
    training_tattoo: 'Tatoeage opleiding',
    training_piercing: 'Piercing opleiding',
    training_tattouage: 'Tattoo opleiding',
    training_udder: 'Opleiding tattoo verwijderen',
  },
  price_form: {
    client_name: 'Naam',
    client_phone: 'Telefoon',
    client_comm: 'Uw locatie',
    sketch_desc: 'Schets toevoegen (Optioneel)',
    sketch_button: 'Selecteer bestand',
    send: 'Verzenden',
    or: 'Of',
    country: 'Land',
    city: 'Stad',
    comment: 'Opmerking',
    val_error: 'Check uw ingevulde gegevens!',
    ths_message: 'Bedankt! Wij nemen binnenkort contact met u op',
    go_back: 'Terug',
    file_size: 'Het bestandsformaat mag niet groter zijn dan 5 MB!',
  },
  selectors: {
    parlor: {
      placeholder: 'Zoeken op salon naam',
    },
    master: {
      placeholder: 'Zoeken op naam, dienst',
    },
    select_type: {
      select: 'Selecteren',
      next: 'Volgende',
      no_price: 'Onderhandelde',
      ad: 'van',
      da: 'tot',
      selected: 'selected',
      info: 'Prijs kan afhangen van grootte en locatie',
    },
    datetime: {
      select_date: 'Selecteer de datum waarin u bent geïnteresseerd',
      no_active_times:
        'Er zijn geen vrije plaatsen meer voor deze datum. Selecteer a.u.b. een andere!',
    },
  },
  verify: {
    verify_account: 'Account verifiëren',
    type: 'Verificatiemethode',
    check_code: 'Code controleren',
    status: 'Status',
    input_phone: 'Vul telefoonnummer in',
    email_help: 'U kunt ook e-mail gebruiken voor verificatie',
    sms_sent: 'Er werd u een code toegestuurd via SMS, vul deze hieronder in',
    resend_sms_after: 'Opnieuw verzenden beschikbaar over %{seconds} seconden',
    resend_sms: 'Stuur opnieuw',
    buttons: {
      back: 'Terug',
      next: 'Volgende',
      create_appointment: 'Aanmelden',
    },
  },
  success: {
    h1: 'Bedankt! Aanmelding succesvol aangemaakt!',
    h2: 'Zodra de beheerder de aanmelding goedkeurt, ontvangt u een SMS-bericht',
    mystery: 'Een mystery shopper worden',
  },
  about: {
    header: 'Uw gegevens',
    birth_info:
      'Door het invullen van uw geboortedatum zorgen wij ervoor dat u een aanvaardbare leeftijd heeft, en het is nodig voor de verjaardagskorting. :)',
    inputs: {
      name: 'Naam en achternaam',
      find_out: 'Vanwaar heeft u gehoord over',
      birth_date: 'Geboortedatum',
    },
    buttons: {
      skip: 'Overslaan',
      back: 'Terug',
      next: 'Volgende',
    },
  },
  footer: {
    header:
      'Abonneer u op onze sociale netwerken om op de hoogte te blijven van het laatste nieuws.',
    site_navigation: 'Navigatie',
    interest: 'Interessant',
    other: 'Overig',
  },
  subscribe_modal: {
    header: 'Abonneer op nieuws voor een bonus',
    submit: 'Verzenden',
    wrong_data: 'Controleer de juistheid van de ingevoerde gegevens!',
    wrong_email: 'Onjuist e-mailadres ingevoerd!',
    thanks: 'Bedankt voor het inschrijven!',
    error: 'Er is een fout opgetreden!',
  },
  chat_bot: {
    call: 'Bel',
    online_record: 'Online afspraak',
    online_consultation: 'Online advies',
    need_help: 'Hulp nodig met een schets?',
    order_sketch: 'Bestel een schets',
    dress_sketch: 'Een schets passen',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problemsituaties',
      id: 'Nummer',
      status: 'Status',
    },
    reset_password: {
      title: 'Wachtwoord vergeten',
      submit: 'verzenden',
      forgot_password: 'Wachtwoord vergeten',
      phone: 'Voer telefoonnummer in',
      success_message: 'Een tijdelijk wachtwoord is naar uw e-mail/telefoon gestuurd',
    },
    registration: {
      name: 'Naam',
      surname: 'Achternaam',
      registered: 'Registratie',
      congratulations: 'Gefeliciteerd!',
      successfully_registered: 'U bent succesvol geregistreerd!',
      log_in: 'Inloggen',
    },
    options: {
      no_interest_in_promotions: 'Ik ben niet geïnteresseerd in promoties en boni',
      dislike_frequent_sms: 'Ik hou niet van frequente SMS-berichten',
      already_have_tattoo:
        'Ik heb al een tatoeage en ben niet van plan binnenkort een nieuwe te nemen',
      other: 'Anders',
    },
    login_page: {
      log_in_page: 'Inloggen op uw account',
      number_missing: 'Heeft u geen nummer?',
      register: 'Registreren!',
      steps: {
        sign_up: 'Meld je aan voor het loyaliteitsprogramma of LOGIN',
        check_coins: 'Controleer je VeanCoin door je aan te melden voor het loyaliteitsprogramma',
        get_coins: 'Ontvang je VeanCoins aan het einde van elke sessie',
        accumulate: 'Verzamel en spaar VeanCoin in je profiel',
        book_session: 'Boek een sessie en kom naar Vean om je tattoo te laten zetten',
        exchange_coins: 'Wissel VeanCoins in en krijg kortingen',
      },
    },
    find_customer: {
      continue: 'Doorgaan',
      not_found: 'Klant niet gevonden!',
      select_type: 'Selecteer het inlogtype',
      no_password: 'U heeft geen wachtwoord ingesteld!',
      found: 'Gevonden!',
      check: 'Controleren...',
      invalid_phone_or_password: 'Ongeldig telefoonnummer of wachtwoord',
      error_login: 'Fout bij het inloggen',
      phone: 'Telefoon',
      temporary_password_message: 'Je gebruikt een tijdelijk wachtwoord',
      permanent_password_message: 'Je gebruikt een permanent wachtwoord',
    },
    product_card: {
      availability: "Beschikbaarheid in de studio's:",
      unavailable: 'Niet verkrijgbaar',
    },
    store_body: {
      full_points_products: 'Goederen die u kunt aanschaffen met VeanCoins',
      half_points_products: 'Goederen die u kunt aanschaffen met 50% korting',
      no_products: 'Goederen niet beschikbaar',
    },
    description_block: {
      loyalty_system: 'VEAN loyaliteit systeem',
      show_more: 'Meer tonen',
    },
    profile_card: {
      my_sketches: 'Mijn schetsen',
      share_link: 'Deel deze link',
      tips: 'Tip',
      send_certificate: 'Certificaat verzenden',
      vip: 'VIP',
      not_vip: 'Word VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Geld',
      active_certificates: 'Geactiveerde VeanCoins',
      balance: 'Balans',
      sessions: 'Sessies',
      points_alltime: 'Verdiende VeanCoins over de hele tijd',
      verify_account_status: 'Accountstatus',
      security_percent: 'Veiligheid:',
      available_certificates: 'Beschikbare certificaten',
      certificates: 'Monetair certificaat',
      bought_certificates: 'Geactiveerde VeanCoins',
      certificate_number: 'Certificaatnummer',
      available_for: 'Geldig tot: ',
      no_active_certificates: 'Er zijn geen geld certificaten',
      no_active_bought_certificates: 'Geen actieve VeanCoins',
      coming_sessions: 'Aankomende sessies',
      complete_agreement: 'Vul de overeenkomst in',
      date: 'Datum: ',
      you_was: 'Je was aanwezig bij %{sessions} sessies',
      edit: 'Edit',
      not_email: 'om het certificaat te verzenden, moet u een e-mail invullen',
      exist_application: 'er is een toepassing',
      need_exist_application: 'er is geen toepassing',
      need_confirm_account: 'Bevestiging van account nodig',
      verified: 'Geverifieerd',
      choose_payment_method: 'Kies betaalmethode',
      parlor: 'Salon',
      sign_up_online: 'Meld je aan voor een sessie',
      mystery_shopper: 'Mysterieuze shopper',
      sign_up_secret_buyer: 'Meld je aan voor een sessie bij ons en word een mysterieuze shopper',
      pay: 'Betalen',
      prepayment: 'Vooruitbetaling',
      questionnaire: 'Vragenlijst',
      agreement: 'Overeenkomst',
      fill_required_information: 'Het is noodzakelijk om de informatie in te vullen',
      buy: 'Herlaad je saldo',
      archive: 'Archief',
      support_service: 'Klantensupport',
      balance_buy_history: 'Geschiedenis van gekochte VeanCoins',
      balance_history: 'Geschiedenis van verkregen VeanCoins',
      buy_history: 'Aankoopgeschiedenis',
      сost: 'Kosten',
      payment_link: 'Betalingslink',
      attributes: 'Eigenschappen',
      invite_friend: 'Nodig een vriend uit',
      phone_or_email: 'Voer een telefoonnummer of e-mailadres in',
      report_problem: 'Meld een probleem',
      note: 'Voer een opmerking in',
      tooltip_coins:
        'je moet je verzamelde VeanCoins activeren voor de door jou gekozen dienst of voor het kopen van goederen om verbranding binnen 1 jaar te voorkomen',
      tooltip_certificate:
        'aantal van jouw gekochte of als cadeau ontvangen geldcertificaten, die binnen 2 maanden gebruikt kunnen worden',
      tooltip_сonfirmed: 'In de bewerking wijzig «ФИО или username» naar «Naam en Achternaam»',
      tooltip_missing:
        'Voeg informatie over jezelf toe (naam en achternaam, geboortedatum, link naar je sociale media, waar je over ons hebt gehoord, telefoonnummer en e-mail om je identiteit te bevestigen)',
      tooltip_verified:
        'Je moet je account bevestigen met een telefoonnummer of e-mail om je verdiende en verzamelde VeanCoins te beschermen.',
      tooltip_vip:
        'VIP klant is een status die elke klant kan krijgen die voor meer dan 1500 euro aan diensten heeft besteed. VIP status biedt bepaalde voordelen voor de eigenaar, zoals bijvoorbeeld: snelle prioriteitsboeking voor sessies buiten de rij, toegang tot unieke goederen en diensten, na het bereiken van de VIP-status krijgt de klant direct 1000 VeanCoins op zijn account bijgeschreven',
      earn_veancoins: 'Verdien veancoins',
    },
    profile_edit: {
      additional_information: 'Aanvullende informatie over VeanCoins',
      parlors: 'Salon (die dichterbij of de belangrijkste is)',
      client_card: 'Klantenkaart',
      full_name: 'Naam en achternaam of gebruikersnaam',
      birth_date: 'Geboortedatum (voor felicitaties)',
      social_media_link: 'Link naar sociale media',
      find_out: 'Hoe heb je het gevonden',
      or: 'of',
      phone_placeholder: 'Telefoon',
      save: 'Opslaan',
      password_tooltip:
        "Maak een wachtwoord aan om in de toekomst toegang te krijgen tot uw profiel zonder hulp van SMS'en en e-mails. Dit beschermt ook uw persoonlijke gegevens.",
      password: 'Wachtwoord',
      repeat_password: 'Herhaal wachtwoord',
      email_taken: 'Dit e-mailadres is al in gebruik!',
      phone_taken: 'Dit telefoonnummer is al in gebruik!',
      simple_password: 'Te eenvoudig wachtwoord!',
      invalid_password: 'Ongeldig wachtwoord!',
      password_for_application: 'Wachtwoord voor de applicatie (niet verplicht)',
      congratulate: 'Gefeliciteerd!',
      successfully_registered: 'Succesvol geregistreerd!',
      need_confirm_account: 'Accountverificatie vereist',
      yes: 'Ja',
      no: 'Nee',
      phone_confirmed: 'Telefoon bevestigd',
      email_confirmed: 'E-mail bevestigd',
      change_password: 'Wachtwoord wijzigen',
      old_password: 'Oud wachtwoord',
      new_password: 'Nieuw wachtwoord',
      confirm_password: 'Herhaal het wachtwoord',
      invalid_phone_or_password: 'Ongeldige telefoonnummer of wachtwoord',
      error_login: 'Fout bij het inloggen',
      years_old: 'Leeftijd',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Geslacht',
      man: 'Man',
      woman: 'Vrouw',
      gender_hidden: 'Geef de voorkeur aan niet vermelden',
      language: 'Taal',
      preferred_style_status: 'Voorkeursstijlen',
      classic: 'Klassiek / Realisme',
      freak: 'Freak / Trash / Anime',
      fashion: 'Mode / Kunst / Minimalisme',
      rock: 'Rock / Hard',
      pop: 'Pop / Mas',
      accommodation_status: 'Accommodatiestatus',
      local: 'Lokaal',
      tourist: 'Toerist',
      guest: 'Gast',
      lifestyle_status: 'Levensstijl',
      extreme: 'Extreem',
      dance: 'Dans',
      anime: 'Anime',
      game: 'Spel',
      music: 'Muziek',
      lgbt: 'LHBT',
      art: 'Kunst / Moderniteit',
      family: 'Familie / Liefde',
      beauty: 'Schoonheid',
      sexuality: 'Seksualiteit',
      masculinity: 'Mannelijkheid',
      tattoo_count_status: 'Aantal tatoeages',
      pierce_count_status: 'Aantal piercings',
      one: 'Eén',
      two: 'Twee of meer',
      inc: 'Heel veel',
      change_information: 'Informatie opslaan',
    },
    verification: {
      sms: 'Bevestigen via SMS',
      email: 'Bevestigen via Email',
      google: 'Verifieer via Google',
      send_code: 'Stuur code',
      code: 'Verificatiecode',
    },
    loyalty: {
      submit_button: 'Versturen',
      bonus_instruction:
        "Voer een van de vermelde acties uit, voeg bewijs toe (screenshots, foto's, enz.) en ontvang een bonus",
      bonus_amount: 'VeanCoins!',
      review_option_1:
        'Laat een uitgebreide beoordeling achter in het profiel van de meester op de site',
      review_option_2: 'Geef een beoordeling aan de studio of aan de meester op de site',
      review_option_3: 'Geef een beoordeling aan de studio en laat een recensie achter op de kaart',
      review_option_4: 'Deel onze studio op sociale netwerken',
      return:
        'Als de foto die je hebt verzonden niet onder een van de categorieën valt waarvoor we boni toekennen, zullen de ontvangen boni worden geannuleerd bij de aankoop van een cadeaubon.',
      evidence_label: 'Bewijs',
    },
    buy_certificate: {
      payment_type_title: 'Type aankoop',
      payment_type_subtitle: 'Wat wilt u doen',
      buy_balance: 'Saldo opwaarderen',
      buy_certificate: 'Certificaat kopen',
      amount_title: 'Bedrag',
      amount_subtitle: 'Voer bedrag in',
      payment_method_title: 'Betaalmethode',
      payment_method_subtitle: 'Kies een betaalmethode',
      payment_title: 'Betaling',
      forming_payment: 'Betaling voorbereiden...',
      pay: 'Betalen',
      continue: 'Doorgaan',
      back: 'Terug',
      submit_to_email: 'Certificaat naar e-mail verzenden',
      get_vean_card: 'Haal VEAN-kaart in de studio op',
      vean_card_description:
        "De VEAN CARD is uw kaart waarop VEAN TATTOO-certificaten automatisch worden geactiveerd, wat veiligheid en gemak garandeert. Al uw cadeaus en kortingen zijn altijd binnen handbereik, en het gebruik van de kaart is zo eenvoudig en voordelig mogelijk. Haal uw kaart op in de VEAN TATTOO-studio's en ontdek vandaag nog alle voordelen!",
      total_balance: 'Evenwicht',
      certificate: 'Certificaat',
      customer_balance: 'Klantenbalans',
      certificate_type: 'Certificatetype',
      promotion_certificate: 'Actiecertificaat',
      buy_for_myself: 'Voor mezelf kopen',
      promotion_certificate_message:
        'Fysieke certificaten worden uitgegeven tot 31 december 2024. Vanaf 1 januari 2025 worden certificaten omgezet in punten en weergegeven in het persoonlijke account van de gebruiker.',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Meer dan 160 salons',
      clients_count: 'Meer dan 1.000.000 klanten',
      largest_company: "Het grootste netwerk van tattoostudio's ter wereld",
    },
    master_block: {
      our_masters: 'Onze meesters',
    },
    master_card: {
      works_count: 'Aantal werken: ',
    },
    no_admin_block: {
      leisure: 'Vrije tijd',
      session_record: 'Inschrijven voor een sessie',
      about_company: 'Over het bedrijf',
      care: 'Verzorging',
      quality_certificates: 'Kwaliteitscertificaten',
      public_buyout_offer: 'Publiek aanbod',
      complaints_offers: 'Klachten en suggesties',
      loyalty_system: 'Systeem loyaliteit',
      discount_page: 'Kortingen',
    },
    peculiarities_block: {
      about_studio: 'Over deze studio',
      clients: 'Klanten',
      sessions: 'Sessies',
      studio_hours: 'Openingstijden: ',
      studio_phone: 'Studio nummer: ',
      wifi: 'Wi-fi: ',
      password: 'Wachtwoord: ',
      from_to: 'van %{time_start} tot %{time_end}',
    },
    photos_block: {
      photos: "Foto's van de salon",
    },
    top_works: 'Werken',
    care_important: 'Het belangrijkste bij een piercing en tattoo is genezing en verzorging.',
    cabinet: 'Persoonlijk kabinet',
    complete_agreemnent: 'Volledige overeenkomst',
    is_blanket: 'Er is een deken om warm te blijven',
    is_water: 'Er zijn warme dranken',
    is_hot_drinks: 'Er zijn zoetigheden',
    is_treats: 'Er zijn koude dranken',
  },
  promotion: {
    submit: 'Verzenden',
    warning: 'Let op! Controleer de juistheid van de ingevoerde gegevens!',
    name: 'Naam / Bijnaam',
    required: 'Dit veld is verplicht!',
    max_value: 'Mag niet meer dan 100 tekens bevatten!',
    phone: 'Telefoon',
    social_link: 'Link naar sociale media',
    wrong_format: 'Verkeerd formaat!',
    terms: 'Ik heb de privacyverklaring gelezen en ga akkoord',
    know_bonus: 'Ik ben op de hoogte dat ik bonuspunten zal ontvangen',
    error: 'Er is een fout opgetreden',
    thanks: 'Bedankt!',
    bonus: 'U heeft %{gift_price} punten ontvangen',
    do_not_disturb: 'Niet storen (SMS en oproepen van VeanTattoo uitschakelen)',
    get_promo: 'Ik wil informatie ontvangen over kortingen en promoties',
    first_time_veancoins_notification:
      'Om VeanCoins voor de eerste keer te gebruiken, moet je contact opnemen met de beheerder van de studio. VeanCoins en Geld hebben verschillende gebruiksregels',
  },
  another_studio_drawler: {
    another: "Andere studio's",
  },
  landing: {
    best_studio: 'De tattoo studio van uw stad',
    vean_wide: 'VEAN TATTOO NETWERK',
    studio_count: "Meer dan 160 tattoo studio's",
    quality: 'Kwaliteit en veiligheid garantie',
    get_price: 'PRIJS OPVRAGEN',
    advantage: 'Voordelen',
    top_works: 'Top Werken',
    prices: 'Prijzen', //долджен быиь
    contact_us: 'Contacteer ons',
    studio_photo: 'Foto van de Studio',
    prices_services: 'Prijzen en diensten',
    about: 'Over ons',
    loyalty_system: 'Loyaliteitsprogramma', //zou moeten zijn
    why_vean: 'Waarom VEAN',
    visit_count: 'Mensen deze studio bezocht',
    description:
      "VeAn Tattoo netwerk is tegenwoordig de grootste ter wereld: wij hebben meer dan 160 salons in 14 landen van Europa. Wij hebben deze branche op een geheel nieuw, hoger niveau gebracht. De specialisatie van het netwerk is vrij breed, maar de hoofdrichting is de tattoo en piercing industrie. Sleutelprincipes van VeAn Tattoo: Deskundigheid en toegankelijkheid: Wij streven ernaar dat elke klant zich comfortabel voelt bij elke fase van het proces, om die reden zijn onze specialisten bereid om al uw vragen online te beantwoorden. Reputatie en klantgerichtheid: Elke klant is een mogelijkheid om een uniek kunstwerk op zijn huid te creëren. Wij waarderen uw tijd en streven ernaar om ervoor te zorgen dat de klant onze studio verlaat met een verbeterde stemming en een ongelooflijke tattoo. Wij hechten veel belang aan onze reputatie en waarderen elke klant van ons netwerk. Waarde en toegankelijkheid: Wij bieden de perfecte prijs-kwaliteitverhouding. Onze diensten zijn toegankelijk voor een breed publiek, en wij offeren niets op aan kwaliteit. Gecertificeerd en hoogwaardig apparatuur: Onze materialen voldoen aan de hoogste veiligheids- en kwaliteitsnormen. Wij garanderen dat onze klanten uitsluitend het beste krijgen. Hoogopgeleide specialisten en een breed scala aan diensten: Bij VeAn Tattoo studio's werken uitsluitend gecertificeerde meesters die over de hele wereld gewaardeerd en gerespecteerd worden. Wij bieden alle bestaande stijlen en technieken van tattoo's, ontwikkeling van gratis individuele schetsen aan de hand van uw behoeften. Daarnaast bieden wij u de mogelijkheid om in te schrijven op al onze officiële pagina's op sociale media. Hierdoor blijft u op de hoogte van speciale aanbiedingen, promoties, prijsvragen, werken van meesters, het laatste nieuws en updates van VeAn Tattoo bedrijf.",
    advantages: {},
    our_masters: 'Onze meesters', //doljen bit
    from: 'van',
    no_price: 'Onderhandelde',
    form: {
      get_price_consultation: 'Prijs opvragen / Consultatie',
      name: 'Naam',
      phone: 'Telefoon',
      place: 'Uw plaats',
      subscribe_news: 'Abonneren op nieuwsbrief voor een bonus',
    },
  },
  form_messages: {
    submit: 'Verzenden',
    warning: 'Let op! Controleer de correctheid van de ingevoerde gegevens!',
    required: 'Dit veld is verplicht!',
    length: 'Niet meer dan %{length} letters!',
    file_length: 'Niet meer dan %length} bestanden!',
    wrong_format: 'Onjuist formaat!',
    error: 'Er is een fout opgetreden',
    thanks: 'Bedankt!',
    required_label: 'Verplicht',
    not_required_label: 'Optioneel',
  },
  training: {
    training: 'OPLEIDING',
    tattoo_school: 'TATOEAGESCHOOL',
    biggest_school: "het grootste netwerk van tattoostudio's ter wereld",
    ne_otkladivaj: 'Stel<br/>jezelf<br/>niet uit tot later',
    learn: 'Leer tatoeëren<br/>en word een topmeester',
    reviews: 'Beoordelingen van afgestudeerden',
    record: 'Inschrijven',
    video_text: 'Hoe kunt u helpen,<br/>wat gaat u leren?',
    ded: {
      consultation: 'consultatie',
      get_more: 'Meer informatie krijgen',
    },
    header_bar: {
      main: 'Hoofd',
      get: 'Wat je krijgt',
      program: 'Cursusprogramma',
      about: 'Over ons',
      reviews: 'Beoordelingen',
    },
    our_course: {
      head: 'Onze<br/>cursussen',
      description_1:
        'dit is de plek waar je altijd een antwoord op je vraag krijgt en iets nieuws leert',
      description_2:
        'En zelfs als je geen artistieke achtergrond hebt - het resultaat zal niet lang op zich laten wachten',
      label: '[ bij ons is het warm, gezellig en opwindend ]',
      description_3:
        'We houden van onze studenten en proberen alles te doen zodat het leerproces zo comfortabel mogelijk is',
    },
    banner: {
      we_teach: 'Wij leren je tatoeëren',
      good_make: 'WIJ TATOEËREN GEWELDIG',
      our_masters: 'ONZE MEESTERS ZIJN KLAAR OM KENNIS, GEHEIMEN, TECHNIEKEN TE DELEN',
      learn_too: 'En we zullen jou ook leren',
    },
    modal: {
      thanks: 'Bedankt!',
      error: 'Er is een fout opgetreden!',
      complete: 'Vul het formulier in en we bellen je',
      full_name: 'Volledige naam',
      phone: 'Telefoonnummer',
      submit: 'Versturen',
      cancel: 'Annuleren',
    },
    paragraph: {
      header_1: 'We zullen zeker leren',
      content_1: 'We doen geen massale training',
      header_2: 'kunnen tekenen',
      content_2: 'We leren je alles/geen artistieke opleiding is helemaal geen probleem',
      header_3: 'Certificaat',
      content_3:
        'Na afronding van de cursussen ontvang je een certificaat van internationale standaard. Goedgekeurd door Global Tattoo ACC',
      header_4: 'We bieden werk',
      content_4:
        'OMG, meen je dat serieus? Ja, de beste afgestudeerden kunnen deel uitmaken van ons team en overal in Europa werken',
      header_5: 'Contract',
      content_5: 'We werken niet op "goed vertrouwen". Alles is officieel en volgens contract',
      no_sleep: {
        header: 'niet overslapen',
        rect_1: 'Hoe?',
        rect_2: 'LEVEN',
        main_label: 'Begin nu met leren',
        label_1: '1000+ afgestudeerden',
        label_2: '100% heeft de training voltooid',
        label_3: '80% kreeg een baan',
        label_4: '300+ partners in de tattoo-industrie',
      },
    },
    principies: {
      head: 'Garanties',
      label_1: '1. Cultuur',
      label_2: '2. Kwaliteit',
      label_3: '3. Methoden',
      heading: 'Alles is gebaseerd op principes',
      description_1:
        '11 jaar geleden besloten we de cultuur van de tattoo-industrie te ontwikkelen, niet zoals iedereen, maar op onze eigen manier - gebaseerd op kennis en ervaring',
      description_2:
        'Vandaag hebben we 1000+ uitstekende tatoeëerders opgeleid. 80% werd deel van ons team. We kunnen met vertrouwen zeggen dat iedereen het tatoeëren kan leren',
      description_3:
        'Het gaat allemaal om de methode die we gebruiken, die we constant verbeteren samen met het team, kunstenaars en onze afgestudeerden, die regelmatig feedback geven',
    },
    packages: {
      head: "Cursusprogramma's",
      from: 'van',
      plus: 'Voordelen',
    },
    facilities: {
      head: 'Mogelijkheden en voordelen',
      subhead: 'Kies onze cursussen en voel de magie van de tattoo-industrie',
      card: {
        head_1: 'Elke afgestudeerde ontvangt een internationaal standaardcertificaat',
        label_1: '- jouw ticket naar een wereld vol mogelijkheden',
        head_2: 'Aanbeveling en steun van een groep professionele meesters',
        label_2: 'Jouw succes is onze trots!',
        head_3: 'Tattoo-community, krijg advies en ondersteuning van onze experts',
        label_3: 'Jouw nieuwe leven in de wereld van tatoeëren begint nu!',
      },
    },
    review: {
      head_1: 'Gevalstudies van meesters',
      head_2: 'Beoordelingen',
      comment_1:
        'De tattoo-opleiding op deze school heeft al mijn verwachtingen overtroffen! De leraren zijn professionals in hun vakgebied, elk stadium van de training wordt grondig besproken, de sfeer is geweldig. Nu kan ik met vertrouwen mijn creatieve ideeën realiseren op de huid van klanten!',
      comment_2:
        "Bedankt aan de school voor zo'n kwalitatieve tattoo-opleiding! Het was in het begin een beetje eng om hieraan te beginnen, maar dankzij de attente en geduldige leraren voelde ik me zeker. Nu ontvangen mijn werken erkenning en bewondering!",
      comment_3:
        'Het was een ongelooflijke reis in de wereld van het tatoeëren! De school biedt alle noodzakelijke kennis en vaardigheden om een echte professional te worden. Ik ben dankbaar voor de ondersteuning en inspiratie die ik hier heb ontvangen',
    },
  },
  questionary: {
    first: {
      heading: 'TOESTEMMING VOOR PROCEDURE BIJ VEAN TATTOO STUDIO',
      textbox:
        'Als de klant jonger is dan 18 jaar, moet de toestemming worden ingevuld door ouders of wettelijke voogden (In British Columbia, Canada, wordt een minderjarige beschouwd als iemand jonger dan 19 jaar. Als je jonger bent dan 19, kies dan de TWEEDE optie)',
      has_18: 'JA, ik ben 18 jaar of ouder',
      no_18: 'NEE, ik ben jonger dan 18 jaar',
    },
    second: {
      warning: 'Let op! Op de dag van de sessie is het noodzakelijk om met voogden te komen!',
      name: 'Volledige naam',
      birthdate: 'Geboortedatum',
      address: 'Woonadres',
      email: 'E-mail',
      sex: 'Geslacht',
      heading: 'UIT TE VOEREN PROCEDURE',
      man: 'Mannelijk',
      woman: 'Vrouwelijk',
      other: 'Niet gespecificeerd',
      parent_data: 'Gegevens van een van de ouders:',
      child_data: 'Gegevens van een MINDERJARIGE:',
      child_field_placeholder: 'Zoon, dochter, enz.',
      child_field_label: 'Voor wie wordt de procedure uitgevoerd',
      confirm:
        'Ik, als wettelijk vertegenwoordiger van een minderjarige, geef toestemming voor het uitvoeren van de volgende procedure voor mijn kind.',
    },
    third: {
      heading: 'ALLES WAT U MOET WETEN OVER DE GENEZINGSPERIODE:',
    },
    fourth: {
      nothing: 'Geen van bovenstaande',
      has_diseases: 'Heeft u enige ziektes?',
      has_contraindications: 'Heeft u enige contra-indicaties?',
      heading1:
        'ALS U EEN VAN DE VOLGENDE ZIEKTEN HEEFT, MOETEN WE HET WETEN, GELIEVE DE RELEVANTE PUNTEN AAN TE DUIDEN:',
      heading2: 'ALS ER TEGENAANWIJZINGEN ZIJN, GEEF DAN DE RELEVANTE INFORMATIE:',
    },
    fifth: {
      heading: 'VERBODEN NA DE PROCEDURE:',
    },
    sixth: {
      heading: 'WAAR HEBT U VAN ONS GEHOORD:',
      label:
        "Na 21 dagen kan een van onze medewerkers contact met u opnemen als onderdeel van een kwaliteitscontrole van de geleverde diensten. Daarnaast ontvangt u na de sessie SMS-informatie en is het mogelijk dat onze geautomatiseerde robot een telefoontje doet. In uw persoonlijke account kunt u zich afmelden voor informatieservices via telefoongesprekken en SMS, en u kunt ook al uw gegevens en foto's verwijderen na 21 dagen vanaf de datum van de dienst.",
    },
    seventh: {
      heading: 'VERKLARING EN TOESTEMMING',
    },
    signature: {
      heading:
        'Ik, ondergetekende (%{name}), geef hierbij toestemming voor de procedure van vandaag met mijn deelname\n' +
        '%{session_type} op mijn lichaam en ga akkoord met de voorwaarden die in dit document zijn opgenomen.',
    },
    next: 'Volgende',
    submit: 'Verzenden',
    no_responsibility: 'De studio is niet verantwoordelijk voor deze sessie',
    acquaint: 'Ik heb de verstrekte informatie gelezen',
    acquaint_2:
      'Ik begrijp dat Vean Tattoo niet verantwoordelijk is voor het genezingsproces.\n' +
      'In het geval van correctie van de hierboven genoemde tatoeage of piercing, bedragen de kosten van de correctie 30% van de kosten van de dienst zelf.\n' +
      'Als u niet tevreden bent met het resultaat van de correctie, wordt het geld voor de dienst in dit geval niet terugbetaald, omdat de eerder genoemde gebieden niet gunstig zijn voor de uitvoering van de dienst zelf.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Geen oplossing',
      in_check: 'In controle',
      problem_solved: 'Probleem opgelost',
      wait: 'Wachten',
      small: 'Kleine situatie',
      unanswered_check: 'Controle zonder antwoord',
    },
  },
  master_page: {
    card: {
      active: 'Actief',
      not_active: 'Niet actief',
      master_since: 'Meester VEAN sinds',
      services: 'Diensten',
      styles: 'Stijlen',
      book: 'Boeken',
    },
    information: {
      top_works: 'Topwerken',
      awards_and_diplomas: "Prijzen en diploma's",
    },
  },
  stripe_warning:
    'Let op! Het minimale betalingsbedrag met stripe is €20. Als een kleiner bedrag nodig is, neem dan contact op met de studio voor details',
  check_in: {
    rate: {
      upload_photo: 'Upload een foto van uw werk',
      upload_photo_desc:
        'U kunt een mooie foto van uw werk maken voor onze sociale media en website. De administrator maakt ook een foto voor kwaliteitscontrole en om het geleverde werk te garanderen. U kunt er altijd voor kiezen om de foto niet te publiceren of te delen, en uw gegevens worden volledig verwijderd na genezing.',
      bad_comment: 'Leg alstublieft uit waarom u een lage beoordeling heeft gegeven',
      review: 'Laat een beoordeling achter!',
      review_desc:
        'Wij zouden het zeer op prijs stellen als u een beoordeling achterlaat. Uw mening is belangrijk voor ons!',
      cashback: 'x2 cashback op uw account in de app',
      send_invoice: 'Stuur factuur naar het e-mailadres',
      influencer: 'Heeft u meer dan 2000 volgers op sociale media?',
    },
    welcome_message: 'WE WENSEN U EEN FIJNE SESSIE!',
    session_started: 'UW SESSIE IS BEGONNEN',
    next_steps: 'Na voltooiing van de sessie zijn er andere stappen beschikbaar voor u',
    buy_healing_product: 'WE RADEN AAN EEN GENEZENDE PRODUCT TE KOPEN',
    skip: 'Overslaan',
    leave_review: 'Laat alstublieft een beoordeling achter',
    your_impression: 'Wat zijn uw algemene indrukken?',
    add_photo: 'Voeg een foto van het werk toe om een extra 1 Vean Coin te krijgen',
    send: 'Verzenden',
    payment_success: 'Betaling was succesvol',
    get_invoice: 'Wilt u een factuur ontvangen?',
    invite_friend: 'Vriend uitnodigen',
    recommend_us: 'Aanbevelen ons aan uw vrienden en verdien samen met hen',
    thank_employees: 'Wij raden ook aan onze medewerkers te bedanken',
    you_can_tip:
      'Beste Nikita, u kunt onze medewerkers ook bedanken met een bepaald bedrag aan fooien',
    own_amount: 'Eigen bedrag',
    who_to_tip: 'Aan wie moet de fooi worden toegewezen?',
    payment_type: 'Betaalwijze',
    continue: 'Doorgaan',
    summary: 'Samenvatting',
    healing_agent: 'Geneesmiddel',
    tips: 'Fooien',
    session_payment: 'Sessie betaling',
    total: 'Totaal',
    waiting: 'Wachten...',
    available_funds: 'Beschikbare fondsen',
    refill_balance: 'Saldo opladen',
    go_to_payment: 'Ga naar betaling',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Fooien',
    prepayment: 'Vooruitbetaling',
    have_questions: 'Heb je vragen?',
    title: 'Sessies',
    selectStatus: 'Selecteer een status',
    selectParlor: 'Selecteer een salon',
    loading: 'Laden...',
    fromDate: 'Vanaf datum',
    toDate: 'Tot datum',
    search: 'Zoeken',
    all: 'Alle',
    status: {
      100: 'In afwachting',
      200: 'Nieuw',
      300: 'Bezig',
      400: 'Voltooid',
      500: 'Geannuleerd',
      600: 'In afwachting van betaling',
    },
    details: 'Gegevens van de sessie',
  },
  master: {
    rank: 'Rang',
    works_count: 'Aantal werken',
    address: 'Adres',
  },
  modalPhone: {
    title: 'Aandacht!',
    info: 'Geachte klant, voor uw veiligheid en een effectieve werking van onze service is het verplicht om alle gegevens in te voeren. Dit helpt ons om tijdig te reageren op belangrijke kwesties en uw bescherming te waarborgen.',
    reminder: 'Herinner me later',
    go_to_settings: 'Ga naar instellingen',
    roughly_price:
      'De geschatte kosten zijn afhankelijk van de nauwkeurigheid van de door u ingevulde gegevens',
    success_message: 'Dank u!',
  },
  studyingRegistration: {
    become_student: 'Word een student',
    step_1: 'Algemene gegevens',
    step_2: 'Persoonlijke informatie',
    step_3: 'Studie details',
    city: 'Stad',
    city_placeholder: 'Voer uw stad in',
    name: 'Voornaam',
    name_placeholder: 'Voer uw voornaam in',
    last_name: 'Achternaam',
    last_name_placeholder: 'Voer uw achternaam in',
    date_of_birth: 'Geboortedatum',
    date_of_birth_placeholder: 'Voer uw geboortedatum in',
    phone: 'Telefoonnummer',
    email: 'E-mail (optioneel)',
    email_placeholder: 'Voer uw e-mail in',
    social_media: 'Sociale media',
    social_media_placeholder: 'Link naar uw profiel',
    referral_source: 'Hoe hebt u over ons gehoord?',
    referral_source_placeholder: 'Kies een bron',
    study_type: 'Studie type',
    study_type_placeholder: 'Kies een studie type',
    start_date: 'Geschatte startdatum',
    start_date_help: 'Voer de geschatte startdatum in',
    hours_number: 'Aantal uren',
    practice_number: 'Aantal praktijken',
    details: 'Details',
    education_price: 'Opleidingsprijs',
    moto: 'Een lesmethode die decennialang is getest',
    description: 'Beschrijving',
    material: 'Materiaal',
  },
  studying_main: {
    become_student: 'Word student!',
    consultation: 'Consultatie',
    want_to_study: 'Ik wil studeren!',
    consult_with_our_advisor:
      'Weet je niet waar je moet beginnen? Neem contact op met onze adviseur!',
  },
  errorTicket: {
    error_description: 'Meld het probleem van de app en wij lossen het snel op',
    error_button: 'Meld de storing van de app',
    problem_situation_description: 'Is er een probleem na de sessie? Laat het ons weten',
    problem_session_button: 'Meld het probleem na de sessie',
    problem_session: 'Een probleem melden na de sessie',
    sidebar_title: 'Probleem melden',
    main_title: 'Foutrapport aanmaken',
    basic_info:
      'Bij het indienen van een ticket is het belangrijk om alle benodigde informatie te verstrekken voor een snelle en efficiënte afhandeling van uw verzoek. Hier is een voorbeeldstructuur van het rapport die u kan helpen:',
    ticket_title: 'Ticket titel:',
    short_description:
      "Korte beschrijving van het probleem (bijvoorbeeld 'Problemen met het aanmaken van sessies')",
    first_title: 'Probleembeschrijving',
    detailed_description: 'Gedetailleerde beschrijving:',
    problem_description:
      'Beschrijf de kern van het probleem, wat er precies aan de hand is en waar de storing ligt.',
    reproduction_steps: 'Stappen om het probleem te reproduceren:',
    reproduction_steps_description: 'Som de stappen op die tot het probleem leiden.',
    expected_result: 'Verwacht resultaat:',
    expected_result_description: 'Wat verwachtte u te ontvangen of te zien?',
    actual_result: 'Feitelijk resultaat:',
    actual_result_description: 'Wat gebeurde er in plaats van het verwachte resultaat?',
    important_data: 'Belangrijke gegevens',
    links: 'Links:',
    links_description: 'Geef links op naar de probleemgebieden of documenten.',
    photos_and_screenshots: "Foto's en screenshots:",
    photos_and_screenshots_description:
      'Voeg afbeeldingen toe die kunnen helpen om het probleem te visualiseren.',
    session_numbers: 'Sessie nummers:',
    session_numbers_description: 'Geef de sessienummers op waarin het probleem zich voordeed.',
    additional_info: 'Aanvullende informatie:',
    additional_info_description: 'Voeg aanvullende informatie toe die nuttig kan zijn.',
    priority_and_urgency: 'Prioriteit en urgentie:',
    priority_and_urgency_description:
      'Als het probleem een aanzienlijke impact heeft op uw werk, geef dit dan hier aan en vraag om een hoge prioriteit.',
    issue_title: 'Titel',
    issue_title_placeholder: 'Voer een korte beschrijving van het probleem in',
    description: 'Beschrijving',
    description_placeholder: 'Beschrijf het probleem uitgebreider',
    files: 'Bestanden',
    drag_and_drop: 'Sleep hier een bestand of kies er een',
    uploaded_files: 'Geüploade bestanden',
    create_button: 'Aanmaken',
    problem_type: 'Probleemtype',
    select_problem_type: 'Kies een probleemtype',
    technical: 'Technisch',
    non_technical: 'Niet-technisch',
    loading: 'Laden...',
    main_page: 'Ga naar de hoofdpagina',
    checkbox: 'U moet akkoord gaan met alle punten.',
  },
  multistep: {
    basic_info: 'Basisgegevens',
    type: 'Type',
    summary: 'Samenvatting',
    details_rent: 'Verhuurgegevens',
    confirmations: 'Bevestigingen',
    open_google: 'Openen in Google Maps',
  },
  check_in_view: {
    important: 'Belangrijk',
    log_in_state_greet: 'Log in op uw account om uw bonussen en kortingen bij te houden.',
    log_in: 'Inloggen',
    download_app_greet: 'Download onze app en ontvang een bonus',
    download: 'Downloaden',
    welcome: 'Welkom',
    studio_info: 'Studio-informatie',
    recommended_products: 'Aanbevolen producten',
  },
  toast: {
    certificate_success: 'Uw certificaat is naar uw e-mailadres gestuurd',
  },
  tattoo_removal: {
    title: 'LASER / VERLICHTING',
    registration_description:
      'Kies een geschikte datum en tijd om te boeken in slechts een paar klikken',
    registration_button: 'Snelle registratie voor een verlichtingssessie',
    consultation_description: 'Details verduidelijken? Wij helpen je graag!',
    consultation_button: 'Consultatie',
    header_nav_title: 'Tattoo Verlichting',
    choose_service: 'Soort dienst',
    choose_service_placeholder: 'Kies een soort dienst',
    paste_tattoo: 'Voeg een foto toe van het verwijderen / verlichten (optioneel)',
    choose_size: 'Maat',
    choose_size_placeholder: 'Kies maat',
  },
  success_form: {
    go_personal_acc: 'Ga naar je persoonlijke account',
    dont_have_acc: 'Heb je geen account?',
    sign_up: 'Meld je aan!',
  },
  loyalty_card: {
    client_card: 'Klantkaart',
    card_number: 'Kaartnummer',
    card_photo: 'Kaartfoto',
  },
  discount: {
    first_session: 'korting voor de eerste sessie',
    second_session: 'korting voor de tweede sessie',
  },
  days: {
    MON: 'MA',
    TUE: 'DI',
    WED: 'WO',
    THU: 'DO',
    FRI: 'VR',
    SAT: 'ZA',
    SUN: 'ZO',
  },
  calculate_price: {
    label: "Om de prijs te weten",
    color_options: {
      black_and_white: "Zwart & Wit",
      colored: "Kleur"
    },
    additional: "Extra",
    select_location: "Selecteer locatie",
    select_style: "Selecteer stijl",
    select_size: "Selecteer maat",
    select_colored: "Kleur",
    select_parlor: "Selecteer salon",
    select_service: "Selecteer service",
    cost: "Kosten",
    from: "Van",
    to: "Tot",
    book: "Maak een afspraak",
    no_info: "Geen informatie"
  }
};
