import { computed, onMounted, reactive } from 'vue';


export const useUTM = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmFields = reactive({});

  const saveUTM = () => {
    for (const key of searchParams.keys()) {
      if (key.includes('utm_')) {
        sessionStorage.setItem(key, searchParams.get(key));
      }
    }
  };

  const bitrixUTMFields = computed(() => {
    return Object.keys(utmFields).reduce((utmParams, key) => {
      utmParams[`fields[${key.toUpperCase()}]`] = utmFields[key];
      return utmParams;
    }, {});
  });

  onMounted(() => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes('utm_')) {
        utmFields[key] = sessionStorage.getItem(key);
      }
    });
  });

  return {
    saveUTM,
    utmFields,
    bitrixUTMFields,
  };
};
