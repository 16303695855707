import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rate" }

import { MazRadioButtons } from 'maz-ui/components';
import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormKitToggleButton',
  props: {
  context: Object,
},
  setup(__props) {

const props = __props;

const selected = ref(Number(props.context.node.value) || null)

watch(selected, (value) => {
  props.context.node.input(value);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(MazRadioButtons), {
      modelValue: selected.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
      options: props.context.options ?? []
    }, null, 8, ["modelValue", "options"])
  ]))
}
}

})