export const toBase64 = (image) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      resolve(e.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(image);
  });
};

export const textToBase64 = (text) => {
  return window.btoa(text);
};

export const base64ToText = (base64) => {
  return window.atob(base64);
};
