import * as customer from '@/api/customer/customer'
import * as student from '@/api/customer/student'
import * as session from '@/api/customer/session'
import * as cart from '@/api/customer/cart'
import * as formAction from '@/api/customer/formAction'
import * as log from '@/api/customer/log'
import * as credit from '@/api/customer/credit'
import * as coins from '@/api/customer/coins'
import * as bonus from '@/api/customer/bonus'
import * as certificate from '@/api/customer/certificate'
import * as promocode from '@/api/customer/promocode'
import * as tattooRemoval from '@/api/customer/tattooRemoval'
import * as TattooRemovalConsultationData from '@/api/customer/tattooRemovalConsultation'
import * as studyingRegistration from '@/api/customer/studentRegistration'

const customerApi = {
  customer,
  student,
  session,
  cart,
  formAction,
  log,
  coins,
  credit,
  bonus,
  certificate,
  promocode,
  tattooRemoval,
  TattooRemovalConsultationData,
  studyingRegistration
}

export { customerApi };