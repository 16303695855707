export default {
  veancoins: {
    summ: 'Total bonuses',
    customer_bonus: 'Customer bonus',
    vip_gift: 'VIP gift',
    vip_price: 'Minimum VIP amount',
    cash_back_sells: 'Cashback for sales',
    cash_back_certificate: 'Cashback for certificate',
    cash_back_student: 'Cashback for training',
    cash_back_service: 'Cashback for service',
    cash_back_money: 'Cashback for balance top-up',
  },
  support: {
    problem_situation_link: 'I have a problem after the session',
    other_problem_link: 'I have another problem',
    problem_situation: {
      header: 'Problem after the session',
      form: {
        session: 'Session',
        created_by_comment: 'Describe the problem',
      },
    },
    complain: {
      header: 'Complaints and suggestions',
      form: {
        name: 'Name',
        phone_number: 'Phone number',
        email: 'E-Mail',
        parlor: 'Salon',
        description: 'Describe the problem',
        feedback: {
          overall_quality: 'How do you rate the overall quality of service in our salon?',
          professional_skills: 'Were you satisfied with the professional skills of the artist?',
          staff_politeness: 'How polite and attentive were the salon staff?',
          waiting_time: 'Were you satisfied with the waiting time for your service?',
          staff_professionalism: 'How do you rate the professionalism of our staff?',
          salon_cleanliness: 'How do you rate the cleanliness and comfort in our salon?',
          service_value: 'Do you think the cost of services matches their quality?',
          recommend: 'Would you recommend our salon to your friends and acquaintances?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'What is the credit for',
    type_education: 'Type of education',
    type_service: 'Type of service',
    credit_type: 'Type of credit',
    how_to_contact: 'How to contact you',
    note: 'Comment',
  },
  items_view: {
    header: 'Items',
    filter_form: {
      name_placeholder: 'Name',
      category_placeholder: 'Category',
      parlor_placeholder: 'Parlor',
      all_categories: 'All categories',
      all_parlors: 'All parlors',
    },
    load_more_button: 'Load more',
    not_found_message: 'Nothing found',
  },
  item_view: {
    error_message: 'Error loading data',
    available_in_parlor: 'Available in your parlor',
    not_available_in_parlor: 'Not available in your parlor',
    similar_items_label: 'Similar items',
    log_in_to_buy: 'Log in to buy',
  },
  item_simple_card: {
    studio_price_label: 'Studio price',
    shop_price_label: 'Shop price',
  },
  student_candidate: {
    form: {
      who_studing: 'Student or seat rental',
      phone_number: 'Phone number',
      first_name: 'First Name',
      last_name: 'Last Name',
      social_link: 'Link to any social network',
      parlors: 'Studio',
      couch: 'Tattoo chair',
      find_out: 'How did you find out about us',
      start_date: 'Desired start date',
      types: 'Session types',
      work_permit_document: 'Work permit document',
      sanitary_book: 'Sanitary book',
      identity_proof: 'Identity proof',
      work_photos: 'Work photos',
      end_date: 'Desired end date',
      confirmations: 'I confirm that',
      know_buy_material: 'I acknowledge that I can purchase materials at the studio',
      customer_will_complete_agreement: 'Each of my clients will sign a client agreement',
      can_show_documents: 'I can provide original documents in the studio if necessary',
      has_country_permission: 'I have permission to stay in the country',
      has_san_book: 'I have a sanitary book',
      has_passport: 'I have an identity document (ID)',
      conditions: 'I have read the rental conditions',
    },
  },
  user: {
    referral_links: {
      booking: 'Session booking',
      student_candidate: 'Rental of space',
      store: 'Client store',
      candidates_form: 'Invite an employee',
    },
  },
  products: {
    want_add: 'I want to add selected care products to the session cost',
    no_warranty: '*without this option, we cannot guarantee you 100% successful healing',
    button_add: 'Add',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Used supplies',
      name: 'Name',
      count: 'Quantity',
    },
  },
  booking: {
    more_interest: 'More interesting things',
    active_promo: 'Current promotions',
    cashback: 'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          for the <span class="text-success">online</span> booking',
    fast_booking: 'Fast booking',
    develop_sketch: 'Sketch development',
    fast_booking_warning:
      'The booking is not final, our manager will contact you to confirm the time and date of the session',
    phone_annotate:
      'To help our sales team respond more quickly, please kindly provide your local contact number. Thank you!',
    increased_risk: 'Warning! High-risk session',
    increased_risk_extended: 'The selected session type and body location have increased risk',
    approximate_price: 'Approximate Price',
    price_warning: 'Additional items are not included',
    type: {
      info: 'Convenient form for session booking with all necessary information',
      booking_button: 'BOOK A SESSION',
      booking_button_promo: 'WITH 15% CASHBACK IN VEAN COINS',
      unsure: "Still unsure about your choice? We'll help you!",
      consultant_help: 'Our consultants will gladly assist you',
      consultation_button: 'CONSULTATION',
      simplified:
        'Book your session quickly and conveniently! Choose the date, time, and service in just a few clicks',
    },
    color: {
      black: 'Black',
      colored: 'Colored',
    },
    controls: {
      next: 'NEXT',
      back: 'Back',
      no_select: 'Not selected',
    },
    place_service: {
      select_studio: 'Select studio',
      service: 'Service',
      size: 'Size',
      location: 'Location',
    },
    customer_info: {
      login_prompt: 'To continue, please log in to your account',
      login_button: 'Log in',
      black_white_colored: 'Black and White or colored?',
      select_style: 'Select style',
      tell_more: 'Tell us more about your tattoo preferences and share examples (references)',
      login_promo: 'Get 10% Cashback when you log in',
      wish: 'Wish',
    },
    date_time: {
      choose_time: 'Choose a visit time',
    },
    master_card: {
      master_unavailable: 'The artist is not available at this time',
      master_guest_warning: 'ATTENTION! The artist is visiting',
      rank: 'Rank:',
      works_count: 'Works count:',
      book_now: 'Book Now',
      select_another_time: 'Select another time',
    },
    success: {
      session: 'SESSION',
      become_mystery_shopper: 'Become a mystery shopper',
      add_to_calendar: 'Add event to calendar',
      call_on_day: '*an administrator from the salon will call you on the day of the session',
      return_to_menu: 'Return to menu',
      recommended_products: 'Recommended products for healing',
    },
    black_friday: '50% discount with mandatory deposit',
  },
  tips: {
    placeholder: 'Choose how to split the tips',
    divide_by_all: 'Equally',
    administrator: 'To Administrator',
    parlor: 'To Salon',
    master: 'To artist',
  },
  new_landing: {
    count_block: {
      countries: 'Countries',
      studios: 'Studios',
      awards: 'Awards',
      masters: 'Artists',
    },
    header_banner: {
      label_1: "We don't make lemonade from lemons",
      label_2: 'we make tattoos!',
      book_now: 'Book Now',
      consultation: 'Consultation',
      prices: 'Prices',
      top_works: 'Top Works',
      advantages: 'Advantages',
      change_parlor: 'Change parlor',
    },
    course_card: {
      theory_part: 'Theory part:',
      practice_part: 'Practice part:',
      advance: 'Additional advantages:',
    },
    helpful_links: {
      for_masters: 'For clients',
      tattoo_news: 'Tattoo News',
      tattoo_news_description: 'Stay updated with the latest from the tattoo world',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'OUR ARTISTS ARE READY TO SHARE THEIR KNOWLEDGE, SECRETS, TECHNIQUES',
      rent_space: 'Rent a Space',
      rent_space_description:
        'Find out about cooperation opportunities by renting a workspace in VEAN studios',
      tattoo_promotions: 'Promotions',
      tattoo_certificates: 'Gift cards',
      consumables: 'Consumables',
      equipment: 'Equipment',
    },
    price_block: {
      title: 'Price',
      loyalty_program: {
        title: 'Loyalty Program',
      },
      actions: {
        book_now: 'Book Now',
        calculate_cost: 'Calculate Cost',
      },
    },
    top_works: {
      title: 'Top Works',
      from_the_world: 'from around the World',
    },
    why_vean: {
      title: 'Why VEAN',
      individual_approach: 'Individual Approach',
      trust: 'Trust',
      safety_guarantee: 'Safety Guarantee',
      convenient_location: 'Convenient Location',
      visited_studio: 'People visited this studio',
    },
  },
  mystery_shopper: {
    title: 'Mystery Shopper',
    description:
      'A mystery shopper is someone who checks the operation of a store under the guise of an ordinary customer. They evaluate the level of service in the store.',
    offer:
      'If you decide to become a mystery shopper for this session, we will give you VeanCoins as a gift.',
    instructionTitle: 'Instructions',
    instructionDetails:
      'During the session, you will need to evaluate the store and the staff based on various criteria. Also, after the session, you will receive an SMS with a link to a questionnaire that you will need to fill out (only honestly!)',
    question: 'Do you want to become a mystery shopper?',
    yesButton: 'Yes',
    noButton: 'No, thank you',
    successMessage: 'You will need to evaluate the studio based on the following criteria:',
    criteria1: 'Phone and social media consultation',
    criteria2: 'Consultation in the studio upon arrival',
    criteria3: "Studio's pricing policy",
    criteria4: "Exterior: Studio's facade, signs, location, etc.",
    criteria5: 'Interior of the studio, ambiance',
    criteria6: 'Range of products, souvenirs, and healing (storefront)',
    criteria7: 'Atmosphere: TV, music, staff',
    criteria8: 'Availability of refreshments, entertainment, magazines, and sketch catalogs',
    criteria9: 'Communication with the artist',
    criteria10: 'Communication with the administrator',
  },
  cookies: {
    text: `We use cookies to enhance your browsing experience, 
      show personalized advertisements or content, 
      and analyze our traffic. By clicking "Accept," 
      you agree to the use of cookies.`,
    confirm: 'Accept',
    more: 'Learn More',
    manage: 'Manage',
    config: {
      necessary: 'Necessary',
      preferences: 'Preferences',
      statistics: 'Statistics',
      marketing: 'Marketing',
    },
  },
  header_menu: {
    logout: 'Exit',
    main: 'Home',
    about_us: 'About Us',
    discount_page: 'Discounts',
    loyalty_page: 'Loyalty Program',
    ai_page_link: 'Generate Sketch',
    fonts_page_link: 'Tattoo Fonts',
    quality_certificate: 'Quality Certificates',
    complaints: 'Complaints and Suggestions',
    confidence: 'Privacy Policy',
    public_offer_link: 'Public Offer',
    work: 'Job Opportunities at VEAN',
    dockuments_page_link: 'Documents',
    login: 'Login',
    account: 'Personal account',
    studying: 'Want to study!',
    buy_certificate: 'Buy a certificate',
    feedback: 'Feedback',
    loan: 'I want a credit!',
    services: 'Services',
    parlor_social_links: 'Parlor social links and contact information',
    global_social_links: 'Global social links and contact information',
  },
  post: {
    welcome: 'Hello. You had a session with us',
    no_answer_questions: 'You did not answer the following questions:',
    point: 'Please rate the quality of our service and earn VeanCoins!',
    send: 'Send',
    error: 'An error occurred!',
    not_found: 'Session not found!',
    not_call: 'No need to call me back',
    thank: 'Thank you very much for your feedback!',
    bot: 'In 21 days, a bot will call you from an unknown number to clarify information about the service provided and the healing process.',
    certificate:
      'A promotional certificate for %{gift_price} VeanCoins has been generated for you.',
    social_networks: 'Please leave a review about us on social networks:',
    month: 'Use it within a month, and don’t forget to remind the administrator about it!',
    dissatisfied:
      'Not satisfied with the service provided? We will process your request as soon as possible.',
    help_us_improve:
      'Help us improve by answering questions and get <span class="text-warning">{bonusAmount}</span> VEANCOINS or present!',
    scores: {
      variants: {
        great: 'Great',
        good: 'Good',
        satisfactorily: 'Satisfactory',
        bad: 'Bad',
        fast: 'Fast',
        orally: 'Orally',
        in_writing: 'In writing',
        orally_in_writing: 'Both orally and in writing',
        yes: 'Yes',
        no: 'No',
        didnt_listen: "Didn't hear",
      },
      administrator:
        'How do you rate the consultation of the studio administrator regarding services, artists, and care?',
      holl: 'How do you rate the comfort of waiting at the reception (environment, cleanliness, convenience)?',
      price: 'How do you rate the cost of services in the studio?',
      callback:
        'How quickly did you receive feedback from the studio during the appointment process?',
      master: 'How do you rate the studio artist?',
      recommendations: 'Were you provided with clear care recommendations?',
      care: 'Were you offered to purchase professional aftercare products or accessories?',
      master_contact: 'Did the artist provide you with their contact information?',
      buy_other: 'Did you purchase accessories or aftercare products?',
      need_contact: 'Do you need us to contact you?',
      loyalty_program: 'Are you already participating in the loyalty program?',
      sketch: 'Were you offered to order an individual sketch/project?',
      souvenir: 'Were you offered to purchase souvenir products or merchandise?',
      repeat_record: 'Would you like to schedule another session?',
    },
    scores_mystery_shopper: {
      instagram: 'Our INSTAGRAM evaluation',
      site: 'Website evaluation: vean-tattoo.%{locale}',
      phone_consultation: 'Phone and social media consultation',
      consultation: 'In-studio consultation upon arrival',
      price: 'Your opinion on the studio’s pricing policy',
      exterior: 'Exterior: Studio facade, signage, location, etc.',
      interior: 'Studio interior and atmosphere',
      assortment: 'Product assortment of souvenirs and healing products (showcase)',
      atmosphere: 'Atmosphere: TV, music, team spirit',
      leisure: 'Availability of refreshments, leisure activities, magazines, and sketch catalogs',
      master: 'Interaction with the artist',
      admin: 'Interaction with the administrator',
      master_one_place: 'Does the artist work only in this studio? (yes/no)',
      master_contact:
        'Requested personal contact information of the artist, was it provided? (yes/no)',
      care: 'Were you offered healing products and souvenirs? Did you receive a small gift: a magnet, sticker, or a 10% discount certificate for the next service? (yes/no)',
      conclusion: 'Final evaluation of the check',
    },
    inputs: {
      cost: 'Enter the amount you paid for the service',
      cost_other: 'What amount did you spend on accessories or aftercare products?',
      review: 'Do you have suggestions for improving the studio?',
      photo: 'Photo of the salon',
    },
    inputs_mystery_shopper: {
      recommendation: 'Your recommendations for us',
      why_vean: 'Why did you choose VEAN?',
    },
  },
  header: {
    online_appointment: 'Book an appointment',
    post_session: 'Quality control',
    get_price: 'Consultation',
    consultation: '(Consultation)',
    order_consultation: 'Order a consultation',
  },
  index: {
    parlor: 'Studio',
    master: 'Artist',
    session_type: 'Service type',
    session_location: 'Service location',
    datetime: 'Date and time',
    create_appointment: 'Create appointment',
    bonus: '* %{customer_bonus} VeanCoins Discount for booking by yourself',
    sketch: 'tattoo sketch (not necessary)',
  },
  session_types: {
    tattoo: 'Tattoo',
    tatouage: 'Permanent makeup',
    piercing: 'Piercing',
    stroke: 'Removal',
    training: 'Training',
    training_tattoo: 'Tattoo training',
    training_piercing: 'Piercing training',
    training_tattouage: 'PMU training',
    training_udder: 'Removal training',
  },
  price_form: {
    client_name: 'Name',
    client_phone: 'Phone',
    client_comm: 'Your service',
    sketch_desc: 'Add a sketch ( * Optional )',
    sketch_button: 'Select file',
    send: 'Send',
    or: 'Or',
    country: 'Country',
    city: 'City',
    comment: 'Comment',
    val_error: 'Check the entered data!',
    ths_message: 'Thanks! You will be contacted shortly',
    go_back: 'To return',
    file_size: 'The file size should be no more than 5 MB!',
  },
  selectors: {
    parlor: {
      placeholder: 'Search by studio name...',
    },
    master: {
      placeholder: 'Search by name or service type…',
    },
    select_type: {
      select: 'Select',
      next: 'Next',
      no_price: 'Agreed',
      ad: 'From',
      da: 'To',
      selected: 'selected',
      info: 'Price may depend on size and location',
    },
    datetime: {
      select_date: 'Select date',
      no_active_times: 'There are no places left on the selected date. Please choose another!',
    },
  },
  verify: {
    verify_account: 'Verify account',
    type: 'Verification method',
    check_code: 'Check code',
    status: 'Status',
    input_phone: 'Enter your phone number',
    email_help: 'You can also use email for verification',
    sms_sent: 'You have been sent an 4-digit SMS code, enter it below',
    resend_sms_after: 'Resend SMS is available in %{seconds} seconds',
    resend_sms: 'Resend SMS',
    buttons: {
      back: 'Back',
      next: 'Next',
      create_appointment: 'Create appointment',
    },
  },
  success: {
    h1: 'Thanks! Appointment created successfully!',
    h2: 'After the administrator approves the entry, an SMS will be sent to you.',
    mystery: 'Become a Secret Buyer',
  },
  about: {
    header: 'About you',
    birth_info:
      'By indicating the date of birth, we make sure that you have a valid age and also it is needed for birthday discount :)',
    inputs: {
      name: 'First and last name',
      find_out: 'How did you find out',
      birth_date: 'Date of Birth',
    },
    buttons: {
      skip: 'Skip',
      back: 'Back',
      next: 'Next',
    },
  },
  footer: {
    header: 'Subscribe to our social networks to be aware of all the news',
    site_navigation: 'Navigation',
    interest: 'Interesting',
    other: 'Other',
  },
  subscribe_modal: {
    header: 'Subscribe to news for a bonus',
    submit: 'Submit',
    wrong_data: 'Check the correctness of the entered data!',
    wrong_email: 'Incorrect email entered!',
    thanks: 'Thank you for subscribing!',
    error: 'An error occurred!',
  },
  chat_bot: {
    call: 'Call',
    online_record: 'Online Record',
    online_consultation: 'Online Consultation',
    need_help: 'Need help with a sketch?',
    order_sketch: 'Order Sketch',
    dress_sketch: 'Try on Sketch',
  },
  check_in_view: {
    important: 'Important',
    log_in_state_greet: 'Log in to your account to track bonuses and discounts.',
    log_in: 'Log in',
    download_app_greet: 'Download our app and get a bonus',
    download: 'Download',
    welcome: 'Welcome',
    studio_info: 'Studio information',
    recommended_products: 'Recommended products',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problem situations',
      id: 'ID',
      status: 'Status',
    },
    reset_password: {
      title: 'Forgot Password',
      submit: 'send',
      forgot_password: 'Forgot Password',
      phone: 'Enter phone number',
      success_message: 'A temporary password has been sent to your email/phone',
    },
    registration: {
      name: 'Name',
      surname: 'Surname',
      registered: 'Registration',
      congratulations: 'Congratulations!',
      successfully_registered: 'You have successfully registered!',
      log_in: 'Log In',
    },
    options: {
      no_interest_in_promotions: "I'm not interested in promotions and bonuses",
      dislike_frequent_sms: "I don't like frequent SMS messages",
      already_have_tattoo: "I already have a tattoo and don't plan to get another one soon",
      other: 'Other',
    },
    login_page: {
      log_in_page: 'Log in to your personal account',
      number_missing: "Don't have a number?",
      register: 'Register!',
      steps: {
        sign_up: 'Sign Up For The Loyalty Program Or LOGIN',
        check_coins: 'Check Your VeanCoin By Registering For The Loyalty Program',
        get_coins: 'Get your VeanCoins at the end of each session',
        accumulate: 'Collect & Accumulate VeanCoin In Your Profile',
        book_session: 'Book a Session & Come To Vean To Get Your Tattoo Done',
        exchange_coins: 'Exchange VeanCoins & Get Discounts',
      },
    },
    find_customer: {
      continue: 'Continue',
      not_found: 'Customer not found!',
      select_type: 'Select login type',
      no_password: 'You have not set a password!',
      found: 'Found!',
      check: 'Checking...',
      invalid_phone_or_password: 'Invalid phone number or password',
      error_login: 'Error during login',
      phone: 'Phone',
      temporary_password_message: 'You use a temporary password',
      permanent_password_message: 'You use a permanent password',
    },
    product_card: {
      availability: 'Studio availability:',
      unavailable: 'Not available',
    },
    store_body: {
      full_points_products: 'Products that can be bought with VeanCoins',
      half_points_products: 'Products that can be bought with a 50% discount',
      no_products: 'There are no goods',
    },
    description_block: {
      loyalty_system: 'VEAN loyalty system',
      show_more: 'Show more',
    },
    profile_card: {
      my_sketches: 'My sketches',
      share_link: 'Share this link',
      tips: 'Tips',
      send_certificate: 'Send certificate',
      vip: 'VIP',
      not_vip: 'Become VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Money',
      active_certificates: 'Activated VeanCoins',
      balance: 'Balance',
      sessions: 'Sessions',
      points_alltime: 'VeanCoins earned all-time',
      available_certificates: 'Available certificates',
      certificates: 'Money certificates',
      bought_certificates: 'Activated VeanCoins',
      certificate_number: 'Certificate number',
      available_for: 'Valid until:',
      verify_account_status: 'Account status',
      security_percent: 'Security:',
      no_active_certificates: 'There are no money certificates',
      no_active_bought_certificates: 'No active VeanCoins',
      coming_sessions: 'Upcoming sessions',
      complete_agreement: 'Complete the agreement',
      date: 'Date:',
      you_was: 'You were at %{sessions} sessions',
      edit: 'Edit',
      not_email: 'To send the certificate, you need to fill out an email',
      exist_application: 'there is an application',
      need_exist_application: 'there is no application',
      need_confirm_account: 'Need to confirm account',
      verified: 'Verified',
      choose_payment_method: 'Choose payment method',
      parlor: 'Parlor',
      sign_up_online: 'Sign up for a session',
      mystery_shopper: 'Mystery shopper',
      sign_up_secret_buyer: 'Sign up with us for a session and become a mystery shopper',
      pay: 'Pay',
      prepayment: 'Prepayment',
      questionnaire: 'Questionnaire',
      agreement: 'Agreement',
      fill_required_information: 'It is necessary to fill in the information',
      buy: 'Top up your balance',
      archive: 'Archive',
      support_service: 'Customer Support',
      balance_buy_history: 'History of purchased VeanCoins',
      balance_history: 'History of earned VeanCoins',
      buy_history: 'Buy History',
      сost: 'Cost',
      payment_link: 'Payment Link',
      attributes: 'Attributes',
      invite_friend: 'Invite a friend',
      phone_or_email: 'Enter a phone number or email',
      report_problem: 'Report a problem',
      note: 'Write a comment',
      tooltip_coins:
        'you need to activate your accumulated VeanCoins for the service you choose or for purchasing goods to avoid burning them within 1 year',
      tooltip_certificate:
        'the number of your purchased or gifted money certificates, which can be used within 2 months',
      tooltip_сonfirmed: 'In editing, change «ФИО или username» to «First and Last Name»',
      tooltip_missing:
        'Add information about yourself (first and last name, date of birth, link to your social networks, where you heard about us, phone number and email for identity confirmation)',
      tooltip_verified:
        'You need to confirm your account with a phone number or email to protect your earned and accumulated VeanCoins.',
      tooltip_vip:
        'VIP client is a status that can be obtained by any client who spends more than 1500 euros on services. VIP status provides certain benefits to its owner, such as: fast priority booking for sessions out of turn, access to unique goods and services, upon reaching VIP status, the client immediately receives 1000 VeanCoins credited to their account',
      earn_veancoins: 'Earn veancoins',
    },
    profile_edit: {
      additional_information: 'Additional information about VeanCoins',
      parlors: 'Salon (which is closer or the main one)',
      client_card: 'Client Card',
      full_name: 'Full Name or Username',
      birth_date: 'Date of Birth (for congratulations)',
      social_media_link: 'Social Media Link',
      find_out: 'How did you find out',
      or: 'or',
      phone_placeholder: 'Phone',
      save: 'Save',
      password_tooltip:
        'Create a password to access your profile in the future without the need for SMS or email. This also protects your personal data.',
      password: 'Password',
      repeat_password: 'Repeat Password',
      email_taken: 'This email is already taken!',
      phone_taken: 'This phone number is already taken!',
      simple_password: 'Too simple password!',
      invalid_password: 'Invalid password!',
      password_for_application: 'Password for the application (optional)',
      congratulate: 'Congratulations!',
      successfully_registered: 'Successfully Registered!',
      need_confirm_account: 'Need to Confirm Account',
      yes: 'Yes',
      no: 'No',
      phone_confirmed: 'Phone confirmed',
      email_confirmed: 'Email confirmed',
      change_password: 'Change password',
      old_password: 'Old password',
      new_password: 'New password',
      confirm_password: 'Repeat the password',
      invalid_phone_or_password: 'Invalid phone number or password',
      error_login: 'Error during login',
      years_old: 'Age',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Gender',
      man: 'Man',
      woman: 'Woman',
      gender_hidden: 'Prefer not to say',
      language: 'Language',
      preferred_style_status: 'Preferred styles',
      classic: 'Classic / Realism',
      freak: 'Freak / Trash / Anime',
      fashion: 'Fashion / Art / Minimalism',
      rock: 'Rock / Hard',
      pop: 'Pop / Mass',
      accommodation_status: 'Accommodation status',
      local: 'Local',
      tourist: 'Tourist',
      guest: 'Guest',
      lifestyle_status: 'Lifestyle',
      extreme: 'Extreme',
      dance: 'Dance',
      anime: 'Anime',
      game: 'Game',
      music: 'Music',
      lgbt: 'LGBT',
      art: 'Art / Modernity',
      family: 'Family / Love',
      beauty: 'Beauty',
      sexuality: 'Sexuality',
      masculinity: 'Masculinity',
      tattoo_count_status: 'Number of tattoos',
      pierce_count_status: 'Number of piercings',
      one: 'One',
      two: 'Two or more',
      inc: 'Very many',
      change_information: 'Save information',
    },
    verification: {
      sms: 'Confirm by SMS',
      email: 'Confirm by Email',
      google: 'Verify by Google',
      send_code: 'Send Code',
      code: 'Verification code',
    },
    loyalty: {
      submit_button: 'Submit',
      bonus_instruction:
        'Complete one of the following tasks, provide evidence (screenshots, photos, etc.), and receive a bonus of',
      bonus_amount: 'VeanCoins',
      review_option_1: "Leave a detailed review on the artist's profile on the website",
      review_option_2: 'Rate the studio or artist on the website',
      review_option_3: 'Rate the studio and leave a review on the map',
      review_option_4: 'Share our studio on social media',
      return:
        "If the photo you sent doesn't belong to any of the categories for which we grant bonuses, the received bonuses will be canceled when purchasing a gift certificate.",
      evidence_label: 'Evidence',
    },
    buy_certificate: {
      payment_type_title: 'Type of Purchase',
      payment_type_subtitle: 'What Would You Like to Do',
      amount_title: 'Amount',
      amount_subtitle: 'Enter the Amount',
      payment_method_title: 'Payment Method',
      payment_method_subtitle: 'Choose a Payment Method',
      payment_title: 'Payment',
      forming_payment: 'Forming Payment...',
      pay: 'Pay',
      continue: 'Continue',
      back: 'Back',
      buy_balance: 'Top up balance',
      buy_certificate: 'Buy certificate',
      submit_to_email: 'Send certificate to Email',
      total_balance: 'Balance',
      certificate: 'Certificate',
      customer_balance: 'Customer balance',
      get_vean_card: 'Get VEAN card in the studio',
      vean_card_description:
        'VEAN CARD is your card where VEAN TATTOO certificates are automatically activated, ensuring safety and convenience. All your gifts and discounts are always at hand, and using the card is as simple and beneficial as possible. Get your card at VEAN TATTOO studios and discover all the advantages today!',
      certificate_type: 'Certificate type',
      promotion_certificate: 'Promotion Certificate',
      buy_for_myself: 'Buy for myself',
      promotion_certificate_message:
        "Physical certificates will be issued until December 31, 2024. Starting January 1, 2025, certificates will be converted into points and displayed in the user's personal account.",
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'More than 160 parlors',
      clients_count: 'Over 1,000,000 clients',
      largest_company: 'The largest tattoo studio network in the world',
    },
    master_block: {
      our_masters: 'Our artists',
    },
    master_card: {
      works_count: 'Number of works: ',
    },
    no_admin_block: {
      leisure: 'Leisure',
      session_record: 'Write to the session',
      about_company: 'About the company',
      care: 'Aftercare',
      quality_certificates: 'Certificates of quality',
      public_buyout_offer: 'Public offer',
      complaints_offers: 'Complaints and suggestions',
      discount_page: 'Discounts',
      loyalty_system: 'Loyalty system',
    },
    peculiarities_block: {
      about_studio: 'About the studio',
      clients: 'Clients',
      sessions: 'Sessions',
      studio_hours: 'Working hours: ',
      studio_phone: 'Studio number: ',
      wifi: 'Wi-fi: ',
      password: 'Password: ',
      from_to: 'from %{time_start} to %{time_end}',
    },
    photos_block: {
      photos: 'Salon photo',
    },
    top_works: 'Works',
    care_important: 'The most important thing in piercing and tattooing is healing and aftercare.',
    cabinet: 'Personal cabinet',
    complete_agreemnent: 'Complete agreement',
    is_blanket: 'There is a blanket to keep warm',
    is_water: 'Hot drinks are available',
    is_hot_drinks: 'There are sweets',
    is_treats: 'Cold drinks are available',
  },
  promotion: {
    submit: 'Submit',
    warning: 'Warning! Check the correctness of the entered data!',
    name: 'Name / Nickname',
    required: 'This field is required!',
    max_value: 'Should not be more than 100 characters!',
    phone: 'Phone',
    social_link: 'Social media link',
    wrong_format: 'Wrong format!',
    wrong_format_password: 'Password must be contain letters and numbers!',
    password_confirmation: 'Password confirmation does not match!',
    terms: 'I have read and agree to the privacy policy',
    know_bonus: 'I am aware that I will receive bonus points',
    error: 'An error occurred',
    thanks: 'Thank you!',
    bonus: 'You have been awarded %{gift_price} points',
    do_not_disturb: 'Do not disturb (disable SMS and calls from VeanTattoo)',
    get_promo: 'I want to receive information about discounts and promotions',
    first_time_veancoins_notification:
      'To use VeanCoins for the first time, it is necessary to contact the studio administrator. VeanCoins and Money have different rules of use',
  },
  another_studio_drawler: {
    another: 'Other studios',
  },
  landing: {
    best_studio: 'Tattoo studio in your city',
    vean_wide: 'VEAN TATTOO NETWORK',
    studio_count: 'More than 160 tattoo studios',
    quality: 'Quality and safety guarantee',
    get_price: 'TO KNOW THE PRICE',
    contact_us: 'Contact us',
    advantage: 'Advantages',
    top_works: 'Top works',
    prices: 'Prices', //долджен быиь
    studio_photo: 'Photos of the Studio',
    prices_services: 'Prices and services',
    about: 'About us',
    loyalty_system: 'Loyalty program', //должна быть
    why_vean: 'Why VEAN',
    visit_count: 'People have visited this studio',
    description:
      'The VeAn Tattoo Studio network is the largest in the world today: we have more than 160 tattoo studios in 14 European countries. We have brought this niche to a whole new, higher standard. The specialization of the network is quite wide, but the main direction is tattoo and piercing industry. Key principles of VeAn Tattoo: Expertise and Accessibility: We strive to make every customer feel comfortable at every step of the process, so our specialists are ready to answer all your questions online. Reputation and focus on customers: Each customer is an opportunity to create a unique work of art on the skin. We appreciate your time and look forward to leaving our studio with an improved mood and an incredible tattoo. We care about the reputation and value every customer of the network. Value and availability: We offer the perfect service for money. Our services are accessible to a wide audience and we do not sacrifice quality. Certified and quality equipment: Our materials meet the highest standards of safety and quality. We guarantee that our customers get the best. Highly qualified professionals and a wide range of services: The VeAn Tattoo studios are staffed exclusively by certified artists, who are valued and respected worldwide. We offer all existing tattoo styles and techniques, development of free individual sketch according to your requirements. We also invite you to subscribe to all our official social media pages. This will allow you to be aware of special offers, promotions, contests, artist works, latest news and updates of VeAn Tattoo.',
    advantages: {},
    our_masters: 'Our artists', //doljen bit
    from: 'From',
    no_price: 'Agreed',
    form: {
      get_price_consultation: 'Find out the price / Consult',
      name: 'Name',
      phone: 'Phone',
      place: 'Your place',
      subscribe_news: 'Subscribe to the news for bonus',
    },
  },
  form_messages: {
    submit: 'Send',
    warning: 'Warning! Check correctness of entered data!',
    required: 'This is a mandatory field!',
    length: 'Not more than %{length} of letters!',
    file_length: 'Not more than %{length} files!',
    wrong_format: 'Wrong format!',
    error: 'There was an error',
    thanks: 'Thanks!',
    required_label: 'Required',
    not_required_label: 'Optional',
  },
  training: {
    training: 'TRAINING',
    tattoo_school: 'TATTOO ACADEMY',
    biggest_school: 'the largest network of tattoo studios in the world',
    ne_otkladivaj: "Don't<br/>put off<br/>yourself for later",
    learn: 'Learn tattooing<br/>and become an amazing artist',
    reviews: "Graduates' reviews",
    record: 'Book your course now',
    video_text: 'What should I expect?<br/>what will you teach?',
    ded: {
      consultation: 'consultation',
      get_more: 'Find out more',
    },

    header_bar: {
      main: 'Home',
      get: 'What you will get',
      program: 'Course program',
      about: 'About us',
      reviews: 'Reviews',
    },

    our_course: {
      head: 'Our<br/>courses',
      description_1:
        'this is the place where you always get answers to your questions and learn something new',
      description_2:
        "And even if you don't have an artistic background - you won't have to wait for the results",
      label: "[ it's homelike, friendly and exciting with us ]",
      description_3:
        'We always treat our students as our friends, and try to do everything to make the learning process as comfortable as possible',
    },

    banner: {
      we_teach: 'We will teach you how to beat tatoos',
      good_make: 'WE TATTOO COOL',
      our_masters: 'OUR ARTISTS ARE READY TO SHARE THEIR KNOWLEDGE, SECRETS, TECHNIQUES',
      learn_too: 'And we will teach you too',
    },
    modal: {
      thanks: 'Thank you!',
      error: 'An error occurred!',
      complete: 'Fill out the form and we will call you',
      full_name: 'First and last name',
      phone: 'Phone number',
      submit: 'Submit',
      cancel: 'Cancel',
    },

    paragraph: {
      header_1: 'We will teach you for sure',
      content_1: "We don't mass produce our courses",
      header_2: "What if you don't know how to draw?",
      content_2: "We will teach you everything. There's no need to have any artistic background",
      header_3: 'Certificate',
      content_3:
        'Upon completion of the courses, you receive an international standard certificate. Approved by Global Tattoo ACC',
      header_4: 'Employment',
      content_4:
        'OMG, are you serious right now? Yes, our best graduates can become a part of our team and work anywhere in Europe',
      header_5: 'Contract',
      content_5: 'We do not operate on "word of honor". Everything is contractual and official',
      no_sleep: {
        header: 'do not oversleep',
        rect_1: 'How?',
        rect_2: 'LIFE',
        main_label: 'Start learning right now',
        label_1: '1000+ graduates',
        label_2: '100% completed the training',
        label_3: '80% employed',
        label_4: '300+ partners in the tattoo industry',
      },
    },
    principies: {
      head: 'Guarantees',
      label_1: '1. Culture',
      label_2: '2. Quality',
      label_3: '3. Methods',
      heading: 'Everything is built on principles',
      description_1:
        '11 years ago, we decided to start developing the culture of the tattoo industry, not like everyone else, but in our own way - based on knowledge and experience',
      description_2:
        'Today, we have taught over 1000 excellent tattoo artists, 80% of them have become a part of our team. We can confidently say that anyone can learn the art of tattooing',
      description_3:
        "It's all about the methodology we use, constantly improving along with the team, artists, and our graduates, who regularly provide feedback",
    },
    packages: {
      head: 'Course Programs',
      from: 'from',
      plus: 'Pros',
    },
    facilities: {
      head: 'Possibilities and Benefits',
      subhead: 'Choose our courses and experience the magic of the tattoo industry',
      card: {
        head_1: 'Each of our graduates receives an international standard certificate',
        label_1: '- your ticket to a world of opportunities',
        head_2: 'Guidance and support from a group of professional artists',
        label_2: 'Your success is our pride!',
        head_3: 'Tattoo Community, Get Advice and Support from Our Experts',
        label_3: 'Your New Life in the Tattoo World Begins Now!',
      },
    },
    review: {
      head_1: 'Our Cases',
      head_2: 'Reviews',
      comment_1:
        "The tattoo training at Vean exceeded all my expectations! The teachers are professionals in their field, each stage of the training is thoroughly explained, and the atmosphere is great. Now I can confidently bring my creative ideas to life on my clients' skin!",
      comment_2:
        'I want to thank the academy for such quality tattoo training! It was a bit scary to take on this venture, but thanks to attentive and patient teachers, I felt confident. Now my works receive recognition and admiration',
      comment_3:
        'It was an incredible journey into the world of tattooing! The school provides all the necessary knowledge and skills to become a true professional. I am grateful for the support and inspiration I received here',
    },
  },
  questionary: {
    first: {
      heading: 'CONSENT FOR PROCEDURE AT VEAN TATTOO STUDIO',
      textbox:
        'If the client is under 18 years old, the consent must be filled out by parents or legal guardians (In British Columbia, Canada, a minor is considered to be under 19 years old. If you are under 19, please choose the SECOND option)',
      has_18: 'YES, I am 18 or older',
      no_18: 'NO, I am under 18',
    },
    second: {
      warning: 'Attention! On the day of the session, it is necessary to come with guardians!',
      name: 'Full Name',
      birthdate: 'Date of Birth',
      address: 'Residential Address',
      email: 'Email',
      sex: 'Gender',
      heading: 'PROCEDURE TO BE PERFORMED',
      man: 'Male',
      woman: 'Female',
      other: 'Not specified',
      parent_data: 'Data of one of the parents:',
      child_data: 'Data of one MINOR:',
      child_field_placeholder: 'Son, daughter, etc.',
      child_field_label: 'For whom is the procedure carried out',
      confirm:
        'I, being the legal representative of a minor, give my consent to carry out the following procedure to my child.',
    },
    third: {
      heading: 'EVERYTHING YOU NEED TO KNOW ABOUT HEALING PERIOD:',
    },
    fourth: {
      nothing: 'None of the above',
      has_diseases: 'Do you have any diseases?',
      has_contraindications: 'Do you have any contraindications?',
      heading1:
        'IF YOU HAVE ANY OF THE FOLLOWING ILLNESSES, WE NEED TO KNOW, PLEASE MARK THE RELEVANT POINTS:',
      heading2: 'IF THERE ARE CONTRAINDICATIONS, PLEASE PROVIDE THE RELEVANT INFORMATION:',
    },
    fifth: {
      heading: 'PROHIBITED AFTER THE PROCEDURE:',
    },
    sixth: {
      heading: 'WHERE DID YOU HEAR ABOUT US:',
      label:
        'After 21 days, one of our employees may contact you as part of a quality assurance check for the services provided. Additionally, after the session, you will receive SMS notifications, and it is possible that our automated robot will make a call. In your personal account, you can opt-out of notification services via calls and SMS, and you can also delete all your data and photos after 21 days from the date of service.',
    },
    seventh: {
      heading: 'STATEMENT AND CONSENT',
    },
    signature: {
      heading:
        "I, the undersigned (%{name}), hereby consent to today's procedure with my participation\n" +
        '%{session_type} on my body and agree to the conditions contained in this document.',
    },
    next: 'Next',
    submit: 'Submit',
    no_responsibility: 'The studio is not responsible for this session',
    acquaint: 'I have read the information provided to me',
    acquaint_2:
      'I understand that Vean Tattoo is not responsible for the healing process.\n' +
      'In the case of correction of the tattoo or piercing mentioned above, the cost of correction will be 30% of the cost of the service itself.\n' +
      'If you are not satisfied with the result of the correction, the funds for the service are not refundable in this case, as the aforementioned areas are not favorable for the service itself.',
  },
  choices: {
    problem_situation: {
      no_solution: 'No solution',
      in_check: 'In check',
      problem_solved: 'Problem solved',
      wait: 'Waiting',
      small: 'Minor situation',
      unanswered_check: 'Unanswered check',
    },
  },
  master_page: {
    card: {
      active: 'Active',
      not_active: 'Not Active',
      master_since: 'Artist VEAN since',
      services: 'Services',
      styles: 'Styles',
      book: 'Book',
    },
    information: { top_works: 'Top works', awards_and_diplomas: 'Awards and diplomas' },
  },
  stripe_warning:
    'Attention! The minimum payment amount using stripe is €20. If a smaller amount is required, please contact the studio for details',
  check_in: {
    rate: {
      upload_photo: 'Upload a photo of your work',
      upload_photo_desc:
        'You can take a beautiful photo of your work for our social media and website. The administrator also takes a picture for quality control and to guarantee the work performed. You can always opt out of publishing and sharing the photo, and your data will be completely deleted after healing.',
      bad_comment: 'Please explain why you gave a low rating',
      review: 'Leave a review!',
      review_desc:
        'We would be very grateful if you leave a review. Your opinion is important to us!',
      cashback: 'x2 cashback to your account in the app',
      send_invoice: 'Send invoice to the email',
      influencer: 'Do you have 2000+ subscribers on social media?',
    },
    welcome_message: 'HAVE A GREAT SESSION!',
    session_started: 'YOUR SESSION HAS STARTED',
    next_steps: 'After completing the session, other steps will become available to you',
    buy_healing_product: 'WE RECOMMEND PURCHASING A HEALING PRODUCT',
    skip: 'Skip',
    leave_review: 'Please leave a review',
    your_impression: 'What are your overall impressions?',
    add_photo: 'Add a photo of the work to get an additional 1 Vean Coin',
    send: 'Send',
    payment_success: 'Payment was successful',
    get_invoice: 'Would you like to get an invoice?',
    invite_friend: 'Invite a friend',
    recommend_us: 'Recommend us to your friends and earn together',
    thank_employees: 'We also suggest thanking our employees',
    you_can_tip: 'Dear Nikita, you can also thank our employees with a certain amount of tips',
    own_amount: 'Own amount',
    who_to_tip: 'Who should be tipped?',
    payment_type: 'Payment type',
    continue: 'Continue',
    summary: 'Summary',
    healing_agent: 'Healing agent',
    tips: 'Tips',
    session_payment: 'Session payment',
    total: 'Total',
    waiting: 'Waiting for administrator/artist make the payment ...',
    available_funds: 'Available funds',
    refill_balance: 'Refill balance',
    go_to_payment: 'Go to payment',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Tips',
    prepayment: 'Prepayment',
    have_questions: 'Have questions?',
    title: 'Sessions',
    selectStatus: 'Select a status',
    selectParlor: 'Select a parlor',
    loading: 'Loading...',
    fromDate: 'From date',
    toDate: 'To date',
    search: 'Search',
    all: 'All',
    status: {
      100: 'Pending',
      200: 'New',
      300: 'In progress',
      400: 'Completed',
      500: 'Canceled',
      600: 'Awaiting payment',
    },
    details: 'Details of the session',
  },
  master: {
    rank: 'Rank',
    works_count: 'Number of works',
    address: 'Address',
  },
  modalPhone: {
    title: 'Attention!',
    info: 'Dear customer, for your safety and effective use of our service, adding all data is mandatory. This will help us respond promptly to important issues and ensure your protection.',
    reminder: 'Remind me later',
    go_to_settings: 'Go to settings',
    roughly_price: 'The approximate cost depends on the accuracy of the data you have provided',
    success_message: 'Thank you!',
  },
  studyingRegistration: {
    become_student: 'Become a student',
    step_1: 'General data',
    step_2: 'Personal information',
    step_3: 'Study details',
    city: 'City',
    city_placeholder: 'Enter your city',
    name: 'First Name',
    name_placeholder: 'Enter your first name',
    last_name: 'Last Name',
    last_name_placeholder: 'Enter your last name',
    date_of_birth: 'Date of Birth',
    date_of_birth_placeholder: 'Enter your date of birth',
    phone: 'Phone Number',
    email: 'Email (optional)',
    email_placeholder: 'Enter your email',
    social_media: 'Social Media',
    social_media_placeholder: 'Link to your profile',
    referral_source: 'How did you hear about us?',
    referral_source_placeholder: 'Choose a source',
    study_type: 'Study Type',
    study_type_placeholder: 'Choose study type',
    start_date: 'Estimated Start Date',
    start_date_help: 'Enter your estimated start date',
    hours_number: 'Number of hours',
    practice_number: 'Number of practices',
    details: 'Details',
    education_price: 'Course price',
    moto: 'A teaching method tested over decades',
    description: 'Description',
    material: 'Material',
  },
  studying_main: {
    become_student: 'Become a student!',
    consultation: 'Consultation',
    want_to_study: 'I want to study!',
    consult_with_our_advisor: "Don't know where to start? Contact our advisor!",
  },
  errorTicket: {
    error_description: "Report the app issue, and we'll fix it quickly",
    error_button: 'Report the malfunctioning of the app',
    problem_situation_description: 'Did a problem occur after the session? Let us know',
    problem_session_button: 'Report the issue after the session',
    problem_session: 'Report a problem after the session',
    sidebar_title: 'Report issue',
    main_title: 'Creating an Error Report',
    basic_info:
      'When creating a ticket, it is important to provide all necessary information for the quick and effective processing of your request. Here is a sample structure of the report that may help you:',
    ticket_title: 'Ticket Title:',
    short_description: "Brief description of the problem (e.g., 'Issues with session creation')",
    first_title: 'Problem Description',
    detailed_description: 'Detailed description:',
    problem_description:
      'Describe the essence of the problem, what exactly is happening, and what the malfunction consists of.',
    reproduction_steps: 'Steps to reproduce the problem:',
    reproduction_steps_description: 'List the steps that lead to the problem.',
    expected_result: 'Expected result:',
    expected_result_description: 'What did you expect to receive or see?',
    actual_result: 'Actual result:',
    actual_result_description: 'What happened instead of the expected result?',
    important_data: 'Important data',
    links: 'Links:',
    links_description: 'Provide links to problematic pages or documents.',
    photos_and_screenshots: 'Photos and screenshots:',
    photos_and_screenshots_description: 'Attach images that can help visualize the problem.',
    session_numbers: 'Session numbers:',
    session_numbers_description: 'Provide the session numbers during which the problem occurred.',
    additional_info: 'Additional information:',
    additional_info_description: 'Include any additional information that may be useful.',
    priority_and_urgency: 'Priority and urgency:',
    priority_and_urgency_description:
      'If the problem significantly affects your work, mention it here, requesting high priority.',
    issue_title: 'Title',
    issue_title_placeholder: 'Enter a brief description of the problem',
    description: 'Description',
    description_placeholder: 'Describe the problem in more detail',
    files: 'Files',
    drag_and_drop: 'Drag and drop the file here or select',
    uploaded_files: 'Uploaded files',
    create_button: 'Create',
    problem_type: 'Problem type',
    select_problem_type: 'Choose a problem type',
    technical: 'Tehcnical',
    non_technical: 'Non-technical',
    loading: 'Loading...',
    main_page: 'Go to the main page',
    checkbox: 'You need to confirm your agreement with all points.',
  },
  multistep: {
    basic_info: 'Basic Data',
    type: 'Type',
    summary: 'Summary',
    details_rent: 'Details',
    confirmations: 'Confirmations',
    open_google: 'Open in Google Maps',
  },
  toast: {
    certificate_success: 'Your certificate has been sent to your email',
  },
  tattoo_removal: {
    title: 'LASER / LIGHTENING',
    registration_description: 'Choose a convenient date and time for booking in just a few clicks',
    registration_button: 'Quick registration for a lightening session',
    consultation_description: 'Want to clarify details? We’re happy to help!',
    consultation_button: 'Consultation',
    header_nav_title: 'Tattoo Lightening',
    choose_service: 'Service Type',
    choose_service_placeholder: 'Select a service type',
    paste_tattoo: 'Attach a photo of removal / lightening (optional)',
    choose_size: 'Size',
    choose_size_placeholder: 'Choose size',
  },
  success_form: {
    go_personal_acc: 'Go to your personal account',
    dont_have_acc: "Don't have an account?",
    sign_up: 'Sign up!',
  },
  loyalty_card: {
    client_card: 'Client Card',
    card_number: 'Card number',
    card_photo: 'Card photo',
  },
  discount: {
    first_session: 'discount for the first session',
    second_session: 'discount for the second session',
  },
  days: {
    MON: 'MON',
    TUE: 'TUE',
    WED: 'WED',
    THU: 'THU',
    FRI: 'FRI',
    SAT: 'SAT',
    SUN: 'SUN',
  },
  calculate_price: {
    label: 'To know the price',
    color_options: {
      black_and_white: 'Black & White',
      colored: 'Colored',
    },
    additional: 'Additional',
    select_location: 'Select location',
    select_style: 'Select style',
    select_size: 'Select size',
    select_colored: 'Color',
    select_parlor: 'Select parlor',
    select_service: 'Select service',
    cost: 'Cost',
    from: 'From',
    to: 'To',
    book: 'Book an appointment',
    no_info: 'No info',
  },
};
