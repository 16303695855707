export default {
  veancoins: {
    summ: 'Suma de bonificaciones',
    customer_bonus: 'Bono del cliente',
    vip_gift: 'Regalo VIP',
    vip_price: 'Monto mínimo VIP',
    cash_back_sells: 'Reembolso por ventas',
    cash_back_certificate: 'Reembolso por certificado',
    cash_back_student: 'Reembolso por formación',
    cash_back_service: 'Reembolso por servicio',
    cash_back_money: 'Reembolso por recarga de saldo',
  },
  support: {
    problem_situation_link: 'Tengo un problema después de la sesión',
    other_problem_link: 'Tengo otro problema',
    problem_situation: {
      header: 'Problema después de la sesión',
      form: {
        session: 'Sesión',
        created_by_comment: 'Describa el problema',
      },
    },
    complain: {
      header: 'Quejas y sugerencias',
      form: {
        name: 'Nombre',
        phone_number: 'Número de teléfono',
        email: 'E-Mail',
        parlor: 'Salón',
        description: 'Describa el problema',
        feedback: {
          overall_quality: '¿Cómo califica la calidad general del servicio en nuestro salón?',
          professional_skills: '¿Quedó satisfecho con las habilidades profesionales del maestro?',
          staff_politeness: '¿Qué tan cortés y atento fue el personal del salón?',
          waiting_time: '¿Quedó satisfecho con el tiempo de espera para su servicio?',
          staff_professionalism: '¿Cómo califica el profesionalismo de nuestro personal?',
          salon_cleanliness: '¿Cómo califica la limpieza y comodidad en nuestro salón?',
          service_value: '¿Cree que el costo de los servicios coincide con su calidad?',
          recommend: '¿Recomendaría nuestro salón a sus amigos y conocidos?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'Para qué es el crédito',
    type_education: 'Tipo de educación',
    type_service: 'Tipo de servicio',
    credit_type: 'Tipo de crédito',
    how_to_contact: 'Cómo contactarte',
    note: 'Comentario',
  },
  items_view: {
    header: 'Productos',
    filter_form: {
      name_placeholder: 'Nombre',
      category_placeholder: 'Categoría',
      parlor_placeholder: 'Salón',
      all_categories: 'Todas las categorías',
      all_parlors: 'Todos los salones',
    },
    load_more_button: 'Cargar más',
    not_found_message: 'No encontrado',
  },
  item_view: {
    error_message: 'Error al cargar datos',
    available_in_parlor: 'Disponible en tu salón',
    not_available_in_parlor: 'No disponible en tu salón',
    similar_items_label: 'Productos similares',
    log_in_to_buy: 'Inicia sesión para comprar',
  },
  item_simple_card: {
    studio_price_label: 'Precio en estudio',
    shop_price_label: 'Precio en tienda',
  },
  student_candidate: {
    form: {
      who_studing: 'Estudiante o alquiler de espacio',
      phone_number: 'Número de teléfono',
      first_name: 'Nombre',
      last_name: 'Apellido',
      social_link: 'Enlace a cualquier red social',
      parlors: 'Estudio',
      couch: 'Silla de tatuaje',
      find_out: '¿Cómo te enteraste de nosotros?',
      start_date: 'Fecha de inicio deseada',
      types: 'Tipos de sesiones',
      work_permit_document: 'Documento de permiso de trabajo',
      sanitary_book: 'Libro sanitario',
      identity_proof: 'Documento de identidad',
      work_photos: 'Fotos de trabajos',
      end_date: 'Fecha de finalización deseada',
      confirmations: 'Confirmo que',
      know_buy_material: 'Estoy al tanto de que puedo comprar materiales en el estudio',
      customer_will_complete_agreement: 'Cada uno de mis clientes firmará un acuerdo',
      can_show_documents: 'Puedo proporcionar documentos originales en el estudio si es necesario',
      has_country_permission: 'Tengo permiso de estancia en el país',
      has_san_book: 'Tengo un libro sanitario',
      has_passport: 'Tengo un documento de identidad (ID)',
      conditions: 'He leído las condiciones del alquiler',
    },
  },
  user: {
    referral_links: {
      booking: 'Reserva de sesión',
      student_candidate: 'Alquiler de espacio',
      store: 'Tienda del cliente',
      candidates_form: 'Invitar a un empleado',
    },
  },
  products: {
    want_add: 'Quiero añadir los productos de cuidado seleccionados al coste de la sesión',
    no_warranty: '*sin esta opción, no podemos garantizarte una curación 100% exitosa',
    button_add: 'Agregar',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Suministros utilizados',
      name: 'Nombre',
      count: 'Cantidad',
    },
  },
  booking: {
    more_interest: 'Más cosas interesantes',
    active_promo: 'Promociones actuales',
    cashback: 'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          por la <span class="text-success">reserva online</span>',
    fast_booking: 'Reserva rápida',
    develop_sketch: 'Desarrollo del boceto',
    fast_booking_warning:
      'La reserva no es definitiva, nuestro gerente se pondrá en contacto contigo para confirmar la hora y la fecha de la sesión',
    phone_annotate:
      'Para ayudar a nuestro equipo de ventas a responder más rápido, por favor proporcione su número de contacto local. ¡Gracias!',
    increased_risk: '¡Atención! Sesión de alto riesgo',
    increased_risk_extended:
      'El tipo de sesión seleccionado y la ubicación en el cuerpo tienen un riesgo aumentado',
    approximate_price: 'Precio aproximado',
    price_warning: 'No se incluyen artículos adicionales',
    type: {
      info: 'Formulario conveniente para reservar una sesión con toda la información necesaria',
      booking_button: 'RESERVAR UNA SESIÓN',
      booking_button_promo: 'CON 15% DE REEMBOLSO EN VEAN COINS',
      unsure: '¿Aún no te decides? ¡Te ayudamos!',
      consultant_help: 'Nuestros consultores estarán encantados de ayudarte',
      consultation_button: 'CONSULTA',
      simplified:
        '¡Reserva tu sesión de forma rápida y cómoda! Elige la fecha, hora y servicio con unos pocos clics',
    },
    color: {
      black: 'Negro',
      colored: 'Coloreado',
    },
    controls: {
      next: 'SIGUIENTE',
      back: 'Atrás',
      no_select: 'No seleccionado',
    },
    place_service: {
      select_studio: 'Seleccionar estudio',
      service: 'Servicio',
      size: 'Tamaño',
      location: 'Ubicación',
    },
    customer_info: {
      login_prompt: 'Para continuar, por favor inicia sesión en tu cuenta',
      login_button: 'Iniciar sesión',
      black_white_colored: '¿Blanco y negro o en color?',
      select_style: 'Seleccionar estilo',
      tell_more:
        'Cuéntanos más sobre tus preferencias de tatuajes y comparte ejemplos (referencias)',
    },
    date_time: {
      choose_time: 'Elige un tiempo de visita',
    },
    master_card: {
      master_unavailable: 'El maestro no está disponible en este momento',
      master_guest_warning: '¡ATENCIÓN! El maestro está de visita',
      rank: 'Rango:',
      works_count: 'Cantidad de obras:',
      book_now: 'Reservar ahora',
      select_another_time: 'Seleccionar otro momento',
    },
    success: {
      session: 'SESIÓN',
      become_mystery_shopper: 'Conviértete en un comprador misterioso',
      add_to_calendar: 'Agregar evento al calendario',
      call_on_day: '*un administrador del salón te llamará el día de la sesión',
      return_to_menu: 'Volver al menú',
      recommended_products: 'Productos recomendados para la curación',
    },
    black_friday: '50% de descuento con depósito obligatorio',
  },
  tips: {
    placeholder: 'Elige cómo dividir las propinas',
    divide_by_all: 'Equitativamente',
    administrator: 'Al administrador',
    parlor: 'Al salón',
    master: 'Al maestro',
  },
  new_landing: {
    count_block: {
      countries: 'Cantidad de países',
      studios: 'Estudios',
      awards: 'Premios',
      masters: 'Maestros',
    },
    header_banner: {
      label_1: 'No hacemos una montaña de un grano de arena',
      label_2: '¡hacemos tatuajes!',
      book_now: 'Reservar ahora',
      consultation: 'Consulta',
      prices: 'Precios',
      top_works: 'Mejores trabajos',
      advantages: 'Ventajas',
      change_parlor: 'Cambiar salón',
    },
    course_card: {
      theory_part: 'Parte teórica:',
      practice_part: 'Parte práctica:',
      advance: 'Ventajas adicionales:',
    },
    helpful_links: {
      for_masters: 'Para maestros',
      tattoo_news: 'Noticias de tatuajes',
      tattoo_news_description: 'Mantente al día con lo más importante del mundo del tatuaje',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Nuestros maestros están listos para compartir conocimientos, secretos, técnicas',
      rent_space: 'Alquiler de espacio',
      rent_space_description:
        'Descubre las oportunidades de colaboración alquilando un espacio de trabajo en los estudios VEAN',
      tattoo_promotions: 'Promociones y promociones.',
      tattoo_certificates: 'Certificados de regalo',
      consumables: 'Materiales consumibles',
      equipment: 'Equipamiento',
    },
    price_block: {
      title: 'Precios',
      loyalty_program: {
        title: 'Programa de lealtad',
      },
      actions: {
        book_now: 'Reservar ahora',
        calculate_cost: 'Calcular costo',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Mejores trabajos',
      from_the_world: 'de todo el mundo',
    },
    why_vean: {
      title: 'Por qué VEAN',
      individual_approach: 'Enfoque individual',
      trust: 'Confianza',
      safety_guarantee: 'Garantía de seguridad',
      convenient_location: 'Ubicación conveniente',
      visited_studio: 'Personas visitaron este estudio',
    },
  },
  mystery_shopper: {
    title: 'Cliente misterioso',
    description:
      'Un cliente misterioso es alguien que verifica el funcionamiento de una tienda bajo la apariencia de un cliente común. Evalúa el nivel de servicio en la tienda.',
    offer:
      'Si decides convertirte en un cliente misterioso en esta sesión, te regalaremos monedas.',
    instructionTitle: 'Instrucciones',
    instructionDetails:
      'Durante la sesión, deberás evaluar la tienda y el personal según diferentes criterios. Además, después de la sesión, recibirás un SMS con un enlace a una encuesta que deberás completar (¡solo con honestidad!)',
    question: '¿Quieres convertirte en un cliente misterioso?',
    yesButton: 'Sí',
    noButton: 'No, gracias',
    successMessage: 'Deberás evaluar el estudio según los siguientes criterios:',
    criteria1: 'Consulta telefónica y en redes sociales',
    criteria2: 'Consulta en el estudio al llegar',
    criteria3: 'Política de precios del estudio',
    criteria4: 'Exterior: Fachada del estudio, letreros, ubicación, etc.',
    criteria5: 'Interior del estudio, decoración',
    criteria6: 'Gama de productos, souvenirs y curación (escaparate)',
    criteria7: 'Ambiente: TV, música, personal',
    criteria8: 'Disponibilidad de refrigerios, entretenimiento, revistas y catálogos de bocetos',
    criteria9: 'Comunicación con el maestro',
    criteria10: 'Comunicación con el administrador',
  },
  cookies: {
    text: `Utilizamos cookies para mejorar su experiencia de navegación, 
      mostrar anuncios o contenido personalizado y analizar nuestro tráfico. 
      Al hacer clic en "Aceptar", acepta el uso de cookies.`,
    confirm: 'Aceptar',
    more: 'Más información',
    manage: 'Configurar',
    config: {
      necessary: 'Necesarias',
      preferences: 'Preferencias',
      statistics: 'Estadísticas',
      marketing: 'Marketing',
    },
  },
  header_menu: {
    logout: 'Salida',
    main: 'Inicio',
    about_us: 'Sobre nosotros',
    discount_page: 'Descuentos',
    loyalty_page: 'Programa de lealtad',
    ai_page_link: 'Generar bosquejo',
    fonts_page_link: 'Fuentes de tatuaje',
    quality_certificate: 'Certificados de calidad',
    complaints: 'Quejas y sugerencias',
    confidence: 'Política de privacidad',
    public_offer_link: 'Oferta pública',
    work: 'Quiero trabajar en VEAN',
    dockuments_page_link: 'Documentos',
    login: 'Iniciar sesión',
    account: 'Área personal',
    studying: '¡Quiero estudiar!',
    buy_certificate: 'Comprar un certificado',
    feedback: 'Comentarios',
    loan: '¡Quiero un crédito!',
    services: 'Servicios',
    parlor_social_links: 'Enlaces sociales del salón e información de contacto',
    global_social_links: 'Enlaces sociales globales e información de contacto',
  },
  post: {
    welcome: 'Hola. Estuviste con nosotros en una sesión',
    no_answer_questions: 'No respondiste a las siguientes preguntas:',
    point: '¡Califica la calidad del servicio y obtén VeanCoins!',
    send: 'Enviar',
    error: '¡Ocurrió un error!',
    not_found: '¡Sesión no encontrada!',
    not_call: 'No necesito que me llamen de vuelta',
    thank: '¡Muchas gracias por tu calificación!',
    bot: 'En 21 días, nuestro bot te llamará desde un número desconocido para confirmar información sobre el servicio y la curación.',
    certificate: 'Se generó un certificado promocional para ti por %{gift_price} VeanCoins.',
    social_networks: 'Por favor, deja una reseña sobre nosotros en redes sociales:',
    month: 'Úsalo dentro de un mes y no olvides recordárselo al administrador.',
    dissatisfied:
      '¿No estás satisfecho con el servicio proporcionado? Procesaremos tu solicitud lo antes posible.',
    help_us_improve:
      'Ayúdanos a mejorar respondiendo preguntas y obtén <span class="text-warning">{bonusAmount}</span> VEANCOINS!',
    scores: {
      variants: {
        great: 'Excelente',
        good: 'Bueno',
        satisfactorily: 'Satisfactorio',
        bad: 'Malo',
        fast: 'Rápido',
        orally: 'De forma oral',
        in_writing: 'Por escrito',
        orally_in_writing: 'De forma oral y por escrito',
        yes: 'Sí',
        no: 'No',
        didnt_listen: 'No escuché',
      },
      administrator:
        '¿Cómo calificarías la consulta del administrador del estudio sobre servicios, maestros y cuidados?',
      recommendations: '¿Te proporcionaron recomendaciones claras para el cuidado?',
      master_contact: '¿El maestro te proporcionó su información de contacto?',
      holl: '¿Cómo calificarías la comodidad en la recepción (ambiente, limpieza, comodidad)?',
      price: '¿Cómo calificarías los precios de los servicios en el estudio?',
      callback: '¿Qué tan rápido recibiste respuesta de la recepción para programar una cita?',
      master: '¿Cómo calificarías al artista del estudio?',
      care: '¿Te ofrecieron productos de cuidado profesional o accesorios?',
      buy_other: '¿Compraste accesorios o productos de cuidado?',
      need_contact: '¿Necesitas que nos pongamos en contacto contigo?',
      loyalty_program: '¿Ya participas en el programa de lealtad?',
      sketch: 'Se me ofreció pedir un diseño o proyecto personalizado:',
      souvenir: 'Se me ofreció comprar productos de recuerdo o merchandising:',
      repeat_record: '¿Deseas agendar otra cita?',
    },
    scores_mystery_shopper: {
      instagram: 'Evaluación de nuestro INSTAGRAM',
      site: 'Evaluación del sitio: vean-tattoo.%{locale}',
      phone_consultation: 'Consulta por teléfono y en redes sociales',
      consultation: 'Consulta en el estudio al llegar',
      price: 'Opinión sobre la política de precios del estudio',
      exterior: 'Exterior: fachada del estudio, letreros, ubicación, etc.',
      interior: 'Interior del estudio, decoración',
      assortment: 'Surtido de productos (recuerdos, productos de cuidado)',
      atmosphere: 'Ambiente: TV, música, equipo',
      leisure: 'Disponibilidad de snacks, entretenimiento, revistas y catálogos de diseños',
      master: 'Interacción con el artista',
      admin: 'Interacción con el administrador',
      master_one_place: '¿El artista trabaja solo en este estudio? (sí/no)',
      master_contact: '¿Proporcionó datos de contacto personales si se le pidió? (sí/no)',
      care: '¿Te ofrecieron productos para la curación y recuerdos? ¿Recibiste un pequeño regalo como imán, pegatina o un certificado del 10%? (sí/no)',
      conclusion: 'Evaluación final de la inspección',
    },
    inputs: {
      cost: 'Indica el monto que pagaste por el servicio',
      cost_other: '¿Cuánto gastaste en accesorios o productos de cuidado?',
      review: '¿Tienes alguna sugerencia para mejorar el estudio?',
      photo: 'Foto del salón',
    },
    inputs_mystery_shopper: {
      recommendation: 'Tus recomendaciones para nosotros',
      why_vean: '¿Por qué elegiste VEAN?',
    },
  },
  header: {
    online_appointment: 'Hacer una cita vía online',
    post_session: 'Calificación de la calidad',
    get_price: 'Consulta',
    consultation: '(Consulta)',
    order_consultation: 'Solicitar una consulta',
  },
  index: {
    parlor: 'Studio',
    master: 'Maestro',
    session_type: 'Tipo de servicio',
    session_location: 'Lugar del servicio',
    datetime: 'Fecha y hora',
    create_appointment: 'Hacer una cita',
    bonus: '* Bonus del %{customer_bonus} VeanCoins al hacer la cita por cuenta propia',
    sketch: 'Bosquejo (no obligatorio)',
  },
  session_types: {
    tattoo: 'Tatuaje',
    tatouage: 'Tatuado',
    piercing: 'Perforaciones',
    stroke: 'Eliminación de tatuaje',
    training: 'Curso',
    training_tattoo: 'Curso de tatuaje',
    training_piercing: 'ОCurso de piercing',
    training_tattouage: 'Curso de tatuado',
    training_udder: 'Curso de eliminación de tatuaje',
  },
  price_form: {
    client_name: 'Nombre',
    client_phone: 'Teléfono',
    client_comm: 'Tu servicio',
    sketch_desc: 'Agregar bosquejo (no obligatorio)',
    sketch_button: 'Agregar un archivo',
    send: 'Enviar',
    or: 'O',
    country: 'País',
    city: 'Ciudad',
    comment: 'Comentario',
    val_error: '¡Verifica los datos introducidos!',
    ths_message: '¡Gracias! , a la brevedad se comunicarán contigo',
    go_back: 'Volver',
    file_size: 'El tamaño del archivo no debe ser mayor a 5 mb',
  },
  selectors: {
    parlor: {
      placeholder: 'Búsqueda por nombre del salón...',
    },
    master: {
      placeholder: 'Búsqueda por nombre, servicio...',
    },
    select_type: {
      select: 'Elegir',
      next: 'Adelante',
      no_price: 'Negociable',
      ad: 'Desde',
      da: 'Hasta',
      selected: 'selected',
      info: 'El precio puede depender del tamaño y la ubicación',
    },
    datetime: {
      select_date: 'Elige la fecha que te interesa',
      no_active_times: 'Para esta fecha ya no queda cupo disponible. ¡Elige otra por favor!',
    },
  },
  verify: {
    verify_account: 'Verificar cuenta',
    type: 'Método de verificación',
    check_code: 'Verificar código',
    status: 'Estado',
    input_phone: 'Introduce un número de teléfono',
    email_help: 'También puedes usar el correo electrónico para la verificación',
    sms_sent: 'Te ha sido enviado un código por SMS, introdúcelo abajo',
    resend_sms_after: 'Reenvío disponible dentro de %{seconds} segundos',
    resend_sms: 'Enviar una vez más',
    buttons: {
      back: 'Atrás',
      next: 'Adelante',
      create_appointment: 'Hacer una cita',
    },
  },
  success: {
    h1: '¡Gracias! , La cita ha sido generada con éxito',
    h2: 'Después de la aprobación de la cita por el administrador te será enviado un SMS.',
    mystery: 'Ser un comprador misterioso',
  },
  about: {
    header: 'Información sobre ti',
    birth_info:
      'Al especificar la fecha de nacimiento, nos aseguramos de que tenga un Edad, y también es necesario para un descuento de cumpleaños :)',
    inputs: {
      name: 'Nombre y Apellidos',
      find_out: 'Donde te has enterado',
      birth_date: 'Fecha de nacimiento',
    },
    buttons: {
      skip: 'Dejar pasar',
      back: 'Atrás',
      next: 'Adelante',
    },
  },
  footer: {
    header: 'Súscribete a nuestras redes sociales para estar al tanto de todas las noticias',
    site_navigation: 'Navegación',
    interest: 'Interesante',
    other: 'Otros',
  },
  subscribe_modal: {
    header: 'Suscríbete a las noticias por un bono',
    submit: 'Enviar',
    wrong_data: '¡Verifique la corrección de los datos ingresados!',
    wrong_email: '¡Dirección de correo electrónico incorrecta!',
    thanks: '¡Gracias por suscribirte!',
    error: '¡Se produjo un error!',
  },
  chat_bot: {
    call: 'Llamar',
    online_record: 'Reservar en línea',
    online_consultation: 'Consulta en línea',
    need_help: '¿Necesita ayuda con un boceto?',
    order_sketch: 'Pedir boceto',
    dress_sketch: 'Probar boceto',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Situaciones problemáticas',
      id: 'ID',
      status: 'Estado',
    },
    reset_password: {
      title: 'Olvidó su contraseña',
      submit: 'enviar',
      forgot_password: 'Olvidó su contraseña',
      phone: 'Ingrese el número de teléfono',
      success_message: 'Se ha enviado una contraseña temporal a su correo electrónico/teléfono',
      login_promo: 'Obtén un 10% Cashback al iniciar sesión',
      wish: 'Deseo',
    },
    registration: {
      name: 'Nombre',
      surname: 'Apellido',
      registered: 'Registro',
      congratulations: '¡Felicidades!',
      successfully_registered: 'Se ha registrado exitosamente!',
      log_in: 'Iniciar sesión',
    },
    login_page: {
      log_in_page: 'Iniciar sesión en su cuenta personal',
      number_missing: '¿No tienes número?',
      register: '¡Regístrate!',
      steps: {
        sign_up: 'Regístrate en el programa de lealtad o INICIA SESIÓN',
        check_coins: 'Verifica tus VeanCoin registrándote en el programa de lealtad',
        get_coins: 'Obtén tus VeanCoins al final de cada sesión',
        accumulate: 'Recoge y acumula VeanCoin en tu perfil',
        book_session: 'Reserva una sesión y ven a Vean para hacerte el tatuaje',
        exchange_coins: 'Cambia VeanCoins y obtén descuentos',
      },
    },
    find_customer: {
      continue: 'Continuar',
      not_found: '¡Cliente no encontrado!',
      select_type: 'Seleccione el tipo de inicio de sesión',
      no_password: '¡No ha establecido una contraseña!',
      found: '¡Encontrado!',
      check: 'Comprobando...',
      invalid_phone_or_password: 'Número de teléfono o contraseña no válidos',
      error_login: 'Error durante el inicio de sesión',
      phone: 'Teléfono',
      temporary_password_message: 'Estás usando una contraseña temporal',
      permanent_password_message: 'Usas una contraseña permanente',
    },
    product_card: {
      availability: 'La presencia en los estudios:',
      unavailable: 'No disponible',
    },
    store_body: {
      full_points_products: 'Productos que se pueden comprar con VeanCoins',
      half_points_products: 'Productos que se pueden comprar con un descuento del 50%',
      no_products: 'No hay productos disponibles',
    },
    description_block: {
      loyalty_system: 'Sistema de lealtad VEAN',
      show_more: 'Mostrar más',
    },
    profile_card: {
      my_sketches: 'Mis bocetos',
      share_link: 'Comparte este enlace',
      tips: 'Propina',
      send_certificate: 'Enviar certificado',
      vip: 'VIP',
      not_vip: 'Convertirse en VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Dinero',
      active_certificates: 'Monedas activadas',
      balance: 'Saldo',
      sessions: 'Sesiones',
      points_alltime: 'Monedas obtenidas en todo momento',
      available_certificates: 'Certificados disponibles',
      certificates: 'Certificados monetarios',
      bought_certificates: 'VeanCoins activadas',
      certificate_number: 'Número de certificado',
      available_for: 'Válido hasta: ',
      no_active_certificates: 'No hay certificados monetarios',
      no_active_bought_certificates: 'No hay VeanCoins activas',
      coming_sessions: 'Sesiones próximas',
      complete_agreement: 'Completar el acuerdo',
      date: 'Fecha: ',
      you_was: 'Has asistido a %{sessions} sesiones',
      edit: 'Editar',
      verify_account_status: 'Estado de la cuenta',
      security_percent: 'Seguridad:',
      not_email: 'para enviar el certificado, debe completar el correo electrónico',
      exist_application: 'Hay una aplicación',
      need_exist_application: 'Ninguna aplicación',
      need_confirm_account: 'Necesidad de confirmar la cuenta',
      verified: 'Verificado',
      choose_payment_method: 'Elegir método de pago',
      parlor: 'Salón',
      sign_up_online: 'Registrarse para una sesión',
      mystery_shopper: 'Comprador misterioso',
      sign_up_secret_buyer:
        'Regístrate con nosotros para una sesión y conviértete en un comprador misterioso',
      pay: 'Pagar',
      prepayment: 'Prepago',
      questionnaire: 'Cuestionario',
      agreement: 'Acuerdo',
      fill_required_information: 'Es necesario rellenar la información',
      buy: 'Recargar saldo',
      archive: 'Archivo',
      support_service: 'Soporte al Cliente',
      balance_buy_history: 'Historial de puntos VeanCoins',
      balance_history: 'Historial de puntos VeanCoins',
      buy_history: 'Historial de compras',
      сost: 'Costo',
      payment_link: 'Enlace de pago',
      attributes: 'Atributos',
      invite_friend: 'Invitar a un amigo',
      phone_or_email: 'Ingrese un número de teléfono o correo electrónico',
      report_problem: 'Reportar un problema',
      note: 'Escribir un comentario',
      tooltip_coins:
        'necesitas activar tus monedas acumuladas para el servicio seleccionado o para la compra de productos para evitar su expiración en un año',
      tooltip_certificate:
        'cantidad de tus certificados monetarios comprados o recibidos como regalo, que pueden ser utilizados en un plazo de 2 meses',
      tooltip_сonfirmed: 'En la edición, cambia «ФИО или username» a «Nombre y Apellido»',
      tooltip_missing:
        'Agrega información sobre ti (nombre y apellido, fecha de nacimiento, enlace a tus redes sociales, cómo supiste de nosotros, número de teléfono y correo electrónico para confirmar tu identidad)',
      tooltip_verified:
        'Necesitas confirmar tu cuenta con un número de teléfono o correo electrónico para proteger tus VeanCoins acumulados y obtenidos.',
      tooltip_vip:
        'Cliente VIP es un estatus que puede obtener cualquier cliente que haya gastado más de 1500 euros en servicios. El estatus VIP ofrece ciertas ventajas a su propietario, como: registro prioritario rápido para sesiones fuera de turno, acceso a productos y servicios únicos, al alcanzar el estatus VIP, el cliente recibe inmediatamente 1000 monedas en su cuenta',
      earn_veancoins: 'Gana veancoins',
    },
    profile_edit: {
      additional_information: 'Información adicional sobre VeanCoins',
      parlors: 'Salón (que está más cerca o principal)',
      client_card: 'Tarjeta del cliente',
      full_name: 'Nombre y apellido o nombre de usuario',
      birth_date: 'Fecha de nacimiento (para felicitaciones)',
      social_media_link: 'Enlace a redes sociales',
      find_out: 'Cómo se enteró',
      or: 'o',
      phone_placeholder: 'Teléfono',
      save: 'Guardar',
      password_tooltip:
        'Crea una contraseña para acceder a tu perfil en el futuro sin necesidad de SMS o correos electrónicos. También protege tus datos personales.',
      password: 'Contraseña',
      repeat_password: 'Repite la contraseña',
      email_taken: '¡Este correo electrónico ya está ocupado!',
      phone_taken: '¡Este número de teléfono ya está ocupado!',
      simple_password: 'Contraseña demasiado simple!',
      invalid_password: '¡Contraseña inválida!',
      password_for_application: 'Contraseña para la aplicación (opcional)',
      congratulate: '¡Felicidades!',
      successfully_registered: 'Registrado con éxito!',
      need_confirm_account: 'Necesita verificar la cuenta',
      yes: 'Sí',
      no: 'No',
      phone_confirmed: 'Teléfono confirmado',
      email_confirmed: 'Correo electrónico confirmado',
      change_password: 'Cambiar contraseña',
      old_password: 'Contraseña antigua',
      new_password: 'Nueva contraseña',
      confirm_password: 'Repetir contraseña',
      invalid_phone_or_password: 'Número de teléfono o contraseña no válidos',
      error_login: 'Error al iniciar sesión',
      years_old: 'Edad',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Género',
      man: 'Hombre',
      woman: 'Mujer',
      gender_hidden: 'Prefiero no decirlo',
      language: 'Idioma',
      preferred_style_status: 'Estilos preferidos',
      classic: 'Clásico / Realismo',
      freak: 'Raro / Basura / Anime',
      fashion: 'Moda / Arte / Minimalismo',
      rock: 'Rock / Duro',
      pop: 'Pop / Masivo',
      accommodation_status: 'Estado de alojamiento',
      local: 'Local',
      tourist: 'Turista',
      guest: 'Invitado',
      lifestyle_status: 'Estilo de vida',
      extreme: 'Extremo',
      dance: 'Danza',
      anime: 'Anime',
      game: 'Juego',
      music: 'Música',
      lgbt: 'LGBT',
      art: 'Arte / Modernidad',
      family: 'Familia / Amor',
      beauty: 'Belleza',
      sexuality: 'Sexualidad',
      masculinity: 'Masculinidad',
      tattoo_count_status: 'Cantidad de tatuajes',
      pierce_count_status: 'Cantidad de piercings',
      one: 'Uno',
      two: 'Dos o más',
      inc: 'Muchos',
      change_information: 'Guardar información',
    },
    verification: {
      sms: 'Confirmar por SMS',
      email: 'Confirmar por Email',
      google: 'Verificar por Google',
      send_code: 'Enviar código',
      code: 'Código de verificación',
    },
    loyalty: {
      submit_button: 'Enviar',
      bonus_instruction:
        'Realiza una de las siguientes acciones, adjunta pruebas (capturas de pantalla, fotos, etc.) y obtén un bono de',
      bonus_amount: 'VeanCoins',
      review_option_1: 'Dejar una reseña detallada en el perfil del maestro en el sitio web',
      review_option_2: 'Calificar el estudio o el maestro en el sitio web',
      review_option_3: 'Calificar el estudio y dejar una reseña en el mapa',
      review_option_4: 'Compartir nuestro estudio en redes sociales',
      return:
        'Si la foto que enviaste no pertenece a ninguna de las categorías por las que otorgamos bonificaciones, las bonificaciones recibidas serán anuladas al comprar un certificado de regalo.',
      evidence_label: 'Evidencia',
    },
    buy_certificate: {
      payment_type_title: 'Tipo de compra',
      payment_type_subtitle: 'Qué desea hacer',
      buy_balance: 'Recargar saldo',
      buy_certificate: 'Comprar certificado',
      amount_title: 'Monto',
      amount_subtitle: 'Ingrese el monto',
      payment_method_title: 'Método de pago',
      payment_method_subtitle: 'Elija un método de pago',
      payment_title: 'Pago',
      forming_payment: 'Formando el pago...',
      pay: 'Pagar',
      continue: 'Continuar',
      back: 'Atrás',
      submit_to_email: 'Enviar certificado al correo electrónico',
      get_vean_card: 'Obtener tarjeta VEAN en el estudio',
      vean_card_description:
        'VEAN CARD es tu tarjeta donde los certificados de VEAN TATTOO se activan automáticamente, garantizando seguridad y comodidad. Todos tus regalos y descuentos siempre estarán a tu alcance, y usar la tarjeta es lo más sencillo y beneficioso posible. Obtén tu tarjeta en los estudios VEAN TATTOO y descubre todas las ventajas hoy mismo.',
      total_balance: 'Balance',
      certificate: 'Certificado',
      customer_balance: 'Saldo del cliente',
      certificate_type: 'Tipo de certificado',
      promotion_certificate: 'Certificado de promoción',
      buy_for_myself: 'Comprar para mí',
      promotion_certificate_message:
        'Se emitirán certificados físicos hasta el 31 de diciembre de 2024. A partir del 1 de enero de 2025, los certificados se convertirán en puntos y se mostrarán en la cuenta personal del usuario.',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Más de 160 salones',
      clients_count: 'Más de 1,000,000 clientes',
      largest_company: 'La red de estudios de tatuajes más grande del mundo',
    },
    master_block: {
      our_masters: 'Nuestros tatuadores',
    },
    master_card: {
      works_count: 'Cantidad de trabajos:',
    },
    no_admin_block: {
      leisure: 'Ocio',
      session_record: 'Reservar sesión',
      about_company: 'Acerca de la compañía',
      care: 'Cuidado',
      quality_certificates: 'Certificados de calidad',
      public_buyout_offer: 'Oferta pública',
      complaints_offers: 'Quejas y sugerencias',
      loyalty_system: 'Védi la sistema de lealtad',
      discount_page: 'Descuentos',
    },
    peculiarities_block: {
      about_studio: 'Acerca de este estudio',
      clients: 'Clientes',
      sessions: 'Sesiones',
      studio_hours: 'Horario de trabajo:',
      studio_phone: 'Número del estudio:',
      wifi: 'Wi-fi:',
      password: 'Contraseña:',
      from_to: 'de %{time_start} a %{time_end}',
    },
    photos_block: {
      photos: 'Foto del salón',
    },
    top_works: 'Trabajos',
    care_important: 'Lo más importante en piercing y tatuajes es la curación y el cuidado.',
    cabinet: 'Gabinete personal',
    complete_agreemnent: 'Completa el acuerdo',
    is_blanket: 'Hay una manta para abrigarse',
    is_water: 'Hay bebidas calientes',
    is_hot_drinks: 'Hay dulces',
    is_treats: 'Hay bebidas frías',
  },
  promotion: {
    submit: 'Enviar',
    warning: '¡Atención! ¡Verifica la corrección de los datos ingresados!',
    name: 'Nombre / Apodo',
    required: '¡Este campo es obligatorio!',
    max_value: 'Debe tener menos de 100 caracteres!',
    phone: 'Teléfono',
    social_link: 'Enlace a red social',
    wrong_format: '¡Formato incorrecto!',
    terms: 'Estoy informado sobre la política de privacidad',
    know_bonus: 'Sé que se me otorgarán puntos de bonificación',
    error: 'Se produjo un error',
    thanks: '¡Gracias!',
    bonus: 'Se te han otorgado %{gift_price} puntos',
    do_not_disturb: 'No molestar (desactivar SMS-y llamadas de VeanTattoo)',
    get_promo: 'Quiero recibir información sobre descuentos y promociones',
    first_time_veancoins_notification:
      'Para usar VeanCoins por primera vez, es necesario ponerse en contacto con el administrador del estudio. VeanCoins y Dinero tienen diferentes reglas de uso',
  },
  another_studio_drawler: {
    another: 'Otros estudios',
  },
  landing: {
    best_studio: 'Mejor estudio de tatuajes de tu ciudad',
    vean_wide: 'RED VEAN TATTOO',
    studio_count: 'Más de 160 estudios de tatuajes',
    quality: 'Garantía de calidad y seguridad',
    get_price: 'OBTENER PRECIO',
    contact_us: 'Póngase en contacto con nosotros',
    advantage: 'Ventajas',
    top_works: 'Mejores trabajos',
    prices: 'Precios', //долджен быиь
    studio_photo: 'Fotos del estudio',
    prices_services: 'Precios y servicios',
    about: 'Acerca de nosotros',
    loyalty_system: 'Programa de lealtad',
    why_vean: 'Por qué VEAN',
    visit_count: 'Personas que visitaron este estudio',
    description:
      'La red de estudios de tatuajes VeAn Tattoo es la más grande del mundo con más de 160 salones en 14 países de Europa. Hemos llevado esta industria a nuevos niveles de estándares. Nuestra especialización es amplia, pero nuestro enfoque principal es la industria del tatuaje y el piercing. Los principios clave de VeAn Tattoo son: experiencia y accesibilidad, reputación y orientación al cliente, valor y asequibilidad, equipo certificado y de alta calidad, especialistas altamente calificados y una amplia gama de servicios. En VeAn Tattoo, solo trabajan maestros con diplomas reconocidos internacionalmente. Ofrecemos una variedad de estilos y técnicas de tatuaje y creamos diseños personalizados gratuitos según sus preferencias. Le recomendamos que siga todas nuestras páginas oficiales en redes sociales para estar al tanto de ofertas especiales, concursos, trabajos de nuestros maestros y las últimas noticias y actualizaciones de VeAn Tattoo.',
    advantages: {},
    our_masters: 'Nuestros maestros', //doljen bit
    from: 'Desde',
    no_price: 'Negociable',
    form: {
      get_price_consultation: 'Obtener precio / Consulta',
      name: 'Nombre',
      phone: 'Teléfono',
      place: 'Su ubicación',
      subscribe_news: 'Suscribirse al boletín para obtener un bono',
    },
  },
  form_messages: {
    submit: 'Enviar',
    warning: '¡Atención! Verifique la corrección de los datos ingresados',
    required: 'Campo obligatorio',
    length: 'No más de %{length} caracteres',
    file_length: 'No más de %{length} archivos',
    wrong_format: 'Formato incorrecto',
    error: 'Ha ocurrido un error',
    thanks: 'Gracias!',
    required_label: 'Requerido',
    not_required_label: 'Opcional',
  },
  training: {
    training: 'FORMACIÓN',
    tattoo_school: 'ESCUELA DE TATUAJES',
    biggest_school: 'la mayor red de salones de tatuaje en el mundo',
    ne_otkladivaj: 'No<br/>pospongas<br/>para más tarde',
    learn: 'Aprende a tatuar<br/>y conviértete en un maestro top',
    reviews: 'Opiniones de los graduados',
    record: 'Registrarse',
    video_text: '¿Cómo puedes ayudar,<br/>qué enseñarás?',
    ded: {
      consultation: 'consulta',
      get_more: 'Obtén más información',
    },
    header_bar: {
      main: 'Principal',
      get: 'Qué obtendrás',
      program: 'Programa del curso',
      about: 'Sobre nosotros',
      reviews: 'Opiniones',
    },
    our_course: {
      head: 'Nuestros<br/>cursos',
      description_1:
        'este es el lugar donde siempre obtienes respuesta a tus preguntas y aprendes algo nuevo',
      description_2: 'Y aunque no tengas formación artística - el resultado no tardará',
      label: '[ con nosotros es cálido, acogedor y emocionante ]',
      description_3:
        'Adoramos a nuestros estudiantes e intentamos hacer todo lo posible para que el proceso de aprendizaje sea cómodo',
    },
    banner: {
      we_teach: 'Te enseñaremos a tatuar',
      good_make: 'TATUAMOS GENIAL',
      our_masters:
        'NUESTROS MAESTROS ESTÁN LISTOS PARA COMPARTIR CONOCIMIENTOS, SECRETOS, TÉCNICAS',
      learn_too: 'Y a ti también te enseñaremos',
    },
    modal: {
      thanks: '¡Gracias!',
      error: '¡Ha ocurrido un error!',
      complete: 'Completa el formulario y te llamaremos',
      full_name: 'Nombre completo',
      phone: 'Número de teléfono',
      submit: 'Enviar',
      cancel: 'Cancelar',
    },
    paragraph: {
      header_1: 'Definitivamente enseñaremos',
      content_1: 'No hacemos el aprendizaje en masa',
      header_2: 'saber dibujar',
      content_2: 'Te enseñaremos todo/no tener una educación artística no es un problema',
      header_3: 'Certificado',
      content_3:
        'Al finalizar los cursos, recibes un certificado de estándar internacional. Aprobado por Global Tattoo ACC',
      header_4: 'Te emplearemos',
      content_4:
        '¿En serio ahora? Sí, los mejores graduados pueden formar parte de nuestro equipo y trabajar en cualquier punto de Europa',
      header_5: 'Contrato',
      content_5: 'No trabajamos basados en "palabra de honor". Todo es oficial y bajo contrato',
      no_sleep: {
        header: 'no duermas',
        rect_1: '¿Cómo?',
        rect_2: 'VIDA',
        main_label: 'Comienza a aprender justo ahora',
        label_1: '1000+ graduados',
        label_2: '100% completaron la formación',
        label_3: '80% consiguieron empleo',
        label_4: '300+ socios en la industria del tatuaje',
      },
    },
    principies: {
      head: 'Garantías',
      label_1: '1. Cultura',
      label_2: '2. Calidad',
      label_3: '3. Métodos',
      heading: 'Todo se construye sobre principios',
      description_1:
        'Hace 11 años decidimos empezar a desarrollar la cultura de la industria del tatuaje, no como todos, a nuestra manera - basada en conocimientos y experiencia',
      description_2:
        'Hoy hemos entrenado a más de 1000 excelentes tatuadores. 80% se convirtieron en parte de nuestro equipo. Podemos decir con confianza que cualquiera puede aprender el arte del tatuaje',
      description_3:
        'Todo se trata de la metodología que usamos, que constantemente mejoramos junto con el equipo, artistas y nuestros graduados, quienes regularmente dan retroalimentación',
    },
    packages: {
      head: 'Programas de cursos',
      from: 'desde',
      plus: 'Ventajas',
    },
    facilities: {
      head: 'Oportunidades y beneficios',
      subhead: 'Elige nuestros cursos y siente la magia de la industria del tatuaje',
      card: {
        head_1: 'Cada uno de nuestros graduados recibe un certificado de estándar internacional',
        label_1: '- tu boleto a un mundo de oportunidades',
        head_2: 'Recomendación y apoyo de un grupo de maestros profesionales',
        label_2: '¡Tu éxito es nuestro orgullo!',
        head_3: 'Comunidad de tatuajes, recibe consejos y apoyo de nuestros expertos',
        label_3: '¡Tu nueva vida en el mundo del tatuaje comienza ahora!',
      },
    },
    review: {
      head_1: 'Casos de maestros',
      head_2: 'Opiniones',
      comment_1:
        '¡La formación en tatuajes en esta escuela superó todas mis expectativas! Los profesores son profesionales en su campo, cada etapa de la formación se revisa detalladamente, el ambiente es genial. Ahora puedo implementar con confianza mis ideas creativas en la piel de los clientes!',
      comment_2:
        '¡Gracias a la escuela por tal formación de calidad en tatuajes! Era un poco aterrador empezar en este negocio, pero gracias a los profesores atentos y pacientes, me sentí seguro. ¡Ahora mis trabajos reciben reconocimiento y admiración!',
      comment_3:
        '¡Fue un viaje increíble al mundo de los tatuajes! La escuela proporciona todos los conocimientos y habilidades necesarios para convertirse en un verdadero profesional. Estoy agradecido por el apoyo e inspiración que recibí aquí',
    },
  },
  questionary: {
    first: {
      heading: 'CONSENTIMIENTO PARA PROCEDIMIENTO EN EL ESTUDIO DE TATUAJES VEAN',
      textbox:
        'Si el cliente es menor de 18 años, el consentimiento debe ser completado por los padres o tutores legales (En Columbia Británica, Canadá, se considera menor a una persona menor de 19 años. Si tienes menos de 19 años, elige la SEGUNDA opción)',
      has_18: 'SÍ, tengo 18 años o más',
      no_18: 'NO, soy menor de 18 años',
    },
    second: {
      warning: '¡Atención! ¡El día de la sesión, es necesario venir con tutores!',
      name: 'Nombre completo',
      birthdate: 'Fecha de nacimiento',
      address: 'Dirección de residencia',
      email: 'Correo electrónico',
      sex: 'Género',
      heading: 'PROCEDIMIENTO A REALIZAR',
      man: 'Hombre',
      woman: 'Mujer',
      other: 'No especificado',
      parent_data: 'Datos de uno de los padres:',
      child_data: 'Datos de un MENOR DE EDAD:',
      child_field_placeholder: 'Hijo, hija, etc.',
      child_field_label: 'Para quién se realiza el procedimiento',
      confirm:
        'Yo, siendo el representante legal de un menor de edad, doy mi consentimiento para realizar el siguiente procedimiento a mi hijo.',
    },
    third: {
      heading: 'TODO LO QUE NECESITAS SABER SOBRE EL PERÍODO DE CURACIÓN:',
    },
    fourth: {
      nothing: 'Ninguna de las anteriores',
      has_diseases: '¿Tiene alguna enfermedad?',
      has_contraindications: '¿Tiene alguna contraindicación?',
      heading1:
        'SI TIENES ALGUNA DE LAS SIGUIENTES ENFERMEDADES, NECESITAMOS SABERLO, POR FAVOR MARCA LOS PUNTOS RELEVANTES:',
      heading2: 'SI HAY CONTRAINDICACIONES, PROPORCIONA LA INFORMACIÓN RELEVANTE:',
    },
    fifth: {
      heading: 'PROHIBIDO DESPUÉS DEL PROCEDIMIENTO:',
    },
    sixth: {
      heading: '¿DÓNDE TE ENTERASTE DE NOSOTROS?',
      label:
        'Después de 21 días, uno de nuestros empleados puede ponerse en contacto contigo como parte de una verificación de calidad de los servicios prestados. Además, después de la sesión, recibirás notificaciones por SMS, y es posible que nuestro robot automatizado realice una llamada. En tu cuenta personal, puedes optar por no recibir servicios de notificación por llamadas y SMS, y también puedes eliminar todos tus datos y fotos después de 21 días a partir de la fecha del servicio.',
    },
    seventh: {
      heading: 'DECLARACIÓN Y CONSENTIMIENTO',
    },
    signature: {
      heading:
        'Yo, el abajo firmante (%{name}), por la presente consiento en el procedimiento de hoy con mi participación\n' +
        '%{session_type} en mi cuerpo y acepto las condiciones contenidas en este documento.',
    },
    next: 'Siguiente',
    submit: 'Enviar',
    no_responsibility: 'El estudio no es responsable de esta sesión',
    acquaint: 'He leído la información proporcionada',
    acquaint_2:
      'Entiendo que Vean Tattoo no es responsable del proceso de curación.\n' +
      'En el caso de la corrección del tatuaje o piercing mencionado anteriormente, el costo de la corrección será del 30% del costo del servicio en sí.\n' +
      'Si no está satisfecho con el resultado de la corrección, en este caso, los fondos por el servicio no son reembolsables, ya que las áreas mencionadas anteriormente no son favorables para el propio servicio.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Sin solución',
      in_check: 'En revisión',
      problem_solved: 'Problema resuelto',
      wait: 'En espera',
      small: 'Situación menor',
      unanswered_check: 'Revisión sin respuesta',
    },
  },
  master_page: {
    card: {
      active: 'Activo',
      not_active: 'No activo',
      master_since: 'Maestro VEAN desde',
      services: 'Servicios',
      styles: 'Estilos',
      book: 'Reservar',
    },
    information: {
      top_works: 'Mejores trabajos',
      awards_and_diplomas: 'Premios y diplomas',
    },
  },
  stripe_warning:
    '¡Atención! El importe mínimo de pago con stripe es de 20€. Si se necesita una cantidad menor, por favor contacte con el estudio para más detalles',
  check_in: {
    rate: {
      upload_photo: 'Sube una foto de tu trabajo',
      upload_photo_desc:
        'Puedes tomar una foto bonita de tu trabajo para nuestras redes sociales y sitio web. El administrador también toma una foto para el control de calidad y garantizar el trabajo realizado. Siempre puedes optar por no publicar ni compartir la foto, y tus datos se eliminarán completamente después de la curación.',
      bad_comment: 'Por favor, explica por qué diste una calificación baja',
      review: '¡Deja una reseña!',
      review_desc:
        'Estaríamos muy agradecidos si dejas una reseña. ¡Tu opinión es importante para nosotros!',
      cashback: 'x2 de reembolso en tu cuenta en la aplicación',
      send_invoice: 'Enviar factura al correo electrónico',
      influencer: '¿Tienes más de 2000 seguidores en redes sociales?',
    },
    welcome_message: '¡TE DESEAMOS UNA BUENA SESIÓN!',
    session_started: 'TU SESIÓN HA COMENZADO',
    next_steps: 'Después de completar la sesión, otros pasos estarán disponibles para ti',
    buy_healing_product: 'TE RECOMENDAMOS COMPRAR UN PRODUCTO CURATIVO',
    skip: 'Omitir',
    leave_review: 'Por favor, deja una reseña',
    your_impression: '¿Cuáles son tus impresiones generales?',
    add_photo: 'Agrega una foto del trabajo para obtener 1 Vean Coin adicional',
    send: 'Enviar',
    payment_success: 'El pago fue exitoso',
    get_invoice: '¿Quieres obtener una factura?',
    invite_friend: 'Invitar a un amigo',
    recommend_us: 'Recomiéndanos a tus amigos y gana junto a ellos',
    thank_employees: 'También proponemos agradecer a nuestros empleados',
    you_can_tip:
      'Querido Nikita, también puedes agradecer a nuestros empleados con una determinada cantidad de propinas',
    own_amount: 'Cantidad propia',
    who_to_tip: '¿A quién se debe dar propina?',
    payment_type: 'Tipo de pago',
    continue: 'Continuar',
    summary: 'Resumen',
    healing_agent: 'Agente curativo',
    tips: 'Propinas',
    session_payment: 'Pago de sesión',
    total: 'Total',
    waiting: 'Esperando...',
    available_funds: 'Fondos disponibles',
    refill_balance: 'Recargar saldo',
    go_to_payment: 'Ir al pago',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Propinas',
    prepayment: 'Prepago',
    have_questions: '¿Tienes preguntas?',
    title: 'Sesiones',
    selectStatus: 'Selecciona un estado',
    selectParlor: 'Selecciona un salón',
    loading: 'Cargando...',
    fromDate: 'Desde la fecha',
    toDate: 'Hasta la fecha',
    search: 'Buscar',
    all: 'Todos',
    status: {
      100: 'Pendiente',
      200: 'Nuevo',
      300: 'En progreso',
      400: 'Completado',
      500: 'Cancelado',
      600: 'En espera de pago',
    },
    details: 'Detalles de la sesión',
  },
  master: {
    rank: 'Rango',
    works_count: 'Número de trabajos',
    address: 'Dirección',
  },
  modalPhone: {
    title: '¡Atención!',
    info: 'Estimado cliente, para su seguridad y un uso eficaz de nuestro servicio, es obligatorio agregar todos los datos. Esto nos ayudará a responder puntualmente a cuestiones importantes y garantizar su protección.',
    reminder: 'Recuérdame más tarde',
    go_to_settings: 'Ir a configuraciones',
    roughly_price:
      'El costo aproximado depende de la precisión de los datos que haya proporcionado',
    success_message: '¡Gracias!',
  },
  studyingRegistration: {
    become_student: 'Conviértete en estudiante',
    step_1: 'Datos generales',
    step_2: 'Información personal',
    step_3: 'Detalles del estudio',
    city: 'Ciudad',
    city_placeholder: 'Ingresa tu ciudad',
    name: 'Nombre',
    name_placeholder: 'Ingresa tu nombre',
    last_name: 'Apellido',
    last_name_placeholder: 'Ingresa tu apellido',
    date_of_birth: 'Fecha de nacimiento',
    date_of_birth_placeholder: 'Ingresa tu fecha de nacimiento',
    phone: 'Número de teléfono',
    email: 'Correo electrónico (opcional)',
    email_placeholder: 'Ingresa tu correo electrónico',
    social_media: 'Redes sociales',
    social_media_placeholder: 'Enlace a tu perfil',
    referral_source: '¿Cómo te enteraste de nosotros?',
    referral_source_placeholder: 'Selecciona una fuente',
    study_type: 'Tipo de estudio',
    study_type_placeholder: 'Selecciona tipo de estudio',
    start_date: 'Fecha estimada de inicio',
    start_date_help: 'Ingresa la fecha estimada de inicio',
    hours_number: 'Número de horas',
    practice_number: 'Número de prácticas',
    details: 'Detalles',
    education_price: 'Precio de la formación',
    moto: 'Un método de enseñanza probado durante décadas',
    description: 'Descripción',
    material: 'Material',
  },
  studying_main: {
    become_student: '¡Conviértete en estudiante!',
    consultation: 'Consulta',
    want_to_study: '¡Quiero estudiar!',
    consult_with_our_advisor: '¿No sabes por dónde empezar? ¡Contacta con nuestro asesor!',
  },
  errorTicket: {
    error_description: 'Informe sobre el problema de la aplicación y lo resolveremos rápidamente',
    error_button: 'Informe sobre el mal funcionamiento de la aplicación',
    problem_situation_description: '¿Ocurrió un problema después de la sesión? Infórmanos',
    problem_session_button: 'Informar sobre el problema después de la sesión',
    problem_session: 'Informar de un problema después de la sesión',
    sidebar_title: 'Reportar problema',
    main_title: 'Creación de un informe de error',
    basic_info:
      'Al crear un ticket, es importante proporcionar toda la información necesaria para el procesamiento rápido y efectivo de su solicitud. Aquí hay una estructura de informe de muestra que puede ayudarle:',
    ticket_title: 'Título del Ticket:',
    short_description:
      "Descripción breve del problema (por ejemplo, 'Problemas con la creación de sesiones')",
    first_title: 'Descripción del Problema',
    detailed_description: 'Descripción detallada:',
    problem_description:
      'Describa la esencia del problema, qué está sucediendo exactamente y en qué consiste la falla.',
    reproduction_steps: 'Pasos para reproducir el problema:',
    reproduction_steps_description: 'Enumere los pasos que conducen al problema.',
    expected_result: 'Resultado esperado:',
    expected_result_description: '¿Qué esperaba recibir o ver?',
    actual_result: 'Resultado real:',
    actual_result_description: '¿Qué ocurrió en lugar del resultado esperado?',
    important_data: 'Datos importantes',
    links: 'Enlaces:',
    links_description: 'Proporcione enlaces a páginas problemáticas o documentos.',
    photos_and_screenshots: 'Fotos y capturas de pantalla:',
    photos_and_screenshots_description:
      'Adjunte imágenes que puedan ayudar a visualizar el problema.',
    session_numbers: 'Números de sesión:',
    session_numbers_description: 'Indique los números de sesión en los que ocurrió el problema.',
    additional_info: 'Información adicional:',
    additional_info_description: 'Incluya información adicional que pueda ser útil.',
    priority_and_urgency: 'Prioridad y urgencia:',
    priority_and_urgency_description:
      'Si el problema afecta significativamente su trabajo, menciónelo aquí, solicitando alta prioridad.',
    issue_title: 'Título',
    issue_title_placeholder: 'Ingrese una breve descripción del problema',
    description: 'Descripción',
    description_placeholder: 'Describa el problema con más detalle',
    files: 'Archivos',
    drag_and_drop: 'Arrastre y suelte el archivo aquí o seleccione',
    uploaded_files: 'Archivos subidos',
    create_button: 'Crear',
    problem_type: 'Tipo de problema',
    select_problem_type: 'Elige un tipo de problema',
    technical: 'Técnico',
    non_technical: 'No técnico',
    loading: 'Cargando...',
    main_page: 'Ir a la página principal',
    checkbox: 'Debe confirmar su acuerdo con todos los puntos.',
  },
  multistep: {
    basic_info: 'Datos Básicos',
    type: 'Tipo',
    summary: 'Resumen',
    details_rent: 'Detalles del alquiler',
    confirmations: 'Confirmaciones',
    open_google: 'Abrir en Google Maps',
  },
  check_in_view: {
    important: 'Importante',
    log_in_state_greet:
      'Inicia sesión en tu cuenta para realizar un seguimiento de tus bonificaciones y descuentos.',
    log_in: 'Iniciar sesión',
    download_app_greet: 'Descarga nuestra aplicación y obtén un bono',
    download: 'Descargar',
    welcome: 'Bienvenido',
    studio_info: 'Información del estudio',
    recommended_products: 'Productos recomendados',
  },
  toast: {
    certificate_success: 'Su certificado ha sido enviado a su correo electrónico',
  },
  tattoo_removal: {
    title: 'LÁSER / ACLARAMIENTO',
    registration_description:
      'Elija una fecha y hora conveniente para reservar en unos pocos clics',
    registration_button: 'Registro rápido para una sesión de aclaramiento',
    consultation_description: '¿Quiere aclarar detalles? ¡Con gusto le ayudaremos!',
    consultation_button: 'Consulta',
    header_nav_title: 'Aclaramiento de tatuajes',
    choose_service: 'Tipo de servicio',
    choose_service_placeholder: 'Seleccione el tipo de servicio',
    paste_tattoo: 'Adjunte una foto de eliminación / aclarado (opcional)',
    choose_size: 'Tamaño',
    choose_size_placeholder: 'Elige el tamaño',
  },
  success_form: {
    go_personal_acc: 'Ir a tu cuenta personal',
    dont_have_acc: '¿No tienes cuenta?',
    sign_up: '¡Regístrate!',
  },
  loyalty_card: {
    client_card: 'Tarjeta de cliente',
    card_number: 'Número de tarjeta',
    card_photo: 'Foto de la tarjeta',
  },
  discount: {
    first_session: 'descuento para la primera sesión',
    second_session: 'descuento para la segunda sesión',
  },
  days: {
    MON: 'LUN',
    TUE: 'MAR',
    WED: 'MIÉ',
    THU: 'JUE',
    FRI: 'VIE',
    SAT: 'SÁB',
    SUN: 'DOM',
  },
  calculate_price: {
    label: "Para conocer el precio",
    color_options: {
      black_and_white: "Blanco y negro",
      colored: "De color"
    },
    additional: "Adicional",
    select_location: "Seleccionar ubicación",
    select_style: "Seleccionar estilo",
    select_size: "Seleccionar tamaño",
    select_colored: "Color",
    select_parlor: "Seleccionar salón",
    select_service: "Seleccionar servicio",
    cost: "Costo",
    from: "Desde",
    to: "Hasta",
    book: "Reservar cita",
    no_info: "Sin información"
  }
};
