import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { MazPhoneNumberInput } from 'maz-ui/components';
import { Result } from 'maz-ui/components/MazPhoneNumberInput/types';
import useCountryCode from '@/composables/useCountryCode';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormKitPhone',
  props: {
  context: Object,
},
  setup(__props) {

const props = __props;

const countryCode = useCountryCode();
const handleInput = (e: Result) => {
  if (e.isValid) {
    props.context.node.input(e.e164);
  } else props.context.node.input('');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(MazPhoneNumberInput), {
    onUpdate: handleInput,
    class: "w-100",
    size: "sm",
    "show-code-on-list": "",
    ignoredCountries: ['RU'],
    "default-country-code": _unref(countryCode),
    placeholder: _ctx.$t('promotion.phone')
  }, null, 8, ["default-country-code", "placeholder"]))
}
}

})