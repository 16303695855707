import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "message-card-container" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = {
  key: 0,
  class: "h3 text-center"
}
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "mb-0" }

import { MazBtn, MazCard } from 'maz-ui/components';
import { TUrlString } from '@/types/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageCard',
  props: {
    title: {},
    message: {},
    icon: { default: 'info' },
    link: {},
    linkText: {},
    transparent: { type: Boolean, default: false }
  },
  emits: ['clickButton'],
  setup(__props: any) {

const iconMap = {
  success: 'bi-check-circle-fill',
  warning: 'bi-exclamation-circle-fill',
  info: 'bi-info-circle-fill',
  error: 'bi-x-circle-fill',
};





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(MazCard), {
      class: _normalizeClass(_ctx.transparent && '--transparent' )
    }, _createSlots({
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            class: _normalizeClass(["bi", iconMap[_ctx.icon]])
          }, null, 2),
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 2
    }, [
      (_ctx.message || _ctx.linkText)
        ? {
            name: "content",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.message), 1),
                (_ctx.linkText)
                  ? (_openBlock(), _createBlock(_unref(MazBtn), {
                      key: 0,
                      size: "sm",
                      to: _ctx.link,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickButton'))),
                      block: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.linkText), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["class"])
  ]))
}
}

})