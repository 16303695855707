<script setup lang="ts">
import { MazSelect } from 'maz-ui/components';
import { TUrlString } from '@/types/types';
import { ref, watch } from 'vue';
import { Size } from 'maz-ui/components/types';

const props = withDefaults(
  defineProps<{
    url?: string;
    blank?: boolean;
    native?: boolean;
    hideCurrentValue?: boolean;
    size?: Size
  }>(),
    {
      url: window.location.pathname,
      blank: false,
    },
);

const countries: {
  label: string;
  value: TUrlString;
}[] = [
  {
    label: 'Bulgaria',
    value: 'https://customer.vean-tattoo.bg',
  },
  {
    label: 'Canada',
    value: 'https://customer.vean-tattoo.ca',
  },
  {
    label: 'Czech Republic',
    value: 'https://customer.vean-tattoo.cz',
  },
  {
    label: 'Estonia',
    value: 'https://customer.vean-tattoo.ee',
  },
  {
    label: 'Germany',
    value: 'https://customer.vean-tattoo.de',
  },
  {
    label: 'Hungary',
    value: 'https://customer.vean-tattoo.hu',
  },
  {
    label: 'Latvia',
    value: 'https://customer.vean-tattoo.lv',
  },
  {
    label: 'Lithuania',
    value: 'https://customer.vean-tattoo.lt',
  },
  {
    label: 'Netherlands',
    value: 'https://customer.vean-tattoo.nl',
  },
  {
    label: 'Poland',
    value: 'https://customer.vean-tattoo.pl',
  },
  {
    label: 'Romania',
    value: 'https://customer.vean-tattoo.ro',
  },
  {
    label: 'Slovakia',
    value: 'https://customer.vean-tattoo.sk',
  },
  {
    label: 'Spain',
    value: 'https://customer.vean-tattoo.es',
  },
  {
    label: 'Ukraine',
    value: 'https://customer.vean-tattoo.com',
  },
  {
    label: 'United Kingdom',
    value: 'https://customer.vean-tattoo.uk',
  },
];

const selectedValue = ref<TUrlString | undefined>(
  props.hideCurrentValue ? undefined : window.location.origin,
);

watch(selectedValue, (value) => {
  const queryString = window.location.search;

  const updatedQueryString = queryString
    .split('&') // Разбиваем строку на параметры
    .filter((param) => !param.startsWith('ask_country=')) // Фильтруем, убирая параметр ask_country
    .join('&'); // Снова объединяем параметры
  window.open(value + props.url + updatedQueryString, props.blank ? '_blank' : '_self');
});
</script>

<template>
  <FormKit
    v-if="native"
    v-model="selectedValue"
    type="select"
    :options="countries"
    placeholder="Select country"
  />
  <MazSelect v-else label="Select country" :size="size ? size : 'md'" :options="countries" v-model="selectedValue" />
</template>

<style scoped lang="scss"></style>
