import { computed, onMounted, reactive } from 'vue';

export const useGclid = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const gclidField = reactive({ value: null });

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [key, value] = cookie.split('=');
      if (key === name) return value;
    }
    return null;
  };

  const saveGclid = () => {
    const gclid = searchParams.get('gclid');
    if (gclid) {
      setCookie('gclid', gclid, 30);
      gclidField.value = gclid;
    }
  };

  const bitrixGclidField = computed(() => {
    return gclidField.value ? { 'fields[UF_CRM_1737556934]': gclidField.value } : {};
  });

  onMounted(() => {
    const gclidFromCookies = getCookie('gclid');
    if (gclidFromCookies) {
      gclidField.value = gclidFromCookies;
    } else {
      saveGclid();
    }
  });

  return {
    saveGclid,
    gclidField,
    bitrixGclidField,
  };
};
