<template>
  <div>
    <Suspense>
      <template #fallback>
        <LoadingFullScreen/>
      </template>
      <template #default>
        <router-view/>
      </template>
    </Suspense>
    <AskSettingsModal />
    <MazDialog max-height="400px" v-model="isOpen" @close="closeModal">
      <template #title>
        {{ modalTitle }}
      </template>
      <template #default>
        <MessageCard transparent :icon="modalTitle.toLocaleLowerCase()" :title="modalTitle" :message="modalContent"/>
      </template>
    </MazDialog>
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import useAnalytic from '@/composables/useAnalytic';
import {
  useConfigurationStore,
  useStylesStore, useUserStore,
} from '@/store/root';
import { getDomainLocale } from '@/utils/url';
import LoadingFullScreen from '@/components/UI/LoadingFullScreen.vue';
import { useUTM } from '@/composables/useUTM';
import { useGclid } from '@/composables/useGclid';
import * as gtag from 'vue-gtag';
import { acceptDomains } from '@/plugins/gtag';
import { isOpen, modalTitle, modalContent, closeModal } from '@/store/modalState';
import { MazDialog } from "maz-ui/components";
import MessageCard from './components/MessageCard.vue';
import { useRoute } from 'vue-router';
import AskSettingsModal from '@/components/Layout/AskSettingsModal.vue';
import useFormActionsStore from '@/store/useFormActionsStore';

gtag.linker({domains: acceptDomains, accept_incoming: true})

const store = useStore();
const route = useRoute();
const formActionsStore = useFormActionsStore();
const siteLinksStore = useConfigurationStore();
const user = useUserStore()
const stylesStore = useStylesStore();
const {saveUTM} = useUTM()
saveUTM()
const {saveGclid} = useGclid()
saveGclid()

const updateParlorSocialMedia = async (currentRoute) => {
  if (currentRoute.query.parlor && currentRoute.query.parlor !== store.state.parlor_social_media.id) {
    await store.dispatch('fetchParlorSocialMedia', { parlorId: parseInt(currentRoute.query.parlor) });
  } else if (user.parlorId && user.parlorId !== store.state.parlor_social_media.id) {
    await store.dispatch('fetchParlorSocialMedia', { parlorId: parseInt(user.parlorId)});
  } else if (!user.parlorId && !currentRoute.query.parlor) {
    store.commit('setParlorSocialMedia', null)
  }
}

watch(route, async (currentRoute) => {
  updateParlorSocialMedia(currentRoute);
});

onMounted(async () => {
  store.commit('setIsLoading', true);
  useAnalytic([`gtm_${getDomainLocale()}`]);
  await user.startRefreshInterval()
  await siteLinksStore.fetch();
  await stylesStore.fetch();
  await formActionsStore.createFormActionTransition();
  await store.dispatch('fetchFindOuts');
  await store.dispatch('fetchSocialMedia');
  await store.dispatch('fetchVipGift');
  await store.dispatch('fetchSiteLinks');

  await updateParlorSocialMedia(route);
  store.commit('setIsLoading', false);
});

</script>

<style lang="scss">
* {
  font-family: "Gilroy-Bold", sans-serif !important;
}

:root {
  --bg-first: #070707;
  --bg-second: #0f0f0f;
  --bg-third: black;
  --color: white;
  --gray: rgba(51, 51, 51, 1);
  --primary: rgba(255, 5, 5, 1);
  --main-header-height: 70px;
  --back-button-size: 40px;
  --fullscreen: calc(100vh - var(--main-header-height))
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary) !important;
  box-shadow: unset !important;
}

.jumbotron {
  background: var(--bg-second);
  padding: 25px 0;

  img {
    cursor: pointer;
  }

  .h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 900;
  }
}

.footer {
  margin-top: auto;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  background: var(--bg-second);

  i {
    margin: 0 0.5rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    max-width: 100%;
  }

  a {
    font-size: 1.75rem;
  }
}

body {
  height: 100vh;
  color: var(--color);
  background: var(--bg-first);
}

#app,
html,
body {
  height: auto !important;
  min-height: 100vh;
  scroll-behavior: smooth;
}

div #app {
  display: flex;
  flex-direction: column;
}

.m-carousel__items {
  align-items: stretch !important;
}

@media (min-width: 992px) {
  .footer {
    p {
      border-right: 1px white solid;
      max-width: 50%;
      margin-bottom: 0;
      font-size: 1.5rem;
      padding: 0 1rem;
    }

    a {
      font-size: 2rem;
    }
  }
}

@media (max-width: 640px) {
  body {
    overflow: auto;
    height: unset;
  }
  .jumbotron {
    padding: 25px 0;

    & > img,
    & > span {
      margin: 0;
    }
  }
}

.p-lg-1 {
  @media (min-width: 992px) {
    padding: 1rem;
  }
}

::-webkit-scrollbar {
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}
</style>
