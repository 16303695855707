export default {
  veancoins: {
    summ: 'Bónuszok összege',
    customer_bonus: 'Ügyfélbónusz',
    vip_gift: 'VIP ajándék',
    vip_price: 'Minimális VIP összeg',
    cash_back_sells: 'Visszatérítés az eladások után',
    cash_back_certificate: 'Visszatérítés a tanúsítványra',
    cash_back_student: 'Visszatérítés a képzésre',
    cash_back_service: 'Visszatérítés a szolgáltatásra',
    cash_back_money: 'Visszatérítés az egyenleg feltöltésére',
  },
  support: {
    problem_situation_link: 'Problémám van az ülés után',
    other_problem_link: 'Más problémám van',
    problem_situation: {
      header: 'Probléma az ülés után',
      form: {
        session: 'Ülés',
        created_by_comment: 'Írja le a problémát',
      },
    },
    complain: {
      header: 'Panaszok és javaslatok',
      form: {
        name: 'Név',
        phone_number: 'Telefonszám',
        email: 'E-Mail',
        parlor: 'Szalon',
        description: 'Írja le a problémát',
        feedback: {
          overall_quality: 'Hogyan értékeli az általános szolgáltatási minőséget szalonunkban?',
          professional_skills: 'Elégedett volt a mester szakmai készségeivel?',
          staff_politeness: 'Mennyire volt udvarias és figyelmes a szalon személyzete?',
          waiting_time: 'Elégedett volt a szolgáltatás várakozási idejével?',
          staff_professionalism: 'Hogyan értékeli munkatársaink szakmaiságát?',
          salon_cleanliness: 'Hogyan értékeli a szalon tisztaságát és kényelmét?',
          service_value:
            'Úgy gondolja, hogy a szolgáltatások költségei megfelelnek a minőségüknek?',
          recommend: 'Ajánlaná szalonunkat barátainak és ismerőseinek?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'Mire való a hitel',
    type_education: 'Oktatás típusa',
    type_service: 'Szolgáltatás típusa',
    credit_type: 'Hitel típusa',
    how_to_contact: 'Hogyan lépjünk kapcsolatba Önnel',
    note: 'Megjegyzés',
  },
  items_view: {
    header: 'Termékek',
    filter_form: {
      name_placeholder: 'Név',
      category_placeholder: 'Kategória',
      parlor_placeholder: 'Szalon',
      all_categories: 'Összes kategória',
      all_parlors: 'Összes szalon',
    },
    load_more_button: 'Több betöltése',
    not_found_message: 'Nem található',
  },
  item_view: {
    error_message: 'Hiba az adatok betöltésekor',
    available_in_parlor: 'Elérhető a szalonban',
    not_available_in_parlor: 'Nem elérhető a szalonban',
    similar_items_label: 'Hasonló termékek',
    log_in_to_buy: 'Bejelentkezés a vásárláshoz',
  },
  item_simple_card: {
    studio_price_label: 'Stúdió ár',
    shop_price_label: 'Bolti ár',
  },
  student_candidate: {
    form: {
      who_studing: 'Diák vagy helybérlés',
      phone_number: 'Telefonszám',
      first_name: 'Keresztnév',
      last_name: 'Vezetéknév',
      social_link: 'Link bármely közösségi hálóra',
      parlors: 'Stúdió',
      couch: 'Tetováló szék',
      find_out: 'Honnan hallott rólunk?',
      start_date: 'Kívánt kezdési dátum',
      types: 'Üléstípusok',
      work_permit_document: 'Munkavállalási engedély',
      sanitary_book: 'Egészségügyi könyv',
      identity_proof: 'Személyazonosító igazolvány',
      work_photos: 'Munkafotók',
      end_date: 'Kívánt befejezési dátum',
      confirmations: 'Megerősítem, hogy',
      know_buy_material: 'Tudomásul veszem, hogy anyagokat vásárolhatok a stúdióban',
      customer_will_complete_agreement: 'Minden ügyfelem aláírja az ügyfélszerződést',
      can_show_documents: 'Szükség esetén eredeti dokumentumokat is tudok bemutatni a stúdióban',
      has_country_permission: 'Van tartózkodási engedélyem az országban',
      has_san_book: 'Egészségügyi könyvem van',
      has_passport: 'Van személyazonosító igazolványom (ID)',
      conditions: 'Megismertem a bérleti feltételeket',
    },
  },
  user: {
    referral_links: {
      booking: 'Ülések foglalása',
      student_candidate: 'Hely bérlése',
      store: 'Ügyfélbolt',
      candidates_form: 'Alkalmazott meghívása',
    },
  },
  products: {
    want_add: 'Szeretném hozzáadni a kiválasztott ápolási termékeket a kezelés költségéhez',
    no_warranty: '*e lehetőség nélkül nem tudjuk garantálni a 100%-os sikeres gyógyulást',
    button_add: 'Hozzáadás',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Felhasznált kellékek',
      name: 'Név',
      count: 'Mennyiség',
    },
  },
  booking: {
    more_interest: 'Még több érdekesség',
    active_promo: 'Aktuális promóciók',
    cashback: 'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          az <span class="text-success">online</span> foglalásra',
    fast_booking: 'Gyors foglalás',
    develop_sketch: 'Vázlat készítése',
    fast_booking_warning:
      'A foglalás nem végleges, menedzserünk felveszi Önnel a kapcsolatot az időpont és a dátum megerősítése érdekében',
    phone_annotate:
      'Annak érdekében, hogy értékesítési csapatunk gyorsabban tudjon válaszolni, kérjük, adja meg helyi kapcsolattartó számát. Köszönjük!',
    increased_risk: 'Figyelem! Magas kockázatú munkamenet',
    increased_risk_extended:
      'A kiválasztott ülés típusa és a test helye fokozott kockázatot jelent',
    approximate_price: 'Hozzávetőleges ár',
    price_warning: 'A kiegészítő termékek nem tartalmazzák',
    type: {
      info: 'Kényelmes űrlap a munkamenet foglalásához minden szükséges információval',
      booking_button: 'FOGLALÁS',
      booking_button_promo: '15% CASHBACKKEL VEAN COINS-BAN',
      unsure: 'Még mindig bizonytalan a választásban? Segítünk',
      consultant_help: 'Tanácsadóink szívesen segítenek',
      consultation_button: 'KONZULTÁCIÓ',
      simplified:
        'Foglaljon időpontot gyorsan és kényelmesen! Válassza ki a dátumot, időpontot és szolgáltatást néhány kattintással',
    },
    color: {
      black: 'Fekete',
      colored: 'Színes',
    },
    controls: {
      next: 'Tovább',
      back: 'Vissza',
      no_select: 'Nincs kiválasztva',
    },
    place_service: {
      select_studio: 'Stúdió kiválasztása',
      service: 'Szolgáltatás',
      size: 'Méret',
      location: 'Helyszín',
    },
    customer_info: {
      login_prompt: 'A folytatáshoz jelentkezzen be',
      login_button: 'Bejelentkezés',
      black_white_colored: 'Fekete-fehér vagy színes?',
      select_style: 'Stílus kiválasztása',
      tell_more:
        'Meséljen többet a tetoválás preferenciáiról és ossza meg a példákat (referenciákat)',
      login_promo: 'Bejelentkezéskor 10% kedvezmény',
      wish: 'Kívánság',
    },
    date_time: {
      choose_time: 'Látogatás időpontjának kiválasztása',
    },
    master_card: {
      master_unavailable: 'A mester ebben az időpontban nem érhető el',
      master_guest_warning: 'FIGYELEM! A mester vendég',
      rank: 'Rang:',
      works_count: 'Művek száma:',
      book_now: 'Foglalás most',
      select_another_time: 'Válasszon más időpontot',
    },
    success: {
      session: 'ÜLÉS',
      become_mystery_shopper: 'Légy rejtélyes vásárló',
      add_to_calendar: 'Esemény hozzáadása a naptárhoz',
      call_on_day: '*a szalon adminisztrátora a műsor napján hívja Önt',
      return_to_menu: 'Vissza a menühöz',
      recommended_products: 'Ajánlott termékek a gyógyuláshoz',
    },
    black_friday: '50% kedvezmény kötelező előleggel',
  },
  tips: {
    placeholder: 'Válassza ki, hogyan osztható meg a borravaló',
    divide_by_all: 'Egyenlően',
    administrator: 'Adminisztrátornak',
    parlor: 'Szalonhoz',
    master: 'Mesterhez',
  },
  new_landing: {
    count_block: {
      countries: 'Országok száma',
      studios: 'Stúdiók',
      awards: 'Díjak',
      masters: 'Mesterek',
    },
    header_banner: {
      label_1: 'Nem csinálunk elefántot a szúnyogból',
      label_2: 'tetoválásokat készítünk!',
      book_now: 'Foglalás most',
      consultation: 'Konzultáció',
      prices: 'Árak',
      top_works: 'Top munkák',
      advantages: 'Előnyök',
      change_parlor: 'Szalon változtatása',
    },
    course_card: {
      theory_part: 'Elméleti rész:',
      practice_part: 'Gyakorlati rész:',
      advance: 'További előnyök:',
    },
    helpful_links: {
      for_masters: 'Mestereknek',
      tattoo_news: 'Tetoválás hírek',
      tattoo_news_description: 'Légy naprakész a tetoválás világának legfontosabb eseményeivel',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Mestereink készen állnak tudásuk, titkaik, technikáik megosztására',
      rent_space: 'Hely bérlése',
      rent_space_description:
        'Tudj meg többet a munkahely bérlésének lehetőségeiről a VEAN stúdiókban',
      tattoo_promotions: 'Promóciók és promóciók',
      tattoo_certificates: 'Ajándék tanúsítványok',
      consumables: 'Fogyóeszközök',
      equipment: 'Felszerelés',
    },
    price_block: {
      title: 'Árak',
      loyalty_program: {
        title: 'Hűségprogram',
      },
      actions: {
        book_now: 'Foglalás most',
        calculate_cost: 'Költség kiszámítása',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Legjobb munkák',
      from_the_world: 'a világ minden tájáról',
    },
    why_vean: {
      title: 'Miért VEAN',
      individual_approach: 'Egyéni megközelítés',
      trust: 'Bizalom',
      safety_guarantee: 'Biztonsági garancia',
      convenient_location: 'Kényelmes elhelyezkedés',
      visited_studio: 'Ember látogatta meg ezt a stúdiót.',
    },
  },
  mystery_shopper: {
    title: 'Titkos vásárló',
    description:
      'A titkos vásárló egy olyan személy, aki egy üzlet működését ellenőrzi egy átlagos vásárló látszatával. Értékeli a szolgáltatás szintjét az üzletben.',
    offer:
      'Ha úgy döntesz, hogy titkos vásárló leszel ezen a szakaszon - ajándékba kapunk érméket.',
    instructionTitle: 'Útmutató',
    instructionDetails:
      'A szakasz során különböző szempontok szerint kell értékelnie az üzletet és a személyzetet. Emellett a szakasz után SMS-t fogsz kapni egy hivatkozással az űrlapra, amit be kell töltened (csak őszintén!)',
    question: 'Szeretnél titkos vásárló lenni?',
    yesButton: 'Igen',
    noButton: 'Nem, köszönöm',
    successMessage: 'Az üzletet az alábbi szempontok alapján kell értékelned:',
    criteria1: 'Telefonos és közösségi média konzultáció',
    criteria2: 'Konzultáció az üzletben érkezéskor',
    criteria3: 'Az üzlet árpolitikája',
    criteria4: 'Külső: Az üzlet homlokzata, feliratok, elhelyezkedés stb.',
    criteria5: 'Az üzlet belső tere, berendezés',
    criteria6: 'Termékek, szuvenírek és gyógyulás kínálata (kirakat)',
    criteria7: 'Hangulat: TV, zene, személyzet',
    criteria8: 'Kínált snackek, szórakozás, magazinok és katalógusok vázlatokkal',
    criteria9: 'Kommunikáció a mesterrel',
    criteria10: 'Kommunikáció az adminisztrátorral',
  },
  cookies: {
    text: `Sütiket használunk a böngészési élmény javítása, 
      személyre szabott reklámok vagy tartalmak megjelenítése 
      és forgalmunk elemzése érdekében. Az „Elfogadás” kattintásával 
      hozzájárul a sütik használatához.`,
    confirm: 'Elfogadás',
    more: 'További információ',
    manage: 'Kezelés',
    config: {
      necessary: 'Szükséges',
      preferences: 'Preferenciák',
      statistics: 'Statisztikai',
      marketing: 'Marketing',
    },
  },
  header_menu: {
    logout: 'Kilépés',
    main: 'Főoldal',
    about_us: 'Rólunk',
    discount_page: 'Akciók',
    loyalty_page: 'Hűségprogram',
    ai_page_link: 'Vázlat generálása',
    fonts_page_link: 'Tetoválás betűtípusok',
    quality_certificate: 'Minőségi tanúsítványok',
    complaints: 'Panaszok és javaslatok',
    confidence: 'Adatvédelmi irányelvek',
    public_offer_link: 'Nyilvános ajánlat',
    work: 'Szeretnék a VEAN-nál dolgozni',
    dockuments_page_link: 'Dokumentumok',
    login: 'Bejelentkezés',
    account: 'Személyes fiók',
    studying: 'Tanulni akarok!',
    buy_certificate: 'Vásároljon tanúsítványt',
    feedback: 'Visszajelzés',
    loan: 'Kölcsönt szeretnék!',
    services: 'Szolgáltatások',
    parlor_social_links: 'Szalon közösségi linkek és elérhetőségek',
    global_social_links: 'Globális közösségi linkek és elérhetőségek',
  },
  post: {
    welcome: 'Helló. Jártál nálunk egy szalonban',
    no_answer_questions: 'Nem válaszoltál a következő kérdésekre:',
    point: 'Kérjük, értékeld a szolgáltatás minőségét és szerezz VeanCoin-okat!',
    send: 'Küldés',
    error: 'Hiba történt!',
    not_found: 'A szalon nem található!',
    not_call: 'Nem szükséges visszahívás',
    thank: 'Köszönjük szépen az értékelésed!',
    bot: '21 nap múlva ismeretlen számról fog hívni egy botunk, hogy megerősítse a szolgáltatásról és gyógyulásról szóló információkat.',
    certificate: 'Akciós ajándékozási kupon generálódott számodra %{gift_price} VeanCoin összeggel',
    social_networks: 'Kérjük, hagyj véleményt rólunk a közösségi médiában:',
    month: 'Használd fel egy hónapon belül, ne felejtsd el emlékeztetni rá az adminisztrátort!',
    dissatisfied: 'Nem vagy elégedett a szolgáltatással? Azonnal feldolgozzuk a kérésed.',
    help_us_improve:
      'Segítsen nekünk jobbnak lenni, válaszoljon a kérdésekre és kapjon <span class="text-warning">{bonusAmount}</span> VEANCOINS!',
    scores: {
      variants: {
        great: 'Kiváló',
        good: 'Jó',
        satisfactorily: 'Megfelelő',
        bad: 'Rossz',
        fast: 'Gyors',
        orally: 'Szóbeli',
        in_writing: 'Írásbeli',
        orally_in_writing: 'Szóbeli és írásbeli',
        yes: 'Igen',
        no: 'Nem',
        didnt_listen: 'Nem hallottam',
      },
      administrator:
        'Hogyan értékeli a stúdió adminisztrátorának konzultációját a szolgáltatásokkal, mesterekkel és gondozással kapcsolatban?',
      recommendations: 'Kapott-e egyértelmű gondozási ajánlásokat?',
      master_contact: 'Megadta Önnek a mester az elérhetőségeit?',
      holl: 'Hogyan értékeled a várakozás kényelmét a recepción (környezet, tisztaság, kényelem)?',
      price: 'Hogyan értékeled a szolgáltatások árát a szalonban?',
      callback: 'Milyen gyorsan kaptál visszajelzést a foglaláskor?',
      master: 'Hogyan értékeled a mestert a szalonban?',
      care: 'Kínáltak neked professzionális ápolószereket vagy ékszereket?',
      buy_other: 'Vásároltál ékszert vagy ápolószereket?',
      need_contact: 'Szükséged van rá, hogy kapcsolatba lépjünk veled?',
      loyalty_program: 'Rész vesz a hűségprogramban?',
      sketch: 'Felajánlották, hogy rendeljek egy egyedi vázlatot/projektet:',
      souvenir: 'Felajánlották, hogy vásároljak szuvenír termékeket vagy márkás árucikkeket:',
      repeat_record: 'Szeretnél ismét bejelentkezni?',
    },
    scores_mystery_shopper: {
      instagram: 'Az INSTAGRAM értékelése',
      site: 'A weboldal értékelése: vean-tattoo.%{locale}',
      phone_consultation: 'Telefonos tanácsadás és közösségi hálózatok',
      consultation: 'Személyes tanácsadás a szalonban',
      price: 'A szalon árpolitikájának véleménye',
      exterior: 'Külső: A szalon homlokzata, táblák, helyszín stb.',
      interior: 'A szalon belső tere, berendezés',
      assortment: 'Ajándék és gyógyulási termékek választéka (vitriína)',
      atmosphere: 'Hangulat: TV, zene, csapat',
      leisure: 'Snackek, szórakozás, magazinok és vázlatkatalógusok',
      master: 'Kommunikáció a mesterekkel',
      admin: 'Kommunikáció az adminisztrátorral',
      master_one_place: 'Csak ebben a szalonban dolgozik a mester? (igen/nem)',
      master_contact: 'Kértél személyes kapcsolatfelvételt a mestertől? (igen/nem)',
      care: 'Kínáltak gyógyulási és ajándéktárgyakat? Kaptál kis ajándékot (mágnes, matrica vagy 10%-os kedvezménykupon a következő szolgáltatásra)? (igen/nem)',
      conclusion: 'A vizsgálat végső értékelése',
    },
    inputs: {
      cost: 'Add meg azt az összeget, amit a szolgáltatásért fizettél',
      cost_other: 'Mekkora összeget költöttél ékszerekre vagy ápolószerekre?',
      review: 'Van javaslatod a szalon fejlesztésére?',
      photo: 'A szalon fényképe',
    },
    inputs_mystery_shopper: {
      recommendation: 'Ajánlásaid számunkra',
      why_vean: 'Miért választottad a VEAN-t?',
    },
  },
  header: {
    online_appointment: 'Online foglalás',
    post_session: 'Minőség ellenőrzés',
    get_price: 'Konzultáció',
    consultation: '(Konzultáció)',
    order_consultation: 'Konzultációt rendelni',
  },
  index: {
    parlor: 'Stúdió',
    master: 'Mester',
    session_type: 'A szolgáltatás típusa',
    session_location: 'Elhelyezés ',
    datetime: 'Dátum és idő',
    create_appointment: 'Regisztráció ',
    bonus: '* %{customer_bonus} VeanCoins BÓNUSZ ÖNFELVÉTELLEl',
    sketch: 'Minta (nem kötelező)',
  },
  session_types: {
    tattoo: 'Tetoválás',
    tatouage: 'Sminktetoválás',
    piercing: 'Piercing',
    stroke: 'Eltávolítás',
    training: 'Képzés',
    training_tattoo: 'Tetováló képzés',
    training_piercing: 'Piercing képzés',
    training_tattouage: 'Sminktetováló képzés',
    training_udder: 'Eltávolítási képzés',
  },
  price_form: {
    client_name: 'Név',
    client_phone: 'Telefon ',
    client_comm: 'Hely',
    sketch_desc: 'Vázlat hozzáadása (opcionális)',
    sketch_button: 'Válasszon ki egy fájlt',
    send: 'Küld',
    or: 'Vagy',
    country: 'Ország',
    city: 'Város',
    comment: 'Megjegyzés',
    val_error: 'Ellenőrizze a megadott adatokat!',
    ths_message: 'Köszönöm!  Hamarosan felvesszük Önnel a kapcsolatot',
    go_back: 'Visszatérés',
    file_size: 'A fájl mérete nem haladhatja meg az 5 MB-ot!',
  },
  selectors: {
    parlor: {
      placeholder: 'Keresés a szalon neve szerint ',
    },
    master: {
      placeholder: 'Keresés a név, szolgáltatás szerint ',
    },
    select_type: {
      select: 'Választ',
      next: 'Tovább',
      no_price: 'Megbeszélhető',
      ad: 'Tól/től',
      da: 'Ig',
      selected: 'selected',
      info: 'Az ár a mérettől és a helyszíntől függhet',
    },
    datetime: {
      select_date: 'Válassza ki a megfelelő dátumot ',
      no_active_times: 'Erre a dátumra már nincs szabad időpont! Kérem, válasszon más dátumot',
    },
  },
  verify: {
    verify_account: 'Fiók hitelesítése',
    type: 'Ellenőrzési módszer',
    check_code: 'Kód ellenőrzése',
    status: 'Állapot',
    input_phone: 'Adja meg a telefonszámát ',
    email_help: 'Az e-mailt is használhatja az ellenőrzéshez',
    sms_sent: 'SMS-ben elküldtük Önnek a kódot, írja be alább',
    resend_sms_after: 'Az újraküldés %{seconds} másodpercen belül elérhető',
    resend_sms: 'Küldd újra',
    buttons: {
      back: 'Vissza ',
      next: 'Tovább ',
      create_appointment: 'Beiratkozás',
    },
  },
  success: {
    h1: 'Köszönöm!  Az időpont sikeresen létrehozva!',
    h2: 'Miután a regisztrációt az adminisztrátor jóváhagyta, SMS-t küldünk Önnek',
    mystery: 'Legyen titkos vásárló',
  },
  about: {
    header: 'Információ Önről ',
    birth_info:
      'Megadva a születési dátumát, mi biztosak lehetünk abban, hogy Ön betöltötte a megengedett életkort, illetve ez szükséges a születésnapi kedvezményhez :)',
    inputs: {
      name: 'Kereszt-és vezetéknév',
      find_out: 'Honnan szereztél tudomást rólunk',
      birth_date: 'Születési dátum',
    },
    buttons: {
      skip: 'Kihagyni',
      back: 'Vissza',
      next: 'Tovább',
    },
  },
  footer: {
    header: 'Iratkozz fel közösségi oldalainkra, hogy légy naprakész az összes hírrel',
    site_navigation: 'Navigáció',
    interest: 'Érdekességek',
    other: 'Egyéb',
  },
  subscribe_modal: {
    header: 'Iratkozz fel a hírekért, cserébe bónuszt kapsz',
    submit: 'Küldés',
    wrong_data: 'Ellenőrizze a megadott adatok helyességét!',
    wrong_email: 'Helytelenül megadott e-mail cím!',
    thanks: 'Köszönjük a feliratkozást!',
    error: 'Hiba történt!',
  },
  chat_bot: {
    call: 'Telefonálni',
    online_record: ' Online időpont egyeztetés ',
    online_consultation: ' Online konzultáció ',
    need_help: ' Segítségre van szüksége egy vázlathoz?',
    order_sketch: 'Vázlat rendelése',
    dress_sketch: 'Vázlat alkalmazása',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problémás helyzetek',
      id: 'Azonosító',
      status: 'Státusz',
    },
    reset_password: {
      title: 'Elfelejtette jelszavát',
      submit: 'küldés',
      forgot_password: 'Elfelejtette jelszavát',
      phone: 'Adja meg a telefonszámot',
      success_message: 'Ideiglenes jelszót küldtünk az e-mailjére/telefonjára',
    },
    registration: {
      name: 'Név',
      surname: 'Vezetéknév',
      registered: 'Regisztráció',
      congratulations: 'Gratulálunk!',
      successfully_registered: 'Sikeresen regisztráltál!',
      log_in: 'Bejelentkezés',
    },
    options: {
      no_interest_in_promotions: 'Nem érdekelnek a promóciók és bónuszok',
      dislike_frequent_sms: 'Nem szeretem a gyakori SMS-üzeneteket',
      already_have_tattoo: 'Már van tetoválásom, és nincs tervezem, hogy hamarosan újat készítsek',
      other: 'Egyéb',
    },
    login_page: {
      log_in_page: 'Az ügyfélfiókba való belépés',
      number_missing: 'Nincs száma?',
      register: 'Regisztráljon!',
      steps: {
        sign_up: 'Iratkozzon fel a hűségprogramra vagy JELENTKEZZEN BE',
        check_coins: 'Ellenőrizze VeanCoin-jait a hűségprogramra való regisztrációval',
        get_coins: 'Szerezze meg VeanCoin-jait minden egyes foglalkozás végén',
        accumulate: 'Gyűjtse és halmozza fel VeanCoin-jait profiljában',
        book_session: 'Foglaljon időpontot, és jöjjön el Vean-hoz, hogy megkapja a tetoválását',
        exchange_coins: 'Cserélje VeanCoin-jait, és kapjon kedvezményeket',
      },
    },
    find_customer: {
      continue: 'Folytatás',
      not_found: 'Ügyfél nem található!',
      select_type: 'Válassza ki a bejelentkezési típust',
      no_password: 'Nincs beállítva jelszó!',
      found: 'Talált!',
      check: 'Ellenőrzés...',
      invalid_phone_or_password: 'Érvénytelen telefonszám vagy jelszó',
      error_login: 'Hiba történt a bejelentkezés során',
      phone: 'Telefon',
      temporary_password_message: 'Ideiglenes jelszót használ',
      permanent_password_message: 'Ön állandó jelszót használ',
    },
    product_card: {
      availability: 'Elérhetőség stúdiókban:',
      unavailable: 'Nem elérhető',
    },
    store_body: {
      full_points_products: 'VeanConokkal megvásárolható termékek',
      half_points_products: '50% kedvezménnyel megvásárolható termékek',
      no_products: 'Nincsenek termékek',
    },
    description_block: {
      loyalty_system: 'VEAN hűségrendszer',
      show_more: 'Többet mutatni',
    },
    profile_card: {
      my_sketches: 'Az én vázlataim',
      share_link: 'Ossza meg ezt a linket',
      tips: 'Tippek',
      send_certificate: 'Tanúsítvány küldése',
      vip: 'VIP',
      not_vip: 'Légy VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Pénz',
      active_certificates: 'Aktivált érmék',
      balance: 'Egyenleg',
      sessions: 'Foglalkozások',
      verify_account_status: 'Fiók állapota',
      security_percent: 'Biztonság:',
      points_alltime: 'Összes idő alatt szerzett érmék',
      available_certificates: 'Elérhető tanúsítványok',
      certificates: 'Pénzügyi tanúsítványok',
      bought_certificates: 'Aktivált VeanCoins',
      certificate_number: 'Tanúsítvány száma',
      available_for: 'Érvényes:',
      no_active_certificates: 'Nincs pénzigazolás',
      no_active_bought_certificates: 'Nincsenek aktív VeanCoins',
      coming_sessions: 'Közelgő foglalkozások',
      complete_agreement: 'Töltse ki a megállapodást',
      date: 'Dátum:',
      you_was: 'Ön részt vett %{sessions} foglalkozáson',
      edit: 'Szerkesztés',
      not_email: 'a tanúsítvány elküldéséhez ki kell töltenie egy e-mailt',
      exist_application: 'van egy alkalmazás',
      need_exist_application: 'nincs alkalmazás',
      need_confirm_account: 'Szükséges a fiók megerősítése',
      verified: 'Megerősítve',
      choose_payment_method: 'Fizetési módszer kiválasztása',
      parlor: 'Szalon',
      sign_up_online: 'Jelentkezzen be egy foglalkozásra',
      mystery_shopper: 'Titkos vásárló',
      sign_up_secret_buyer: 'Jelentkezz be hozzánk egy ülésre, és légy titkos vásárló',
      pay: 'Fizet',
      prepayment: 'Előleg',
      questionnaire: 'Kérdőív',
      agreement: 'Megállapodás',
      fill_required_information: 'Szükséges kitölteni az információkat',
      buy: 'Töltse fel egyenlegét',
      archive: 'Archívum',
      support_service: 'Ügyfélszolgálat',
      balance_buy_history: 'Vásárolt pontok VeanCoins',
      balance_history: 'Szerzett pontok VeanCoins',
      buy_history: 'Vásárlási előzmények',
      сost: 'Költség',
      payment_link: 'Fizetési hivatkozás',
      attributes: 'Attribútumok',
      invite_friend: 'Barát meghívása',
      phone_or_email: 'Adja meg a telefonszámot vagy az e-mail címet',
      report_problem: 'Probléma bejelentése',
      note: 'Megjegyzés megadása',
      tooltip_coins:
        'aktiválnia kell a megszerzett érméit a választott szolgáltatásért vagy termékek vásárlásáért, hogy elkerülje azok elvesztését egy éven belül',
      tooltip_certificate:
        'megvásárolt vagy ajándékba kapott pénzügyi tanúsítványok száma, amelyeket két hónapon belül fel lehet használni',
      tooltip_сonfirmed:
        'A szerkesztés során változtassa meg a «ФИО или username»-t «Név és Vezetéknév»-re',
      tooltip_missing:
        'Adjon hozzá információt magáról (név és vezetéknév, születési dátum, közösségi média linkjei, honnan tudott rólunk, telefonszám és e-mail azonosító megerősítéséhez)',
      tooltip_verified:
        'Meg kell erősítenie a fiókját telefonon vagy e-mailben, hogy megvédje a megszerzett és felhalmozott VeanCoins-ait.',
      tooltip_vip:
        'A VIP ügyfél olyan státusz, amelyet minden olyan ügyfél kaphat, aki 1500 eurónál többet költött szolgáltatásokra. A VIP státusz bizonyos előnyöket biztosít tulajdonosának, például: gyors prioritásos bejelentkezés a foglalkozásokra soron kívül, hozzáférés egyedi termékekhez és szolgáltatásokhoz, a VIP státusz elérésekor a ügyfél azonnal 1000 érmét kap a fiókjára',
      earn_veancoins: 'Keress veancoins-t',
    },
    profile_edit: {
      additional_information: 'További információk a VeanCoins-ról',
      parlors: 'Szalon (ami közelebb vagy a fő)',
      client_card: 'Ügyfélkártya',
      full_name: 'Teljes név vagy felhasználónév',
      birth_date: 'Születési dátum (köszöntéshez)',
      social_media_link: 'Közösségi média link',
      find_out: 'Honnan tudta meg',
      or: 'vagy',
      phone_placeholder: 'Telefon',
      save: 'Mentés',
      password_tooltip:
        'Hozzon létre jelszót, hogy a jövőben hozzáférhessen profiljához SMS és e-mail segítség nélkül. Ezzel meg is védi személyes adatait.',
      password: 'Jelszó',
      repeat_password: 'Jelszó újra',
      email_taken: 'Ez az e-mail cím már foglalt!',
      phone_taken: 'Ez a telefonszám már foglalt!',
      simple_password: 'Túl egyszerű jelszó!',
      invalid_password: 'Érvénytelen jelszó!',
      password_for_application: 'Alkalmazáshoz szükséges jelszó (nem kötelező)',
      congratulate: 'Gratulálunk!',
      successfully_registered: 'Sikeresen regisztrált!',
      need_confirm_account: 'Szükséges a fiók megerősítése',
      yes: 'Igen',
      no: 'Nem',
      phone_confirmed: 'Telefon megerősítve',
      email_confirmed: 'Email megerősítve',
      change_password: 'Jelszó megváltoztatása',
      old_password: 'Régi jelszó',
      new_password: 'Új jelszó',
      confirm_password: 'Ismételje meg a jelszót',
      invalid_phone_or_password: 'Érvénytelen telefonszám vagy jelszó',
      error_login: 'Hiba történt a bejelentkezés során',
      years_old: 'Életkor',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Nem',
      man: 'Férfi',
      woman: 'Nő',
      gender_hidden: 'Inkább nem nyilatkozom',
      language: 'Nyelv',
      preferred_style_status: 'Preferált stílusok',
      classic: 'Klasszikus / Realizmus',
      freak: 'Freak / Trash / Anime',
      fashion: 'Divat / Művészet / Minimalizmus',
      rock: 'Rock / Hard',
      pop: 'Pop / Masa',
      accommodation_status: 'Szálláshely státusz',
      local: 'Helyi',
      tourist: 'Turista',
      guest: 'Vendég',
      lifestyle_status: 'Életmód',
      extreme: 'Extrém',
      dance: 'Tánc',
      anime: 'Anime',
      game: 'Játék',
      music: 'Zene',
      lgbt: 'LGBT',
      art: 'Művészet / Modern',
      family: 'Család / Szerelem',
      beauty: 'Szépség',
      sexuality: 'Szikszualitás',
      masculinity: 'Maszkulin',
      tattoo_count_status: 'Tetoválások száma',
      pierce_count_status: 'Piercingek száma',
      one: 'Egy',
      two: 'Kettő vagy több',
      inc: 'Nagyon sok',
      change_information: 'Információk mentése',
    },
    verification: {
      sms: 'Megerősítés SMS-ben',
      email: 'Megerősítés Email-ben',
      google: 'Ellenőrzés Google segítségével',
      send_code: 'Kód küldése',
      code: 'Ellenőrző kód',
    },
    loyalty: {
      submit_button: 'Küldés',
      bonus_instruction:
        'Végezzen el egyet az alább felsorolt ​​műveletek közül, és adjon hozzá bizonyítékokat (képernyőképek, fényképek stb.), Majd szerezze meg a bónuszt',
      bonus_amount: 'VeanCoins!',
      review_option_1: 'Hagyjon részletes véleményt a mester profiljában a webhelyen',
      review_option_2: 'Értékelje a stúdiót vagy a mestert a webhelyen',
      review_option_3: 'Értékelje a stúdiót és hagyjon véleményt a térképen',
      review_option_4: 'Ossza meg a stúdiónkat a közösségi médiában',
      return:
        'Ha a küldött fotó nem tartozik egyik kategóriába sem, amelyekért bónuszokat adunk, akkor a kapott bónuszokat törlik, amikor egy ajándékutalványt vásárolnak.',
      evidence_label: 'Bizonyítékok',
    },
    buy_certificate: {
      payment_type_title: 'Vásárlás típusa',
      payment_type_subtitle: 'Mit szeretne tenni',
      buy_balance: 'Egyenleg feltöltése',
      buy_certificate: 'Tanúsítvány vásárlása',
      amount_title: 'Összeg',
      amount_subtitle: 'Adja meg az összeget',
      payment_method_title: 'Fizetési mód',
      payment_method_subtitle: 'Válasszon fizetési módot',
      payment_title: 'Fizetés',
      forming_payment: 'Fizetés előkészítése...',
      pay: 'Fizetni',
      continue: 'Folytatni',
      back: 'Vissza',
      submit_to_email: 'Tanúsítvány elküldése e-mailben',
      get_vean_card: 'Szerezd meg a VEAN kártyát a stúdióban',
      vean_card_description:
        'A VEAN CARD az Ön kártyája, amelyen a VEAN TATTOO tanúsítványok automatikusan aktiválódnak, biztosítva a biztonságot és a kényelmet. Minden ajándéka és kedvezménye mindig kéznél van, és a kártya használata a lehető legegyszerűbb és legelőnyösebb. Szerezze be kártyáját a VEAN TATTOO stúdiókban, és fedezze fel az összes előnyt még ma!',
      total_balance: 'Egyensúly',
      certificate: 'Tanúsítvány',
      customer_balance: 'Ügyfél egyenlege',
      certificate_type: 'Tanúsítvány típusa',
      promotion_certificate: 'Akciós tanúsítvány',
      buy_for_myself: 'Vásárolj magamnak',
      promotion_certificate_message:
        'A fizikai tanúsítványokat 2024. december 31-ig adják ki. 2025. január 1-jétől a tanúsítványok pontokká alakítva jelennek meg a felhasználó személyes fiókjában.',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Több mint 160 szalon',
      clients_count: 'Több mint 1 500 000 ügyfél',
      largest_company: 'A világ legnagyobb tetováló stúdió hálózata',
    },
    master_block: {
      our_masters: 'Mestereink',
    },
    master_card: {
      works_count: 'Művek száma:',
    },
    no_admin_block: {
      leisure: 'Szabadidő',
      session_record: 'Regisztráljon egy alkalmazásra',
      about_company: 'A cégről',
      care: 'Gondoskodás',
      quality_certificates: 'Minőségi tanúsítványok',
      public_buyout_offer: 'Nyilvános ajánlat',
      complaints_offers: 'Panaszok és javaslatok',
      loyalty_system: 'Védi a hűségrendszer',
      discount_page: 'Kedvezmények',
    },
    peculiarities_block: {
      about_studio: 'Erről a stúdióról',
      clients: 'Ügyfelek',
      sessions: 'Munkamenetek',
      studio_hours: 'Munkaórák:',
      studio_phone: 'A stúdió száma:',
      wifi: 'Wi-fi:',
      password: 'Jelszó:',
      from_to: 'kezdve %{time_start} és %{time_end} végéig',
    },
    photos_block: {
      photos: 'Szalon fotó',
    },
    top_works: 'Munkák',
    care_important: 'A piercingnél és a tetoválásnál a legfontosabb a gyógyítás és az ápolás.',
    cabinet: 'Személyes szekrény',
    complete_agreemnent: 'Teljes megállapodás',
    is_blanket: 'Van egy takaró, amellyel fel lehet melegedni',
    is_water: 'Vannak forró italok',
    is_hot_drinks: 'Vannak édességek',
    is_treats: 'Vannak hideg italok',
  },
  promotion: {
    submit: 'Küldés',
    warning: 'Figyelem! Ellenőrizze az adatok helyességét!',
    name: 'Név / Becenév',
    required: 'Ez a mező kötelező!',
    max_value: 'Nem lehet több, mint 100 karakter!',
    phone: 'Telefon',
    social_link: 'Link a közösségi médiahoz',
    wrong_format: 'Rossz formátum!',
    terms: 'Elolvastam és elfogadom az adatvédelmi irányelveket',
    know_bonus: 'Tudom, hogy bónusz pontokat kapok',
    error: 'Hiba történt',
    thanks: 'Köszönöm!',
    bonus: 'Önnek jóváírva %{gift_price} pont',
    do_not_disturb: 'Ne zavarjanak (VeanTattoo SMS és hívások kikapcsolása)',
    get_promo: 'Szeretnék tájékoztatást kapni a kedvezményekről és akciókról',
    first_time_veancoins_notification:
      'A VeanCoins első használatához lépjen kapcsolatba a stúdió adminisztrátorával. A VeanCoins és a Pénz különböző szabályok szerint használható',
  },
  another_studio_drawler: {
    another: 'Más stúdiók',
  },
  landing: {
    best_studio: 'A legjobb tetováló stúdió a városodban',
    vean_wide: 'VEAN TATTOO HÁLÓZAT',
    studio_count: 'Több mint 160 tetováló stúdió',
    quality: 'Minőségi és biztonsági garancia',
    get_price: 'MEGTUDNI AZ ÁRAT',
    advantage: 'Előnyök',
    contact_us: 'Свяжитесь с нами',
    top_works: 'Legjobb munkák',
    prices: 'Árak', //долджен быиь
    studio_photo: 'Fotó a Stúdióról',
    prices_services: 'Árak és szolgáltatások',
    about: 'Rólunk',
    loyalty_system: 'Hűségprogram', // lennie kell
    why_vean: 'Miért a VEAN',
    visit_count: 'ember látogatta meg ezt a stúdiót',
    description:
      'A VeAn Tattoo tetováló stúdiók hálózata a mai napon a legnagyobb a világon: több mint 160 szalonunk van 14 európai országban. Ezt a rést egy teljesen új, magasabb színvonalra emeltük. A hálózat specializációja meglehetősen széles, de a fő irány a tetoválás és piercing ipar. A VeAn Tattoo társaság alapelvei: Szakértelem és hozzáférhetőség: Arra törekszünk, hogy minden ügyfél jól érezze magát a folyamat minden szakaszában, így szakembereink készséggel válaszolnak minden kérdésére online. Hírnév és ügyfélközpontúság: Minden ügyfélnek lehetősége nyílik egyedi műalkotás létrehozására a bőrén. Nagyra értékeljük az idejét, és arra törekszünk, hogy jobb hangulattal és hihetetlen tetoválással hagyja el stúdiónkat. Törődünk hírnevünkkel, és nagyra értékeljük a hálózat minden ügyfelét. Érték és elérhetőség: Ideális ár-érték arányt kínálunk. Szolgáltatásaink széles közönség számára elérhetőek, ugyanakkor nem áldozunk a minőségről. Tanúsított és minőségi berendezések: Anyagaink megfelelnek a legmagasabb biztonsági és minőségi előírásoknak. Garantáljuk, hogy ügyfeleink csak a legjobbat kapják. Magasan képzett szakemberek és széleskörű szolgáltatások: A VeAn Tattoo stúdiók kizárólag minősített mestereket alkalmaznak, akiket világszerte megbecsülnek és tisztelnek. Minden létező tetoválási stílust és technikát kínálunk, ingyenes egyedi vázlat kidolgozását az Ön igényei szerint. Arra is felkérjük Önt, hogy iratkozzon fel a közösségi hálózatok összes hivatalos oldalára. Ez lehetővé teszi, hogy tájékozódjon a különleges ajánlatokról, promóciókról, versenyekről, művészek munkáiról, valamint a VeAn Tattoo legfrissebb híreiről és frissítéseiről.',
    advantages: {},
    our_masters: 'Mestereink', //doljen bit
    from: 'Tól',
    no_price: 'Megbeszélhető',
    form: {
      get_price_consultation: 'Tudja meg az árat / Konzultáció',
      name: 'Név',
      phone: 'Telefon',
      place: 'Az Ön helye',
      subscribe_news: 'Iratkozzon fel a hírlevélre bónuszért',
    },
  },
  form_messages: {
    submit: 'Küldés',
    warning: 'Figyelem! Ellenőrizze a megadott adatok helyességét!',
    required: 'Ez kötelezően kitöltendő mező!',
    length: 'Nem több mint %{length} betű!',
    file_length: 'Nem több mint %{length} fájl!',
    wrong_format: 'Hibás formátum!',
    error: 'Hiba történt',
    thanks: 'Köszönöm!',
    required_label: 'Kötelező',
    not_required_label: 'Nem kötelező',
  },
  training: {
    training: 'KÉPZÉS',
    tattoo_school: 'TETOVÁLÓISKOLA',
    biggest_school: 'a világ legnagyobb tetoválószalon hálózata',
    ne_otkladivaj: 'Ne halaszd el<br/>magadat későbbre',
    learn: 'Tanulj meg tetoválni<br/>és válj top mesterré',
    reviews: 'Végzettek véleményei',
    record: 'Jelentkezés',
    video_text: 'Hogyan segíthetsz,<br/>mit tanítasz?',
    ded: {
      consultation: 'konzultáció',
      get_more: 'Tudj meg több információt',
    },
    header_bar: {
      main: 'Főoldal',
      get: 'Amit kapsz',
      program: 'A tanfolyam programja',
      about: 'Rólunk',
      reviews: 'Vélemények',
    },
    our_course: {
      head: 'Tanfolyamaink<br/>',
      description_1: 'ez az a hely, ahol mindig választ kapsz a kérdéseidre és új dolgokat tanulsz',
      description_2: 'És még ha nincs is művészeti háttéred - az eredmény nem fog váratni magára',
      label: '[ nálunk meleg, otthonos és izgalmas ]',
      description_3:
        'Szeretjük diákjainkat és mindent megteszünk azért, hogy a tanulási folyamat a lehető legkényelmesebb legyen',
    },
    banner: {
      we_teach: 'Megtanítunk Tetoválni',
      good_make: 'JÓL TETOVÁLUNK',
      our_masters: 'MESTEREINK KÉSZEN ÁLLNAK A TUDÁS, TITKOK, TECHNIKÁK MEGOSZTÁSÁRA',
      learn_too: 'És téged is megtanítunk',
    },
    modal: {
      thanks: 'Köszönjük!',
      error: 'Hiba történt!',
      complete: 'Töltsd ki az űrlapot, és felhívunk',
      full_name: 'Teljes név',
      phone: 'Telefonszám',
      submit: 'Küldés',
      cancel: 'Mégse',
    },
    paragraph: {
      header_1: 'Biztosan megtanítunk',
      content_1: 'Nem gyártósoros képzést végzünk',
      header_2: 'rajzolni tudni',
      content_2: 'Mindent megtanítunk/ne aggódj, ha nincs művészeti végzettséged, az sem probléma',
      header_3: 'Oklevél',
      content_3:
        'A tanfolyamok befejeztével nemzetközi szabványú oklevelet kapsz. Jóváhagyta a Global Tattoo ACC',
      header_4: 'Elhelyezkedés',
      content_4:
        'Komolyan? Igen, a legjobb végzettek csapatunk részévé válhatnak és Európa bármely pontján dolgozhatnak',
      header_5: 'Szerződés',
      content_5: 'Nem "becsületszóra" dolgozunk. Minden hivatalos és szerződéssel szabályozott',
      no_sleep: {
        header: 'ne aludj el',
        rect_1: 'Hogyan?',
        rect_2: 'ÉLET',
        main_label: 'Kezdd el a tanulást most',
        label_1: '1000+ végzett',
        label_2: '100% befejezte a képzést',
        label_3: '80% elhelyezkedett',
        label_4: '300+ partner a tetoválóiparban',
      },
    },
    principies: {
      head: 'Garanciák',
      label_1: '1. Kultúra',
      label_2: '2. Minőség',
      label_3: '3. Módszerek',
      heading: 'Minden elveken alapul',
      description_1:
        '11 évvel ezelőtt úgy döntöttünk, hogy fejlesztjük a tetoválóipar kultúráját, nem úgy, mint mindenki más, hanem a saját utunkon - tudásra és tapasztalatra alapozva',
      description_2:
        'Ma már 1000+ kiváló tetoválóművészt képeztünk ki. 80% a csapatunk részévé vált. Biztosan állíthatjuk, hogy bárki megtanulhatja a tetoválás művészetét',
      description_3:
        'Mindent a használt módszerünk határoz meg, amelyet folyamatosan fejlesztünk a csapatunkkal, művészeinkkel és végzettjeinkkel együtt, akik rendszeresen adnak visszajelzést',
    },
    packages: {
      head: 'Tanfolyami programok',
      from: 'tól',
      plus: 'Előnyök',
    },
    facilities: {
      head: 'Lehetőségek és előnyök',
      subhead: 'Válaszd a tanfolyamainkat és érezd a tetoválásipar varázsát',
      card: {
        head_1: 'Minden végzett tanulónk nemzetközileg elfogadott oklevelet kap',
        label_1: '- a lehetőségek világának a jegyed',
        head_2: 'Ajánlás és támogatás egy profi mestercsoporttól',
        label_2: 'a te sikered a mi büszkeségünk!',
        head_3: 'Tetováló közösség, szerezz tanácsokat és támogatást szakértőinktől',
        label_3: 'Az új életed a tetoválás világában most kezdődik!',
      },
    },
    review: {
      head_1: 'Mestereink munkái',
      head_2: 'Vélemények',
      comment_1:
        'A tetoválóképzés ebben az iskolában minden várakozásomat felülmúlta! A tanárok a maguk területén profik, minden képzési szakaszt alaposan átbeszélünk, a hangulat nagyszerű. Most már magabiztosan valósíthatom meg kreatív ötleteimet a vásárlók bőrén!',
      comment_2:
        'Köszönöm az iskolának a minőségi tetoválóképzést! Kezdetben kicsit ijesztő volt belekezdeni, de a figyelmes és türelmes tanároknak köszönhetően biztosnak éreztem magam. Most már munkáim elismerést és csodálatot kapnak!',
      comment_3:
        'Hihetetlen utazás volt a tetoválás világába! Az iskola minden szükséges tudást és készséget megad, hogy valódi profivá válj. Hálás vagyok a támogatásért és inspirációért, amit itt kaptam',
    },
  },
  questionary: {
    first: {
      heading: 'ENGEDÉLY A VEAN TATTOO STÚDIÓBAN TÖRTÉNŐ ELJÁRÁSHOZ',
      textbox:
        'Ha az ügyfél 18 év alatti, a hozzájárulást szülők vagy törvényes gondnokok kell kitölteniük (Brit Columbia-ban, Kanadában, aki 19 éves kor alatt van, azt kiskorúnak tekintik. Ha Ön 19 év alatti, kérjük, válassza a MÁSODIK opció)',
      has_18: 'IGEN, 18 éves vagyok vagy idősebb',
      no_18: 'NEM, 18 évesnél fiatalabb vagyok',
    },
    second: {
      warning: 'Figyelem! Az ülés napján gondnokokkal kell érkezni!',
      name: 'Teljes név',
      birthdate: 'Születési dátum',
      address: 'Lakcím',
      email: 'Email',
      sex: 'Nem',
      heading: 'ELJÁRÁS MEGVALÓSÍTÁSA',
      man: 'Férfi',
      woman: 'Nő',
      other: 'Nincs megadva',
      parent_data: 'Az egyik szülő adatai:',
      child_data: 'Egy KISKORÚ adatai:',
      child_field_placeholder: 'Fia, lánya, stb.',
      child_field_label: 'Ki számára végzik a eljárást',
      confirm:
        'Én, mint egy kiskorú törvényes képviselője, hozzájárulok ahhoz, hogy az alábbi eljárást gyermekem végezze.',
    },
    third: {
      heading: 'MINDEN, AMIT TUDNOD KELL A GYÓGYULÁSI IDŐSZAKRÓL:',
    },
    fourth: {
      nothing: 'Egyik sem',
      has_diseases: 'Van valamilyen betegsége?',
      has_contraindications: 'Van valamilyen ellenjavallata?',
      heading1:
        'HA BÁRMILYEN ALÁBBI BETEGSÉGE VAN, TUDNUNK KELL, KÉRJÜK JELEZZE A MEGFELELŐ PONTOKAT:',
      heading2: 'HA ELLENJAVALLATOK VANNYAK, KÉRJÜK, SZÁLLÍTSON MEGFELELŐ INFORMÁCIÓKAT:',
    },
    fifth: {
      heading: 'TILOS AZ ELJÁRÁS UTÁN:',
    },
    sixth: {
      heading: 'HONNAN HALLOTTÁL RÓLUNK:',
      label:
        '21 nap után az egyik munkatársunk felveheti Önnel a kapcsolatot a nyújtott szolgáltatások minőségellenőrzése keretében. Ezenkívül a session után SMS értesítést kap, és lehetséges, hogy automatizált robotunk telefonálni fog. Személyes fiókjában lemondhat a telefonos és SMS értesítési szolgáltatásokról, és 21 nap elteltével törölheti minden adatát és fényképét a szolgáltatás időpontjától.',
    },
    seventh: {
      heading: 'ÁLLÍTÁS ÉS ENGEDÉLY',
    },
    signature: {
      heading:
        'Alulírott (%{name}) ezennel beleegyezem a mai eljárásba való részvételemmel\n' +
        '%{session_type} a testemen és elfogadom a jelen dokumentumban foglalt feltételeket.',
    },
    next: 'Tovább',
    submit: 'Beküldés',
    no_responsibility: 'A stúdió nem vállal felelősséget ezért az ülésért',
    acquaint: 'Elolvastam az általam kapott információkat',
    acquaint_2:
      'Megértem, hogy a Vean Tattoo nem vállal felelősséget a gyógyulási folyamatért.\n' +
      'A fent említett tetoválás vagy piercing javítása esetén a javítás költsége az eredeti szolgáltatás árának 30%-a lesz.\n' +
      'Ha nem elégedett a javítás eredményével, ebben az esetben a szolgáltatásért fizetett pénz nem visszatérítendő, mivel a fent említett területek nem kedvezőek a szolgáltatás elvégzésére.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Nincs megoldás',
      in_check: 'Ellenőrzés alatt',
      problem_solved: 'Probléma megoldva',
      wait: 'Várakozás',
      small: 'Kisebb helyzet',
      unanswered_check: 'Válasz nélküli ellenőrzés',
    },
  },
  master_page: {
    card: {
      active: 'Aktív',
      not_active: 'Nem aktív',
      master_since: 'Mester VEAN óta',
      services: 'Szolgáltatások',
      styles: 'Stílusok',
      book: 'Foglalás',
    },
    information: {
      top_works: 'Legjobb munkák',
      awards_and_diplomas: 'Díjak és diplomák',
    },
  },
  stripe_warning:
    'Figyelem! A stripe használatával történő fizetés minimális összege 20 €. Ha kisebb összegre van szükség, kérjük, lépjen kapcsolatba a stúdióval a részletekért',
  check_in: {
    rate: {
      upload_photo: 'Töltsön fel egy képet a munkájáról',
      upload_photo_desc:
        'Készíthet egy szép képet a munkájáról a közösségi médiánkhoz és weboldalunkhoz. Az adminisztrátor is készít egy képet a minőségellenőrzéshez és az elvégzett munka garantálásához. Bármikor dönthet úgy, hogy nem engedélyezi a kép közzétételét és megosztását, és az adatai a gyógyulás után teljesen törlésre kerülnek.',
      bad_comment: 'Kérjük, magyarázza el, miért adott alacsony értékelést',
      review: 'Hagyjon véleményt!',
      review_desc: 'Nagyon hálásak lennénk, ha véleményt hagyna. Fontos számunkra az Ön véleménye!',
      cashback: 'x2 visszatérítés az alkalmazásban található fiókjára',
      send_invoice: 'Számla küldése e-mailre',
      influencer: 'Van több mint 2000 követője a közösségi médiában?',
    },
    welcome_message: 'KELLEMES ÜLÉST KÍVÁNUNK!',
    session_started: 'AZ ÜLÉS ELKEZDŐDÖTT',
    next_steps: 'Az ülés befejezése után további lépések lesznek elérhetők az Ön számára',
    buy_healing_product: 'AJÁNLJUK EGY GYÓGYÍTÓ TERMÉK MEGVÁSÁRLÁSÁT',
    skip: 'Kihagy',
    leave_review: 'Kérjük, hagyjon értékelést',
    your_impression: 'Mik az általános benyomásaid?',
    add_photo: 'Adjon hozzá egy fényképet a munkáról, hogy további 1 Vean Coin-t kapjon',
    send: 'Küld',
    payment_success: 'A fizetés sikeres volt',
    get_invoice: 'Szeretne számlát kapni?',
    invite_friend: 'Meghívni egy barátot',
    recommend_us: 'Ajánlja minket barátainak, és keressen együtt velük',
    thank_employees: 'Javasoljuk továbbá, hogy köszönjük meg alkalmazottainknak',
    you_can_tip:
      'Kedves Nikita, meg is köszönhetjük alkalmazottainknak egy meghatározott összegű borravalóval',
    own_amount: 'Saját összeg',
    who_to_tip: 'Kinek kell borravalót adni?',
    payment_type: 'Fizetési típus',
    continue: 'Folytatás',
    summary: 'Összegzés',
    healing_agent: 'Gyógyító szer',
    tips: 'Borravaló',
    session_payment: 'Ülés fizetése',
    total: 'Összesen',
    waiting: 'Várakozás...',
    available_funds: 'Elérhető alapok',
    refill_balance: 'Egyenleg feltöltése',
    go_to_payment: 'Tovább a fizetéshez',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Borravaló',
    prepayment: 'Előleg',
    have_questions: 'Van kérdése?',
    title: 'Ülések',
    selectStatus: 'Válasszon állapotot',
    selectParlor: 'Válassza ki a szalont',
    loading: 'Betöltés...',
    fromDate: 'Dátumtól',
    toDate: 'Dátumig',
    search: 'Keresés',
    all: 'Összes',
    status: {
      100: 'Függőben',
      200: 'Új',
      300: 'Folyamatban',
      400: 'Befejezett',
      500: 'Törölve',
      600: 'Fizetésre vár',
    },
    details: 'A session részletei',
  },
  master: {
    rank: 'Rang',
    works_count: 'Munkák száma',
    address: 'Cím',
  },
  modalPhone: {
    title: 'Figyelem!',
    info: 'Tisztelt Ügyfelünk! Biztonsága és szolgáltatásunk hatékony használata érdekében minden adat megadása kötelező. Ez segít abban, hogy időben reagáljunk a fontos kérdésekre és biztosítsuk védelmét.',
    reminder: 'Emlékeztess később',
    go_to_settings: 'Menj a beállításokhoz',
    roughly_price: 'A hozzávetőleges költség a megadott adatok pontosságától függ',
    success_message: 'Köszönjük!',
  },
  studyingRegistration: {
    become_student: 'Legyél diák',
    step_1: 'Általános adatok',
    step_2: 'Személyes adatok',
    step_3: 'Tanulmányi adatok',
    city: 'Város',
    city_placeholder: 'Írd be a városod',
    name: 'Keresztnév',
    name_placeholder: 'Írd be a keresztneved',
    last_name: 'Vezetéknév',
    last_name_placeholder: 'Írd be a vezetékneved',
    date_of_birth: 'Születési dátum',
    date_of_birth_placeholder: 'Írd be a születési dátumod',
    phone: 'Telefonszám',
    email: 'E-mail (nem kötelező)',
    email_placeholder: 'Írd be az e-mail címed',
    social_media: 'Közösségi média',
    social_media_placeholder: 'Link a profilodhoz',
    referral_source: 'Hogyan hallottál rólunk?',
    referral_source_placeholder: 'Válaszd ki a forrást',
    study_type: 'Tanulmányi típus',
    study_type_placeholder: 'Válaszd ki a tanulmányi típust',
    start_date: 'Becsült kezdési dátum',
    start_date_help: 'Írd be a becsült kezdési dátumot',
    hours_number: 'Órák száma',
    practice_number: 'Gyakorlatok száma',
    details: 'Részletek',
    education_price: 'Képzési díj',
    moto: 'Több évtizeden át bevált oktatási módszer',
    description: 'Leírás',
    material: 'Anyag',
  },
  studying_main: {
    become_student: 'Legyen diák!',
    consultation: 'Konzultáció',
    want_to_study: 'Tanulni akarok!',
    consult_with_our_advisor: 'Nem tudod, honnan kezdj? Lépj kapcsolatba tanácsadónkkal!',
  },
  errorTicket: {
    error_description: 'Jelentse a probléma az alkalmazásban, és gyorsan javítjuk',
    error_button: 'Jelentse az alkalmazás hibás működését',
    problem_situation_description: 'Probléma merült fel a szekció után? Tudassa velünk',
    problem_session_button: 'Jelentse a problémát a munkamenet után',
    problem_session: 'Probléma jelentése a munkamenet után',
    sidebar_title: 'Probléma bejelentése',
    main_title: 'Hibabejelentés létrehozása',
    basic_info:
      'A hibaüzenet létrehozásakor fontos, hogy minden szükséges információt megadjon a kérés gyors és hatékony feldolgozásához. Íme egy minta jelentés felépítése, amely segíthet:',
    ticket_title: 'Hibaüzenet címe:',
    short_description: "A probléma rövid leírása (pl. 'Problémák a munkamenetek létrehozásával')",
    first_title: 'A probléma leírása',
    detailed_description: 'Részletes leírás:',
    problem_description:
      'Írja le a probléma lényegét, hogy mi történik pontosan, és hogy miben áll a hiba.',
    reproduction_steps: 'A probléma reprodukálásának lépései:',
    reproduction_steps_description:
      'Sorolja fel azokat a lépéseket, amelyek a problémához vezetnek.',
    expected_result: 'Várt eredmény:',
    expected_result_description: 'Mit várt, hogy kap vagy lát?',
    actual_result: 'Valós eredmény:',
    actual_result_description: 'Mi történt a várt eredmény helyett?',
    important_data: 'Fontos adatok',
    links: 'Hivatkozások:',
    links_description: 'Adjon meg hivatkozásokat a problémás oldalakra vagy dokumentumokra.',
    photos_and_screenshots: 'Fényképek és képernyőképek:',
    photos_and_screenshots_description:
      'Csatolja azokat a képeket, amelyek segíthetnek a probléma vizualizálásában.',
    session_numbers: 'Munkamenet számok:',
    session_numbers_description:
      'Adja meg azokat a munkamenet számokat, amelyek során a probléma felmerült.',
    additional_info: 'További információ:',
    additional_info_description: 'Adjon meg minden egyéb információt, amely hasznos lehet.',
    priority_and_urgency: 'Prioritás és sürgősség:',
    priority_and_urgency_description:
      'Ha a probléma jelentősen befolyásolja munkáját, kérjük, jelezze itt, és kérjen magas prioritást.',
    issue_title: 'Cím',
    issue_title_placeholder: 'Írjon be egy rövid leírást a problémáról',
    description: 'Leírás',
    description_placeholder: 'Részletesebben írja le a problémát',
    files: 'Fájlok',
    drag_and_drop: 'Húzza ide a fájlt, vagy válassza ki',
    uploaded_files: 'Feltöltött fájlok',
    create_button: 'Létrehozás',
    problem_type: 'Probléma típusa',
    select_problem_type: 'Válasszon problématípust',
    technical: 'Műszaki',
    non_technical: 'Nem műszaki',
    loading: 'Betöltés...',
    main_page: 'Menj a főoldalra',
    checkbox: 'Megerősítenie kell az összes ponttal való egyetértését.',
  },
  multistep: {
    basic_info: 'Alapadatok',
    type: 'Típus',
    summary: 'Összegzés',
    details_rent: 'Bérleti részletek',
    confirmations: 'Megerősítések',
    open_google: 'Megnyitás a Google Térképen',
  },
  check_in_view: {
    important: 'Fontos',
    log_in_state_greet: 'Jelentkezzen be fiókjába, hogy nyomon kövesse bónuszait és kedvezményeit.',
    log_in: 'Bejelentkezés',
    download_app_greet: 'Töltse le alkalmazásunkat, és szerezzen bónuszt',
    download: 'Letöltés',
    welcome: 'Üdvözöljük',
    studio_info: 'Stúdió információk',
    recommended_products: 'Ajánlott termékek',
  },
  toast: {
    certificate_success: 'Az Ön tanúsítványa el lett küldve az e-mail címére',
  },
  tattoo_removal: {
    title: 'LÉZER / VILÁGOSÍTÁS',
    registration_description: 'Válasszon egy megfelelő dátumot és időpontot néhány kattintással',
    registration_button: 'Gyors regisztráció világosító kezelésre',
    consultation_description: 'Szeretne tisztázni részleteket? Örömmel segítünk!',
    consultation_button: 'Konzultáció',
    header_nav_title: 'Tetoválás világosítása',
    choose_service: 'Szolgáltatás típusa',
    choose_service_placeholder: 'Válassza ki a szolgáltatás típusát',
    paste_tattoo: 'Csatolja az eltávolítás / világosítás fényképét (nem kötelező)',
    choose_size: 'Méret',
    choose_size_placeholder: 'Válassza ki a méretet',
  },
  success_form: {
    go_personal_acc: 'Menj a személyes fiókodba',
    dont_have_acc: 'Nincs fiókod?',
    sign_up: 'Regisztrálj!',
  },
  loyalty_card: {
    client_card: 'Klienskártya',
    card_number: 'Kártyaszám',
    card_photo: 'Kártya fényképe',
  },
  discount: {
    first_session: 'kedvezmény az első ülésre',
    second_session: 'kedvezmény a második ülésre',
  },
  days: {
    MON: 'H',
    TUE: 'K',
    WED: 'SZE',
    THU: 'CS',
    FRI: 'P',
    SAT: 'SZO',
    SUN: 'V',
  },
  calculate_price: {
    label: "Az ár megismeréséhez",
    color_options: {
      black_and_white: "Fekete-fehér",
      colored: "Színes"
    },
    additional: "További",
    select_location: "Helyszín kiválasztása",
    select_style: "Stílus kiválasztása",
    select_size: "Méret kiválasztása",
    select_colored: "Szín",
    select_parlor: "Szalon kiválasztása",
    select_service: "Szolgáltatás kiválasztása",
    cost: "Költség",
    from: "Tól",
    to: "Ig",
    book: "Időpont foglalása",
    no_info: "Nincs információ"
  }
};
