import { defineStore } from 'pinia';
import { apolloClientCustomer } from '@/plugins/apollo';
import useGQLQuery from '@/composables/useGQLQuery';
import { computed, ref, watch } from 'vue';
import { getAddr, getImageUrl, getProtocol } from '@/utils/url';
import { getTranslatedField } from '@/utils/gqlHelper';
import dayjs from 'dayjs';
import axios from '@/plugins/axios';
import { getTokens } from '@/apiService';
import { useI18n } from 'vue-i18n';
import { jwtDecode } from 'jwt-decode';
import { useLazyQuery, useQuery } from '@vue/apollo-composable';
import { textToBase64 } from '@/utils/toBase64';
import { CustomerLocalStorage } from '@/utils/localStorageConstants';
import { customerApi } from '@/api/customer';
import createOptions from '@/utils/createOptions';
import useErrorHandler from '@/composables/useErrorHandler';
import { scrollTop } from '@/utils/scroll';
import useBonusStore from './useBonusStore';
import { useUTM } from '@/composables/useUTM';
import { useGclid } from '@/composables/useGclid';

const useParlorsStore = defineStore('parlors', () => {
  const { PARLORS } = useGQLQuery();

  const { loading, result } = useQuery(PARLORS, {
    where: {
      site_show_parlor: { _eq: true },
    },
  });

  const parlorsOptions = computed(() =>
    createOptions(result.value?.parlors, 'name', 'id', { useTranslatedField: true, sorted: true }),
  );

  return {
    loading,
    parlorsOptions,
  };
});
const useParlorStore = defineStore('parlor', () => {
  const { LANDING_PARLOR_BY_ID } = useGQLQuery();

  const id = ref(null);
  const name = ref('');
  const topWorks = ref([]);
  const rawMasters = ref([]);
  const totalClients = ref(0);
  const maps = ref('');

  const masters = computed(() => {
    if (rawMasters.value.length)
      return rawMasters.value.map((el) => ({
        id: el.id,
        parlorId: el.profile.parlor.id,
        avatar: getImageUrl(el.profile.avatar),
        name: el.first_name + ' ' + el.last_name,
        rang: getTranslatedField(el.profile.masterrank, 'name'),
        rating: Number(el.profile.mark),
        worksCount: el.sessions_aggregate.aggregate.count,
        sessionTypes: el.session_types.map((value) => ({
          id: value.session_type.id,
          name: getTranslatedField(value.session_type, 'name'),
        })),
      }));
    else return [];
  });

  const fetch = async (parlorId) => {
    const { data } = await apolloClientCustomer.query({
      query: LANDING_PARLOR_BY_ID,
      variables: {
        parlorId,
      },
    });

    id.value = data.parlor.id || null;
    name.value = getTranslatedField(data.parlor, 'name');
    maps.value = data.parlor.maps || '';
    topWorks.value = data.top_works || [];
    rawMasters.value = data.masters || [];
    totalClients.value = data.parlor.customers_aggregate.aggregate.count || 0;
  };

  return {
    fetch,
    id,
    name,
    topWorks,
    masters,
    totalClients,
    maps,
  };
});

const useConfigurationStore = defineStore('configuration', () => {
  const { CONFIGURATIONS } = useGQLQuery();
  const links = ref(null);
  const currency = ref(null);
  const valueconfiguration = ref();

  const currencyUC = computed(() => '');
  // const currencyUC = computed(() => (currency.value ? String(currency.value).toUpperCase() : null));
  const fetch = async () => {
    const { data } = await apolloClientCustomer.query({
      query: CONFIGURATIONS,
    });
    links.value = data?.core_siteparlorpage_by_pk || null;
    currency.value = data?.liqpayconfig?.currency || null;
    valueconfiguration.value = data?.valueconfiguration || null;
  };

  return {
    links,
    currency,
    currencyUC,
    valueconfiguration,
    fetch,
  };
});

const useFindOutsStore = defineStore('findOuts', () => {
  const { CUSTOMERS_FINDOUT } = useGQLQuery();
  const { result, loading } = useQuery(CUSTOMERS_FINDOUT);

  const findouts = computed(() => result.value?.findouts || []);

  const options = computed(() => {
    return findouts.value.map((el) => ({
      value: el.id,
      label: getTranslatedField(el, 'name'),
    }));
  });

  return {
    options,
    result,
    loading,
  };
});

const useStylesStore = defineStore('styles', () => {
  const { STYLES } = useGQLQuery();
  const styles = ref([]);
  const stylesOptions = computed(
    () =>
      styles.value.length &&
      styles.value.map((el) => ({
        label: getTranslatedField(el, 'name'),
        value: el.id,
      })),
  );

  const stylesOptionsNames = computed(
    () =>
      stylesOptions.value?.length &&
      stylesOptions.value.map((el) => ({
        label: el.label,
        value: el.label,
      })),
  );
  const fetch = async () => {
    const { data } = await apolloClientCustomer.query({
      query: STYLES,
    });
    styles.value = data?.styles || [];
  };

  return {
    styles,
    stylesOptions,
    stylesOptionsNames,
    fetch,
  };
});

const useUserStore = defineStore('user', () => {
  const { t } = useI18n();

  const userId = ref(Number(localStorage.getItem('userId')) || null);
  const accessToken = ref(localStorage.getItem('accessToken') || null);
  const refreshToken = ref(localStorage.getItem('refreshToken') || null);
  const authErrors = ref(null);
  const sessions = ref([]);

  const isAuthenticated = computed(() => !!userId.value);
  const sessionsInWork = computed(() =>
    sessions.value.filter((s) => s.sessionstatus.status === 300),
  );

  const { CUSTOMER_BY_ID } = useGQLQuery();
  const { result, load } = useLazyQuery(CUSTOMER_BY_ID, { id: userId });

  const userInfo = computed(() => result.value?.customer);

  const parlorId = computed(() => userInfo.value?.parlor_id);
  const language = computed(() => userInfo.value?.language);
  const b64userId = computed(() => textToBase64(userId.value));
  const balance = computed(() =>
    userInfo.value?.last_balance.length ? userInfo.value?.last_balance[0].balance : 0,
  );
  const balanceBuy = computed(() =>
    userInfo.value?.last_balance_buy.length ? userInfo.value?.last_balance_buy[0].balance : 0,
  );

  const login = async (phoneNumber, password) => {
    try {
      authErrors.value = null;
      const tokens = await getTokens(phoneNumber, password);

      if (!tokens) {
        throw new Error(t('client_page.find_customer.invalid_phone_or_password'));
      }

      const { id } = jwtDecode(tokens.access);

      localStorage.setItem('accessToken', tokens.access);
      localStorage.setItem('refreshToken', tokens.refresh);
      localStorage.setItem('userId', id.toString());

      accessToken.value = tokens.access;
      refreshToken.value = tokens.refresh;
      userId.value = id;
    } catch (error) {
      console.error('Login error:', error);
      authErrors.value = error || t('client_page.find_customer.error_login');
      throw new Error(error);
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');

    userId.value = null;
    accessToken.value = null;
    refreshToken.value = null;

    delete axios.defaults.headers.common['Authorization'];

    window.location.reload();
  };

  const refreshTokens = async (refreshToken) => {
    try {
      const { access } = await customerApi.customer.refreshAccessToken({ refresh: refreshToken });
      localStorage.setItem(CustomerLocalStorage.accessToken, access);
    } catch {
      logout();
    }
  };

  const startRefreshInterval = async () => {
    const refreshToken = localStorage.getItem(CustomerLocalStorage.refreshToken);
    if (refreshToken) {
      await refreshTokens(refreshToken);
      setInterval(() => refreshTokens(refreshToken), 1000 * 60 * 5);
    }
  };

  watch(
    userId,
    () => {
      if (userId.value) {
        load(null, { id: userId.value });
      }
    },
    { immediate: true },
  );

  return {
    accessToken,
    refreshTokens,
    startRefreshInterval,
    authErrors,
    b64userId,
    isAuthenticated,
    language,
    login,
    logout,
    refreshToken,
    sessions,
    sessionsInWork,
    userId,
    userInfo,
    parlorId,
    balance,
    balanceBuy,
  };
});
const useActionsLogStore = defineStore('actionsLogStore', () => {
  const logId = ref(undefined);

  const createLog = async (data) => {
    try {
      const response = await axios.post(
        `${getProtocol()}://${getAddr()}/api/v1/customer-actions/`,
        data,
      );
      logId.value = response?.data?.id;
      return response?.data?.id;
    } catch (e) {
      console.error(e);
    }
  };
  const patchLog = async (id, data) => {
    try {
      if (id)
        await axios.patch(`${getProtocol()}://${getAddr()}/api/v1/customer-actions/${id}/`, data);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteLog = async (id = logId.value) => {
    try {
      if (id) {
        await axios.delete(`${getProtocol()}://${getAddr()}/api/v1/customer-actions/${id}/`);
        logId.value;
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    createLog,
    deleteLog,
    logId,
    patchLog,
  };
});

const useBookingStore = defineStore('booking', () => {
  const stepsCount = 4;

  const { utmFields } = useUTM();
  const { gclidField } = useGclid();

  const { LANDING_PARLOR_BY_ID } = useGQLQuery();
  const { t } = useI18n();
  const { handleAxiosError } = useErrorHandler();

  const parlorStore = useParlorsStore();
  const userStore = useUserStore();
  const bonusStore = useBonusStore();

  const sessionId = ref(null);
  const master = ref(null);
  const isMystery = ref(false);
  const phoneNumber = ref(null);
  const usedPromo = ref(null);

  const step = ref(1);
  const loading = ref(false);
  const bookedFrom = ref(null);
  const referralUser = ref(null);
  const vcPaymentAmount = ref(null);

  const createdSession = ref(null);

  const parlor = ref(null);

  const sessionTypes = ref([]);

  const sessionType = ref(null);
  const sessionTypeLocations = ref([]);

  const sessionTypeLocation = ref(null);

  const size = ref(null);

  const colored = ref(null);

  const style = ref(null);

  const customerComments = ref(null);

  const date = ref(null);
  const currentMonth = ref(new Date().getMonth());
  const currentYear = ref(new Date().getFullYear());
  const time = ref(null);

  const rawMastersData = ref(null);

  const formattedDate = computed(() =>
    date.value ? dayjs(date.value).format('YYYY-MM-DD') : null,
  );

  const formattedTime = computed(() => (time.value ? time.value.slice(0, 5) : null));

  const selectedParlorInformation = computed(
    () => parlorStore.parlorsOptions.find((option) => option.value === parlor.value) || null,
  );

  const selectedTypeInformation = computed(
    () => sessionTypes.value?.find((el) => el.id === sessionType.value) || null,
  );

  const selectedLocationInformation = computed(
    () =>
      selectedTypeInformation.value?.locations?.find((el) => el.id === sessionTypeLocation.value) ||
      null,
  );

  const selectedMasterInformation = computed(
    () => availableMasters.value?.find((el) => el.id === master.value) || null,
  );

  const isIncreasedRisk = computed(
    () =>
      selectedTypeInformation.value?.increased_risk ||
      selectedLocationInformation.value?.increased_risk,
  );

  const sessionTypesOptions = computed(() =>
    sessionTypes.value.map((el) => ({
      label: getTranslatedField(el, 'name'),
      value: el.id,
    })),
  );

  const sessionTypeLocationsOptions = computed(() => {
    const currentType = sessionTypes.value?.find((type) => type.id === sessionType.value);
    return (
      currentType?.locations.map((location) => ({
        label: getTranslatedField(location, 'name'),
        value: location.id,
      })) || []
    );
  });

  const sizesOptions = computed(() => {
    const currentType = sessionTypes.value.find((type) => type.id === sessionType.value);
    return (
      currentType?.sizes.map((el) => ({
        label: `~${el.size}`,
        value: el.id,
      })) || []
    );
  });

  const colorsOptions = computed(() => [
    {
      label: t('booking.color.black'),
      value: false,
    },
    {
      label: t('booking.color.colored'),
      value: true,
    },
  ]);

  const rawMastersDataFiltered = computed(() => {
    if (!sessionType.value) {
      // Если sessionType не указан, возвращаем все данные как есть
      return rawMastersData.value;
    }

    // Если sessionType указан, фильтруем мастеров по наличию нужного типа сессии
    return Object.fromEntries(
      Object.entries(rawMastersData.value).map(([date, masters]) => [
        date,
        masters.filter((master) => master.session_types.includes(sessionType.value)),
      ]),
    );
  });

  const stylesOptions = computed(() =>
    selectedTypeInformation.value?.styles.map((el) => ({
      label: getTranslatedField(el.style, 'name'),
      value: el.style.id,
    })),
  );

  const availableDates = computed(() => {
    if (!rawMastersDataFiltered.value) return [];

    const datesSet = new Set();
    Object.keys(rawMastersDataFiltered.value).forEach((key) => {
      const masters = rawMastersDataFiltered.value[key];
      for (let i = 0; i < masters.length; i++) {
        if (masters[i].free_time && masters[i].free_time.length) {
          datesSet.add(new Date(currentYear.value, currentMonth.value, Number(key)));
          break;
        }
      }
    });

    // Преобразуем Set в массив
    return Array.from(datesSet);
  });

  const isSuccessBooking = computed(() => !!sessionId.value);

  const availableTimes = computed(() => {
    if (!date.value) return null;

    const selectedDay = date.value.getDate();
    if (!rawMastersDataFiltered.value[selectedDay]) return [];

    const timesSet = new Set();

    rawMastersDataFiltered.value[selectedDay].forEach((master) => {
      master.free_time.forEach((time) => {
        timesSet.add(time);
      });
    });

    return Array.from(timesSet)
      .sort()
      .map((time) => ({
        label: time.slice(0, 5),
        value: time,
      }));
  });

  const availableMasters = computed(() => {
    const dateKey = date.value ? new Date(date.value).getDate().toString() : null;
    const masters = rawMastersDataFiltered.value[dateKey] || [];

    return masters
      .map((master) => {
        const isAvailable = dateKey && (!style.value || master.styles.includes(style.value)) && (!time.value || master.free_time.includes(time.value));
        return {
          ...master,
          unavailable: !isAvailable,
        };
      })
      .sort((a, b) => {
        return a.unavailable - b.unavailable;
      });
  });

  const $reset = () => {
    master.value = null;
    step.value = 1;
    parlor.value = null;
    sessionTypes.value = [];
    sessionType.value = null;
    sessionTypeLocations.value = [];
    sessionTypeLocation.value = null;
    bookedFrom.value = null;
    referralUser.value = null;
    size.value = null;
    colored.value = false;
    style.value = null;
    customerComments.value = null;
    date.value = null;
    currentMonth.value = new Date().getMonth();
    currentYear.value = new Date().getFullYear();
    time.value = null;
    rawMastersData.value = null;
    sessionId.value = null;
    phoneNumber.value = null;
    createdSession.value = null;
    vcPaymentAmount.value = null;
    usedPromo.value = null;
  };

  const nextStep = () => {
    scrollTop();
    step.value < stepsCount && step.value++;
  };
  const prevStep = () => {
    scrollTop();
    step.value > 1 && step.value--;
  };

  const successStep = () => {
    scrollTop();
    step.value = -1;
  };

  const fetchMasters = async (parlorId, month, year, master = null) => {
    loading.value = true;

    // Создаем базовый URL с обязательными параметрами
    let url = `${getProtocol()}://${getAddr()}/api/v2/sessions/booking-session/${parlorId}?month=${month}&year=${year}`;

    // Добавляем параметр master, если он указан
    if (master) {
      url += `&master=${master}`;
    }

    try {
      const response = await axios.get(url);
      rawMastersData.value = response?.data || null;
    } catch (error) {
      console.error('Error fetching masters:', error);
      rawMastersData.value = null;
    } finally {
      loading.value = false;
    }
  };

  const fetchParlorInformation = async (parlorId) => {
    loading.value = true;
    try {
      const { data } = await apolloClientCustomer.query({
        query: LANDING_PARLOR_BY_ID,
        variables: {
          parlorId,
        },
      });

      const now = new Date();
      await fetchMasters(parlorId, now.getMonth() + 1, now.getFullYear(), master.value);

      const rawSessionTypes = data.masters
        .map((master) => master.session_types.map((el) => el.session_type))
        .flat();
      const rawSessionTypesMap = new Map(rawSessionTypes.map((item) => [item.id, item]));
      sessionTypes.value = Array.from(rawSessionTypesMap.values());
    } catch (e) {
      console.log(e);
    } finally {
      loading.value = false;
    }
  };

  const createAppointment = async () => {
    try {
      loading.value = true;

      const appliedBonusId = await bonusStore.createAppliedBonus();

      const data = {
        customer: userStore.userId,
        sketch: customerComments.value?.base64,
        booked_from: bookedFrom.value,
        is_colored: colored.value,
        referral_user: referralUser.value,
        customer_note: customerComments.value?.text,
        parlor: parlor.value,
        master: master.value,
        type: sessionType.value,
        used_promo: usedPromo.value,
        from_site: true,
        moderated: false,
        date: formattedDate.value,
        time: formattedTime.value,
        location: sessionTypeLocation.value,
        size: size.value,
        style: style.value,
        phone_number: phoneNumber.value,
        vc_payment_amount: vcPaymentAmount.value,
        applied_bonus: appliedBonusId,
        gclid: gclidField.value || null,
        utm_source: utmFields.utm_source || null,
        utm_medium: utmFields.utm_medium || null,
        utm_campaign: utmFields.utm_campaign || null,
        utm_content: utmFields.utm_content || null,
        utm_term: utmFields.utm_term || null,
      };

      const response = await axios.post(
        `${getProtocol()}://${getAddr()}/api/v1/sessions-customers/`,
        data,
      );

      createdSession.value = response.data;

      sessionId.value = response.data.id;
    } catch (e) {
      console.error('createAppointment error:' + e);
      handleAxiosError(e);
      return { sessionId: null };
    } finally {
      loading.value = false;
    }
  };

  watch(parlor, async (newParlor) => {
    if (newParlor) {
      await fetchParlorInformation(newParlor);
      sessionType.value = null;
    }
  });

  watch(sessionType, async () => {
    sessionTypeLocation.value = null;
    colored.value = false;
    style.value = null;
    size.value = null;
  });

  return {
    $reset,
    availableDates,
    availableMasters,
    availableTimes,
    bookedFrom,
    referralUser,
    colored,
    colorsOptions,
    createAppointment,
    currentMonth,
    currentYear,
    customerComments,
    date,
    fetchMasters,
    fetchParlorInformation,
    formattedDate,
    formattedTime,
    isIncreasedRisk,
    isMystery,
    isSuccessBooking,
    loading,
    master,
    vcPaymentAmount,
    nextStep,
    parlor,
    phoneNumber,
    prevStep,
    selectedLocationInformation,
    selectedParlorInformation,
    selectedTypeInformation,
    selectedMasterInformation,
    sessionId,
    sessionType,
    sessionTypeLocation,
    sessionTypeLocations,
    sessionTypeLocationsOptions,
    sessionTypes,
    sessionTypesOptions,
    size,
    sizesOptions,
    step,
    stepsCount,
    style,
    stylesOptions,
    successStep,
    createdSession,
    time,
  };
});

const useCheckInStore = defineStore('checkIn', () => {
  const customersCheckIn = ref({});

  function setCustomerCheckIn(key, value) {
    customersCheckIn.value[key] = value;
  }

  function removeCustomerCheckIn(key) {
    delete customersCheckIn.value[key];
  }

  return {
    customersCheckIn,
    setCustomerCheckIn,
    removeCustomerCheckIn,
  };
});

export {
  useActionsLogStore,
  useBookingStore,
  useConfigurationStore,
  useFindOutsStore,
  useParlorStore,
  useParlorsStore,
  useCheckInStore,
  useStylesStore,
  useUserStore,
};
