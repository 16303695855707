import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "rate" }
const _hoisted_2 = ["name", "id", "checked", "onInput"]
const _hoisted_3 = ["for"]


export default /*@__PURE__*/_defineComponent({
  __name: 'FormKitRate',
  props: {
  context: Object,
},
  setup(__props) {

const props = __props;

const count = Number(props.context.count || 5);
const icon = props.context.icon || 'bi-star';
const iconActive = props.context.iconActive || 'bi-star-fill';
const handleInput = (i: number) => {
  props.context.node.input(i);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(count), (i) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "rate__elem",
        key: i
      }, [
        _createElementVNode("input", {
          class: "elem-input",
          name: props.context.node.name,
          id: props.context.node.name + '_' + i,
          checked: props.context._value === i,
          onInput: ($event: any) => (handleInput(i)),
          type: "radio"
        }, null, 40, _hoisted_2),
        _createElementVNode("label", {
          class: "elem-label",
          for: props.context.node.name + '_' + i
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["bi", 
            (isNaN(Number(props.context.value)) || (i > Number(props.context.value)))
              ? _unref(icon)
              : _unref(iconActive)
          ])
          }, null, 2)
        ], 8, _hoisted_3)
      ]))
    }), 128))
  ]))
}
}

})