import { customerApi } from '@/api/customer';
import { EFormActionType } from '@/types/enums';
import { defineStore } from 'pinia';
import { LocationQuery, useRoute } from 'vue-router';
import { ref, watch } from 'vue';

export default defineStore('formActionStore', () => {
  const route = useRoute();

  console.log('route.query', route.query);

  console.log('askCountry', 'ask_country' in route.query);

  const formLinkId = ref<number | null>(null);
  const askCountry = ref<boolean>(false);
  const askLanguage = ref<boolean>(false);
  const parlorId = ref<number | null>(null);
  const pixelId = ref<string | null>(null);

  const createFormActionTransition = async (): Promise<void> => {
    if (formLinkId.value) {
      await customerApi.formAction.createFormAction({
        action_type: EFormActionType.transition,
        form_link: formLinkId.value,
      });
    }
  };
  const createFormActionRecord = async (): Promise<void> => {
    if (formLinkId.value) {
      await customerApi.formAction.createFormAction({
        action_type: EFormActionType.record,
        form_link: formLinkId.value,
      });
    }
  };

  const setValues: (query: LocationQuery) => void = (query) => {
    formLinkId.value = Number(route.query.form_link) || null;
    askCountry.value = 'ask_country' in route.query;
    askLanguage.value = 'ask_language' in route.query;
    parlorId.value = Number(route.query.parlor) || null;
    pixelId.value = String(route.query.pixel_id) || null;
  };

  watch(() => route.query, (val) => setValues(val), { immediate: true });

  return {
    askCountry,
    askLanguage,
    parlorId,
    pixelId,
    createFormActionTransition,
    createFormActionRecord,
  };
});
